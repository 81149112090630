import React from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from '../RudderStack';

const BottomMenu: React.FC<{ toCall: () => void }> = (props) => {
  const analytics = useAnalytics();
  return (
    <>
      <div id="top" className="menu_buttom">
        <nav className="nav">
          <Link
            to="/consultation_open"
            className={`${
              /consultation_open*/.test(location.pathname) ? 'opacity-100' : 'opacity-50'
            } nav__link `}
            onClick={() => {
              analytics?.track('Project Menu Clicked');
              props.toCall();
            }}
          >
            <img src="/Icons/noun_complete_activated.svg" width={35} height={35} alt="" />
            <span>Projets</span>
          </Link>
          <Link
            to="/settings/preferences"
            className={`${/preferences*/.test(location.pathname) ? 'opacity-100' : 'opacity-50'} ${
              window.innerWidth < 995 && 'my-third-step'
            } nav__link`}
            onClick={() => {
              analytics?.track('Search Criteria Menu Clicked');
              props.toCall();
            }}
          >
            <img src="/Icons/noun_filter_3212581.svg" width={35} height={35} alt="" />
            <span>Critères</span>
          </Link>
          <Link
            to="/favorites"
            className={`${
              /favorites*/.test(location.pathname) ? 'opacity-100' : 'opacity-50'
            } nav__link`}
            onClick={() => {
              analytics?.track('Favorite Menu Clicked');
              props.toCall();
            }}
          >
            <img src="/Icons/star-light.svg" width={35} height={35} alt="Icone étoile" />
            <span>Favoris</span>
          </Link>
          <Link
            to="/consultation_ask"
            className={`${
              /consultation_ask*/.test(location.pathname) ? 'opacity-100' : 'opacity-50'
            } nav__link`}
            onClick={() => {
              analytics?.track('Project Request Menu Clicked');
              props.toCall();
            }}
          >
            <img src="/Icons/noun_update_activated.svg" width={35} height={35} alt="" />
            <span>Demandes</span>
          </Link>
        </nav>
      </div>
    </>
  );
};

export default BottomMenu;
