import React from 'react';
import Button from '../../../common/navigation/Button';
import { useAnalytics } from '../../../common/RudderStack';
import { useHistory } from 'react-router';

const PriceRequestCardFree: React.FC<{ taken?: boolean }> = ({ taken = false }) => {
  const analytics = useAnalytics();
  const history = useHistory();

  const freeSubscription = () => {
    analytics?.track('Free Subscription');
    history.push('/consultation_open#tour');
  };

  return (
    <div className="card price-request-card-subscription-free price-request-card-plan card-plan-free price-request-plan mb-3">
      <div className="price-request-header-title">
        <span></span>
      </div>
      <div className="card-title">
        <h3 className="mb-3">
          <strong>Formule d'essai</strong>
        </h3>
      </div>
      <p className="subtitle-request-price">Découvrez tous les projets :</p>
      <ul className="check-list-price-request">
        <li className="icon-free">Par départements</li>
        <li className="icon-free">Par lots</li>
        <li className="icon-free">Par secteur d’activité</li>
        <li className="cancel-icon-free">Sans accès à la maîtrise d’ouvrage</li>
        <li className="cancel-icon-free">Sans accès aux DCEs</li>
        <li className="cancel-icon-free">Sans possibilité de réponse</li>
        <li className="cancel-icon-free">Sans formation personnalisée à l’outil</li>
      </ul>
      <div className="button">
        {
          !taken
            ? (<Button
              color="btn-free-pack"
              text={'Finaliser mon compte'}
              action={() => freeSubscription()}
            />)
            : (<Button
              disabled
              color="btn-free-pack"
              text={'Formule actuelle'}
              action={() => null}
            />)
        }
      </div>
    </div>
  );
};

export default PriceRequestCardFree;
