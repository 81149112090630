import React, { useState, useEffect /*, useContext*/ } from 'react';
import axios from 'axios';
import CardFree from '../subscriptions/prices/CardFree';
import CardAnnually from '../subscriptions/prices/CardAnnually';
import { Redirect } from 'react-router';
import SubscriptionPromotion from '../subscriptions/SubscriptionPromotion';
import { BACKEND_URL } from '../../config/environment';
//import queryString from 'query-string';
//import { ToastContext } from '../../common/providers/ToastProvider';
// import CardAnnuallyPromotion from '../subscriptions/prices/CardAnnuallyPromotion';
// import CardQuarterlyPromotion from '../subscriptions/prices/CardQuarterlyPromotion';
// import BoxSponsorship from '../../common/navigation/BoxSponsorship';
//import CircularLoader from '../../common/navigation/CircularLoader';
//import ReferralCode from '../sponsorships/ReferralCode';
import PriceRequestCardAnnually from '../subscriptions/prices/PriceRequestCardAnnually';
import PriceRequestCardFree from '../subscriptions/prices/PriceRequestCardFree';
import CircularLoader from '../../common/navigation/CircularLoader';
import ErrorMessage from '../../common/errors/ErrorMessage';

const RegisterFormulas: React.FC<{ user: IUser }> = ({ user }) => {
  const [promotions, setPromotions] = useState(null);
  const [hasFoundAnnually, setHasFoundAnnually] = useState(false);
  const [hasFoundQuarterly, setHasFoundQuarterly] = useState(false);
  const [prices, setPrices] = useState<Array<IStripePrice>>(null);
  const [error, setError] = useState(null);
  const [loadingPrice, setLoadingPrice] = useState(true);
  const [displayPromotion] = useState(false);
  //const { referral_code } = queryString.parse(location.search);
  //const [referralCode, setReferralCode] = useState<string>(referral_code);
  //const [isExist, setIsExist] = useState<boolean>(false);
  //const [loading, setLoading] = useState<boolean>(false);
  //const { displayToast } = useContext(ToastContext);

  // const handleOnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setReferralCode(event.target.value);
  // };

  // useEffect(() => {
  //   if (referral_code) {
  //     referralCodeIsExist();
  //   }
  // }, [referral_code]);

  // const referralCodeIsExist = () => {
  //   setLoading(true);
  //   axios
  //     .post(`${BACKEND_URL}/api/check-referral-code`, { referral_code: referralCode })
  //     .then(() => {
  //       setIsExist(true);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setIsExist(false);
  //       setLoading(false);
  //       displayToast('Le code de parrainage est invalide.');
  //     });
  // };

  const handleGetPromotion = (promotionCode: string) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`${BACKEND_URL}/api/stripe/promotion`, { params: { promotion_code: promotionCode } })
        .then(({ data }) => {
          setPromotions(data.promotion);
          return resolve();
        })
        .catch((err) => {
          setPromotions(null);
          return reject(err);
        });
    });
  };

  useEffect(() => {
    if (user.account.price_requests?.some((element) => element.name === 'annually')) {
      setHasFoundAnnually(true);
    }

    if (user.account.price_requests?.some((element) => element.name === 'quarterly')) {
      setHasFoundQuarterly(true);
    }

    if (!prices) {
      axios
        .get(`${BACKEND_URL}/api/prices/proposed/subscription`)
        .then(({ data }) => {
          setPrices(data.prices);
        })
        .catch(() => {
          setError("Cette offre est invalide ou n'est plus disponible.");
        })
        .finally(() => {
          setLoadingPrice(false);
        });
    } else {
      setLoadingPrice(false);
      setError("Cette offre est invalide ou n'est plus disponible.");
    }
  }, []);

  const displayCards = () => {
    if (prices?.some((item) => item.name === 'quarterly' || item.name === 'annually')) {
      return (
        <>
          <CardFree />
          {prices?.some((item) => item.name === 'annually') && <CardAnnually prices={prices} />}
        </>
      );
    } else {
      return (
        <>
          <PriceRequestCardFree />
          <PriceRequestCardAnnually foundAnnually={hasFoundAnnually} />
        </>
      );
    }
  };

  return user.is_stripe_subscriber ? (
    <Redirect to="/settings/subscription" />
  ) : (
    <div className="container pt-2 pb-2" style={{ flexGrow: 1, maxWidth: 1400 }}>
      <div className="d-flex align-items-center height-100">
        <div
          className="card card-body container-plans"
          style={{
            paddingLeft: '50px',
            paddingRight: '50px',
            paddingTop: '60px',
            paddingBottom: '60px',
          }}
        >
          <img
            width="250px"
            src="/images/logo_saqara.png"
            alt="Logo chantier privé par Saqara"
            style={{ margin: 'auto' }}
          />
          <h2 className="mt-4">Finalisez votre abonnement</h2>
          {/* {loading && <CircularLoader />}
          {!loading && (
            <> */}
          {/* <ReferralCode
                referralCode={referralCode}
                isExist={isExist}
                referralCodeIsExist={referralCodeIsExist}
                handleOnChangeEvent={handleOnChangeEvent}
              /> */}

          {/* {isExist && <BoxSponsorship referralCode={referralCode} />} */}

          {/* {!isExist && ( */}
          {displayPromotion && <SubscriptionPromotion handleGetPromotion={handleGetPromotion} />}
          <div className="plans mb-3 mt-5" style={{ maxWidth: '100%' }}>
            {error && <ErrorMessage text={error} />}
            {loadingPrice ? <CircularLoader /> : displayCards()}
          </div>
          {/* )} 
              {isExist && (
                <div className="plans mb-3 mt-5">
                  <CardFree displayRedirectionButton={true} />
                  <CardQuarterlyPromotion
                    price={267.3}
                    referralCode={referralCode}
                    hideDescription={false}
                  />
                  <CardAnnuallyPromotion
                    hideDescription={false}
                    price={855}
                    referralCode={referralCode}
                  />
                </div>
              )}*/}
          {/* </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default RegisterFormulas;
