import React from 'react';

const Pagination: React.FC<{
  lastPage: number;
  currentPage: number;
  toCall: (page: number) => void;
}> = ({ lastPage, currentPage, toCall }) => {
  const previousPage = () => {
    toCall(currentPage - 1);
  };

  const nextPage = () => {
    toCall(currentPage + 1);
  };

  const targetedPage = (page: number) => {
    toCall(page);
  };

  return (
    <div className="d-flex justify-content-between mt-4">
      <div>
        <button
          type="button"
          className="btn btn-pagination"
          onClick={() => previousPage()}
          disabled={currentPage === 1}
        >
          Précédent
        </button>
      </div>
      <div>
        {currentPage !== 1 && currentPage - 2 >= 1 && (
          <>
            <button
              type="button"
              className="btn btn-pagination"
              onClick={() => targetedPage(1)}
              style={{ display: 'inline', width: 'auto' }}
            >
              1
            </button>
            <span style={{ paddingTop: '10px' }}>{' ... '}</span>
          </>
        )}
        {currentPage !== 1 && (
          <button
            type="button"
            className="btn btn-pagination"
            onClick={() => targetedPage(currentPage - 1)}
            style={{ display: 'inline', width: 'auto' }}
          >
            {currentPage - 1}
          </button>
        )}{' '}
        <button
          type="button"
          className="btn btn-pagination-selected"
          onClick={() => targetedPage(currentPage)}
          style={{ display: 'inline', width: 'auto' }}
          disabled
        >
          {currentPage}
        </button>{' '}
        {currentPage !== lastPage && (
          <button
            type="button"
            className="btn btn-pagination"
            onClick={() => targetedPage(currentPage + 1)}
            style={{ display: 'inline', width: 'auto' }}
          >
            {currentPage + 1}
          </button>
        )}
        {currentPage !== lastPage && currentPage + 2 <= lastPage && (
          <>
            <span style={{ paddingTop: '10px' }}>{' ... '}</span>
            <button
              type="button"
              id="button-last-page"
              data-cy="button-last-page"
              className="btn btn-pagination"
              onClick={() => targetedPage(lastPage)}
              style={{ display: 'inline', width: 'auto' }}
            >
              {lastPage}
            </button>
          </>
        )}
      </div>
      <div>
        <button
          type="button"
          id="button-next-page"
          data-cy="button-next-page"
          className="btn btn-pagination"
          onClick={() => nextPage()}
          disabled={currentPage === lastPage}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default Pagination;
