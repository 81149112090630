import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useAnalytics } from '../../common/RudderStack';

const ErrorPayment: React.FC<{ user: IUser; subscription: ISubscription }> = ({ subscription }) => {
  const history = useHistory();
  const analytics = useAnalytics();

  const settingSubscription = () => {
    analytics?.track('Free Subscription');
    history.push('/settings/subscription');
  };

  return (
    <div className="project-request-box">
      <div className="card p-20" style={{ width: '45%' }}>
        <div className="information-title">
          <img
            className="mt-3"
            src="/Icons/info-subscription.png"
            alt="Informations d’abonnement"
          />
          <h3 className="ml-5" style={{ fontSize: '21px' }}>
            Informations d’abonnement
          </h3>
        </div>
        <p className="pt-3">
          <span style={{ color: '#283549', fontWeight: 'bold', fontSize: '15px' }}>
            Abonnement trimestriel Chantier Privé
          </span>{' '}
          <br />
          <br />
          Abonnement fait le {subscription?.current_period_start} <br /> <br />
          Paiement échoué le {subscription?.current_period_end}
        </p>
      </div>

      <div className="project-request">
        <h2>Votre paiement vient d’échouer</h2>
        <p>
          Le paiement de votre abonnement Chantier Privé vient d'échouer. Malheureusement, en
          l&lsquo;absence de ce paiement votre compte sera temporairement suspendu. Nous vous
          invitons donc à mettre rapidement à jour vos informations de paiement. Cliquez sur le
          bouton <span className="orange">mettre à jour mon abonnement</span> ci-dessous. <br />
          Si vous souhaitez en savoir plus contactez-nous au{' '}
          <span className="orange"> 0187666076 </span>ou sur
          <span className="orange"> contact@chantierprive.fr.</span>
        </p>
        <div className="col-md-8 mt-4">
          <Link to="#" onClick={() => settingSubscription()} className="btn btn-secondary">
            Renouveler mon abonnement
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPayment;
