import React from 'react';

/**
 * This react component is used to display the footer of the application.
 * @constructor
 */
export default function Footer() {
  return (
    <div className="footer">
      <div className="d-flex justify-content-between">
        <div className="disabled">Copyright chantier privé &copy; 2022</div>
        <div>
          <span>
            <a
              target="_blank"
              href={`${process.env.BUCKET_URL}/documents/mentions-legales.pdf`}
              rel="noreferrer"
            >
              Mentions légales
            </a>
          </span>
          <span>-</span>
          <span>
            <a target="_blank" href={`${process.env.SAQARA_URL_CGV}`} rel="noreferrer">
              CGV
            </a>
          </span>
          <span>-</span>
          <span>
            <a href="https://saqara.com" target="_blank" rel="noreferrer">
              SAQARA
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
