import React from 'react';

type ButtonProps = {
  color: string;
  text: string;
  action: (e?: React.SyntheticEvent) => void;
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  id?: string;
};

const Button: React.FC<ButtonProps> = ({ color, text, action, loading, disabled, style, id }) => (
  <button
    id={id}
    data-cy={id}
    className={`btn ${color}`}
    disabled={loading || disabled}
    onClick={action}
    style={style}
  >
    {loading ? (
      <img
        src="/images/spinnr_CP.gif"
        alt="Veuillez patienter"
        style={{ height: '40px', marginTop: '-10px' }}
      />
    ) : (
      text
    )}
  </button>
);

export default Button;
