import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../config/environment';

type FavoriteButtonProps = {
  value: boolean;
  project: IProject;
  onValueChange: (newValue: boolean) => void;
};

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ value, project, onValueChange }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleClickEvent = () => {
    if (!loading) {
      setLoading(true);
      const id = project.id;
      const newValue = !value;
      const promise = newValue ? sendAddToFavoritesRequest(id) : sendRemoveFromFavoritesRequest(id);

      promise.then(() => {
        onValueChange(newValue);

        setLoading(false);
      });
    }
  };

  const getIcon = (isFavorite: boolean) =>
    isFavorite ? '/Icons/star-filled.svg' : '/Icons/star.svg';

  const getIconStar = (isFavorite: boolean) => (isFavorite ? 'star-filled' : 'star-empty');

  const sendAddToFavoritesRequest = (id: string) =>
    axios.post(`${BACKEND_URL}/api/v2/users/me/projects/${id}/favorites`);

  const sendRemoveFromFavoritesRequest = (id: string) =>
    axios.delete(`${BACKEND_URL}/api/v2/users/me/projects/${id}/favorites`);

  return (
    <img
      src={getIcon(value)}
      data-cy={getIconStar(value)}
      className="favorite-btn"
      role="button"
      width="21"
      height="20"
      onClick={handleClickEvent}
    />
  );
};

export default FavoriteButton;
