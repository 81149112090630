import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ProductTourContext } from '../../common/providers/ProductTourProvider';

/**
 * This react component is used to display the sidebar of the application when the product tour is enabled.
 * This component able the user to navigate in the different sections of the application.
 * @constructor
 */
const ProductTourSideBar: React.FC = () => {
  const { triggerNextTourStep } = useContext(ProductTourContext);
  const location = useLocation();

  return (
    <>
      <div id="navbar" className="sidebar">
        <div className="product-tour-sidebar-container">
          <Link to="/consultation_open" id="link-home" data-cy="link-home">
            <img
              src="/images/logo_saqara.png"
              alt="Logo chantier privé par Saqara"
              className="logo"
            />
          </Link>

          <div id="tour__projects" data-cy="tour-projects" className="item">
            <Link
              onClick={() => triggerNextTourStep()}
              to="/consultation_open"
              id="link-projects"
              data-cy="link-projects"
              className={/consultation_open*/.test(location.pathname) ? 'active' : ''}
            >
              <div
                className={`${
                  /consultation_open*/.test(location.pathname) ? '' : 'no-'
                }marker light`}
              />
              <img src="/Icons/noun_complete_activated.svg" alt="" />
              <div className="link">Projets</div>
            </Link>
          </div>

          <div className="item" id="tour__asked-projects" data-cy="tour-asked-projects">
            <Link
              onClick={() => triggerNextTourStep()}
              to="/consultation_ask"
              className={/consultation_ask*/.test(location.pathname) ? 'active' : ''}
              id="link-projects-requested"
              data-cy="link-projects-requested"
            >
              <div
                className={`${
                  /consultation_ask*/.test(location.pathname) ? '' : 'no-'
                }marker light`}
              />
              <img src="/Icons/noun_update_activated.svg" alt="" />
              <div className="link">Accès demandés</div>
            </Link>
          </div>

          <div className="item">
            <Link
              to="/settings/profile"
              className={/settings*/.test(location.pathname) ? 'active' : ''}
              id="link-settings"
              data-cy="link-settings"
            >
              <div className="no-marker" />
              <img src="/Icons/noun_filter_3212581.svg" className="img-settings" alt="" />
              <div className="link">Paramètres</div>
            </Link>
          </div>

          <div className="item" id="tour__profile" data-cy="tour-profile">
            <Link
              onClick={() => triggerNextTourStep()}
              to="/settings/profile"
              id="link-settings-profile"
              data-cy="link-settings-profile"
              className={/profile*/.test(location.pathname) ? 'active' : ''}
            >
              <div className={`${/profile*/.test(location.pathname) ? '' : 'no-'}marker`} />
              <div className="link reduced">Votre compte</div>
            </Link>
          </div>

          <div className="item" id="tour__filters" data-cy="tour-filters">
            <Link
              onClick={() => triggerNextTourStep()}
              to="/settings/preferences"
              id="link-settings-criterias"
              data-cy="link-settings-criterias"
              className={/preferences*/.test(location.pathname) ? 'active' : ''}
            >
              <div className={`${/preferences*/.test(location.pathname) ? '' : 'no-'}marker`} />
              <div className="link reduced">
                Critères
                <br />
                de recherche
              </div>
            </Link>
          </div>

          <div className="item" id="tour__subscriptions" data-cy="tour-subscriptions">
            <Link
              id="link-settings-subscription"
              data-cy="link-settings-subscription"
              onClick={() => triggerNextTourStep()}
              to="/settings/subscription"
              className={/subscription*/.test(location.pathname) ? 'active' : ''}
            >
              <div
                className={`${/subscription*/.test(location.pathname) ? '' : 'no-'}marker light`}
              />
              <div className="link reduced">Abonnement</div>
            </Link>
          </div>

          <div className="item">
            <a id="link-sign-out" data-cy="link-sign-out">
              <div className="no-marker" />
              <div className="link reduced">Déconnexion</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTourSideBar;
