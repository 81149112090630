import React, { useContext } from 'react';
import axios from 'axios';
import Modal from '../../common/Modal/index';
import { UserContext } from '../../common/providers/UserProvider';
import { BACKEND_URL } from '../../config/environment';

/**
 * This react component is used to see the logout modal.
 * This modal is used to confirm the logout of the user from the app.
 * @param props
 */
const LogoutModal: React.FC<{ hidden: boolean; closeModal: () => void }> = ({
  hidden,
  closeModal,
}) => {
  const { logout } = useContext(UserContext);

  const userLogout = () => {
    axios.post(`${BACKEND_URL}/api/logout`).then(() => {
      logout();
    });
    closeModal();
  };

  return (
    <Modal hidden={hidden} maxWidth={600} closeModal={closeModal}>
      <div className="modal-body">
        <h1>Déconnexion</h1>
        <p className="mt-3">Êtes vous sûr(e) de vouloir vous déconnecter ?</p>
        <button
          id="button-disconnect"
          data-cy="button-disconnect"
          type="button"
          className="btn btn-secondary mt-2"
          onClick={() => userLogout()}
        >
          Déconnexion
        </button>

        <button
          id="button-disconnect-cancel"
          data-cy="button-disconnect-cancel"
          type="button"
          className="btn btn-primary mt-2"
          onClick={() => closeModal()}
        >
          Annuler
        </button>
      </div>
    </Modal>
  );
};

export default LogoutModal;
