import React, { useState } from 'react';

const UserInformation: React.FC<{ user: IUser }> = (props) => {
  const parameter = props;
  const [user] = useState<IUser>(parameter.user);
  return (
    <div className="col-sm mb-3">
      <h2 className="title-card-option">
        <span>Vous</span>
        {(user && user.profile === 3 && <span className="manager-title">Manager</span>) ||
          (user && user.managers?.length > 0 && user.profile === 0 && (
            <span className="account-title">Géré par : {user.managers[0].mail}</span>
          ))}
      </h2>
      <br />
      <div className="card-sub-detail">
        <span>Prénom :</span> {user.first_name}
      </div>
      <div className="card-sub-detail">
        <span>Nom :</span> {user.last_name}
      </div>
      <div className="card-sub-detail">
        <span>Email :</span> {user.mail}
      </div>
      <div className="card-sub-detail">
        <span>Téléphone :</span> {user.phone}
      </div>
    </div>
  );
};

export default UserInformation;
