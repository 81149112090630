import React from 'react';

const CompanyInformation: React.FC<{ company: ICompany }> = ({ company }) => {
  return (
    <div className="col-sm">
      <h2 className="title-card-option">Votre entreprise</h2>
      <br />
      <div className="card-sub-detail">
        <span>Siret :</span> {company.siret}
      </div>
      <div className="card-sub-detail">
        <span>Adresse :</span> {company.address}
      </div>
      <div className="card-sub-detail">
        <span>Département :</span> {company.zip_code}
      </div>
      <div className="card-sub-detail">
        <span>Ville :</span> {company.city}
      </div>
    </div>
  );
};

export default CompanyInformation;
