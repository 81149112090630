if (process.env.APP_ENV === 'production' || process.env.APP_ENV === 'staging') {
(function (d, w, c) {
  w.ChatraID = 'a6sut3c6pJCE7byKY';
  var s = d.createElement('script');
  w[c] =
    w[c] ||
    function () {
      (w[c].q = w[c].q || []).push(arguments);
    };
  s.async = true;
  s.src = 'https://call.chatra.io/chatra.js';
  if (d.head) d.head.appendChild(s);
})(document, window, 'Chatra');
window.ChatraSetup = {
  groupId: 'wocKDLCD75QGRnRgA',
  colors: {
    buttonText: '#fff',
    /* couleur du texte du bouton de chat */
    buttonBg: '#466496' /* couleur de fond du bouton de chat */,
  },
};
}
