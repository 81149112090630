import React, { useEffect, useState } from 'react';
import Checkbox from '../../../common/navigation/Checkbox';
import getTradesCategories from './getTradesCategories';

const TradesCategoriesEdit: React.FC<{
  trades: Array<ITrades>;
  updateTrades: (trade: ITrades) => void;
  updateAllTrades: (tradeList: Array<ITrades>) => void;
  background?: string;
}> = ({ trades, updateTrades, updateAllTrades, background = 'white' }) => {
  const originalTradeList = getTradesCategories();
  const [tradeList, setTradeList] = useState<Array<ITrades>>(originalTradeList);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (search !== '') {
      setTradeList(
        tradeList.filter(
          (value) =>
            value.name.toLowerCase().includes(search.toLowerCase()) || value.name.includes(search)
        )
      );
    } else setTradeList(originalTradeList);
  }, [trades, search]);

  const handleUpdateAllTrades = () => {
    if (trades.length === tradeList.length) {
      updateAllTrades([]);
    } else {
      updateAllTrades(tradeList);
    }
  };

  return (
    <>
      <input
        type="text"
        placeholder="Rechercher un Lot..."
        className="form-control"
        onChange={(event) => setSearch(event.target.value)}
      />
      <div
        className="multi-checkboxes profile mt-2"
        style={{ height: '225px', overflowY: 'scroll', backgroundColor: background }}
      >
        {search === '' && (
          <Checkbox
            id="all-trades-checkbox"
            data-cy="all-trades-checkbox"
            onChange={() => handleUpdateAllTrades()}
            checked={trades.length === tradeList.length}
            label={'Tous les lots'}
          />
        )}
        {tradeList.length > 0 ? (
          tradeList.map((trade) => (
            <Checkbox
              key={trade.name}
              id={trade.name}
              data-cy={trade.name}
              onChange={() => updateTrades(trade)}
              checked={trades.some((item) => item.name === trade.name)}
              label={`${trade.name}`}
            />
          ))
        ) : (
          <i className="sub-description">Aucun lot ne correspond.</i>
        )}
      </div>
    </>
  );
};

export default TradesCategoriesEdit;
