import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import LogoutModal from '../../components/authentication/LogoutModal';
import { ProductTourContext } from '../../common/providers/ProductTourProvider';
import ProductTourSideBar from '../product-tour/ProductTourSideBar';
import { useAnalytics } from '../RudderStack';
import { BACKEND_URL } from '../../config/environment';

/**
 * This react component is used  to  display the sidebar of the application.
 * This component able the user to navigate in the different sections of the application.
 * @param props
 * @constructor
 */
const SideBar: React.FC<{ toCall: () => void; user: IUser }> = (props) => {
  const { isTourEnabled, forceTourTrigger } = useContext(ProductTourContext);
  const [isSigningOff, setIsSigningOff] = useState<boolean>(false);
  const [keepAliveStarted, setKeepAliveStarted] = useState<boolean>(false);
  const location = useLocation();
  const analytics = useAnalytics();
  const user = props.user;

  const keepAlive = () => {
    setKeepAliveStarted(true);

    setTimeout(() => {
      axios.post(`${BACKEND_URL}/api/keep-alive`);
      keepAlive();
    }, 1000 * 300); // Each 5 minutes is enough.
  };

  useEffect(() => {
    if (!keepAliveStarted) {
      keepAlive();
    }
  }, [keepAliveStarted]);

  return (
    <>
      <LogoutModal hidden={isSigningOff} closeModal={() => setIsSigningOff(false)} />
      {isTourEnabled ? (
        <ProductTourSideBar />
      ) : (
        <div id="navbar" className="sidebar">
          <div className="w-100 verticalmenu text-left">
            <Link
              className="nav-link dropdownmenu-title"
              to="/settings/profile"
              onClick={() => {
                props.toCall();
              }}
            >
              Compte
            </Link>
            <Link
              className="nav-link dropdownmenu-title"
              to="/settings/subscription"
              onClick={() => {
                props.toCall();
              }}
            >
              Abonnement
            </Link>
            <a
              className="nav-link dropdownmenu-title"
              href="/admin"
              onClick={() => {
                props.toCall();
              }}
            >
              Administration
            </a>
            <hr />
            <Link
              className="nav-link dropdownmenu-subtitle"
              to="/settings/faq"
              onClick={() => {
                props.toCall();
              }}
            >
              Centre d’aide
            </Link>
            <Link
              className="nav-link dropdownmenu-subtitle"
              to="#"
              onClick={() => {
                setIsSigningOff(true);
                props.toCall();
              }}
              id="link-sign-out"
              data-cy="link-sign-out"
            >
              Se déconnecter
            </Link>
            <hr />
            <a
              className="nav-link dropdownmenu-par"
              target="_blank"
              rel="noreferrer"
              href={`${process.env.BUCKET_URL}/documents/cgu.pdf`}
            >
              Conditions générales Chantier Privé
            </a>
          </div>
          <div className="sidebar-container">
            <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
              <Link to="/consultation_open" id="link-home" data-cy="link-home">
                <img src="/images/logo_saqara.png" alt="Chantier Privé" className="logo" />
              </Link>
              <div className="item">
                <Link
                  onClick={() => {
                    analytics?.track('Project Menu Clicked');
                    props.toCall();
                  }}
                  to="/consultation_open"
                  id="link-projects"
                  data-cy="link-projects"
                  className={`${
                    /consultation_open*/.test(location.pathname) ? 'active' : ''
                  } d-flex align-items-center`}
                >
                  <div
                    className={`${
                      /consultation_open*/.test(location.pathname) ? '' : 'no-'
                    }marker light`}
                  />
                  <img src="/Icons/noun_complete_activated.svg" alt="" />
                  <div className="link">PROJETS</div>
                </Link>
              </div>

              <div className={`${window.innerWidth >= 995 && 'item my-third-step'}`}>
                <Link
                  onClick={() => {
                    analytics?.track('Search Criteria Menu Clicked');
                    props.toCall();
                  }}
                  to="/settings/preferences"
                  className={`${
                    /preferences*/.test(location.pathname) ? 'active' : ''
                  } d-flex align-items-center`}
                  id="link-preferences"
                  data-cy="link-preferences"
                >
                  <div
                    className={`${
                      /preferences*/.test(location.pathname) ? '' : 'no-'
                    }marker light `}
                  />
                  <img src="/Icons/noun_filter_3212581.svg" className="img-settings" alt="" />
                  <div className="link pt-0">
                    CRITÈRES DE <br /> RECHERCHE
                  </div>
                </Link>
              </div>

              <div className="item">
                <Link
                  onClick={() => {
                    analytics?.track('Favorite Menu Clicked');
                    props.toCall();
                  }}
                  to="/favorites"
                  id="link-favorite"
                  data-cy="link-favorite"
                  className={`${
                    /favorites*/.test(location.pathname) ? 'active' : ''
                  } d-flex align-items-center`}
                >
                  <div
                    className={`${/favorites*/.test(location.pathname) ? '' : 'no-'}marker light`}
                  />
                  <img src="/Icons/star-light.svg" alt="Icone étoile" />
                  <div className="link">FAVORIS</div>
                </Link>
              </div>

              <div className="item">
                <Link
                  onClick={() => {
                    analytics?.track('Project Request Menu Clicked');
                    props.toCall();
                  }}
                  to="/consultation_ask"
                  className={`${
                    /consultation_ask*/.test(location.pathname) ? 'active' : ''
                  } d-flex align-items-center`}
                  id="link-projects-requested"
                  data-cy="link-projects-requested"
                >
                  <div
                    className={`${
                      /consultation_ask*/.test(location.pathname) ? '' : 'no-'
                    }marker light`}
                  />
                  <img src="/Icons/noun_update_activated.svg" alt="" />
                  <div className="link">DEMANDE D’ACCÈS</div>
                </Link>
              </div>
              {user && user.profile === 3 && (
                <div className={`${window.innerWidth >= 995 && 'item my-third-step'}`}>
                  <Link
                    onClick={() => {
                      analytics?.track('User management Menu Clicked');
                      props.toCall();
                    }}
                    to="/user_management"
                    className={`${
                      /user_management*/.test(location.pathname) ? 'active' : ''
                    } d-flex align-items-center`}
                    id="link-preferences"
                    data-cy="link-preferences"
                  >
                    <div
                      className={`${
                        /user_management*/.test(location.pathname) ? '' : 'no-'
                      }marker light `}
                    />
                    <img src="/Icons/users.svg" className="img-settings" alt="" />
                    <div className="link pt-0">
                      GESTION DES <br />
                      UTILISATEURS
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <div className="tutorial-btn-container" hidden={true}>
              <button
                onClick={() => {
                  analytics?.track('Tutorial Menu Clicked');
                  forceTourTrigger();
                }}
                className="tutorial-btn"
              >
                Tutoriel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
