import React from 'react';

const ErrorMessage = ({ text }: { text: string }) => {
  return (
    <div className="alert alert-danger" id="alert-danger" data-cy="alert-danger">
      {text}
    </div>
  );
};

export default ErrorMessage;
