import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import CircularLoader from '../../common/navigation/CircularLoader';
import TitleIconed from '../../common/navigation/TitleIconed';
import axios from 'axios';
import { useAnalytics } from '../../common/RudderStack';
import { BACKEND_URL } from '../../config/environment';

const SubscriptionResumeOffer: React.FC<{ user: IUser }> = ({ user }) => {
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [loadingResumeSubscription, setLoadingResumeSubscription] = useState(false);
  const [displayResumeConfirmation, setDisplayResumeConfirmation] = useState(false);
  const [resumeError, setResumeError] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const history = useHistory();
  const analytics = useAnalytics();

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/api/subscriptions`)
      .then(({ data }) => {
        setSubscription(data.subscription);
      })
      .catch(() => {
        history.push(`/settings/subscription`);
      })
      .finally(() => {
        setLoadingSubscription(false);
      });
  }, []);

  useEffect(() => {
    if (!user.is_stripe_subscriber || !user.is_on_grace_period) {
      history.push(`/settings/subscription`);
    }
  }, [user.is_stripe_subscriber, user.is_on_grace_period]);

  const handleResumeSubscription = () => {
    analytics?.track('Button Clicked', {
      location: 'resume offer',
      text: 'Je profite de l’offre immédiatement',
    });

    setLoadingResumeSubscription(true);
    axios
      .post(`${BACKEND_URL}/api/stripe/resume-subscription-offer`)
      .then(() => {
        setLoadingResumeSubscription(true);
        setDisplayResumeConfirmation(true);
      })
      .catch(() => {
        setResumeError(true);
      })
      .finally(() => {
        setLoadingResumeSubscription(false);
      });
  };

  return (
    <div>
      {loadingSubscription ? (
        <CircularLoader />
      ) : (
        <>
          <TitleIconed icon="noun_filter_activated" text="Réactiver votre abonnement" />

          <div className="resume-subscription-box">
            {displayResumeConfirmation ? (
              <>
                <p className="promotion-text">Votre demande a bien été prise en compte !</p>

                <p className="plan-informations">
                  Vous êtes désormais réengagé sur votre Abonnement{' '}
                  {subscription.stripe_price.display_name}, renouvelé le{' '}
                  {subscription.current_period_end} avec réduction de 50%.
                </p>

                <div className="subscription-resume-cta">
                  <Link
                    to="/consultation_open"
                    className="btn btn-secondary max-btn"
                    data-cy="btn-find-project"
                  >
                    Trouvez des chantiers
                  </Link>
                </div>
              </>
            ) : (
              <>
                {resumeError && (
                  <p className="alert alert-danger">
                    Une erreur est survenue, vous n&apos;êtes malheureusement peut-être plus
                    éligible à cette offre. Veuillez contacter le support pour plus
                    d&apos;informations.
                  </p>
                )}

                <p className="promotion-text">
                  Obtenez immédiatement -50% de réduction sur votre prochaine échéance !
                </p>

                <p>
                  Pour cela, il vous suffit de réactiver votre abonnement en cliquant sur le bouton
                  ci-dessous.
                  <br />
                  Vous n’avez pas besoin de repasser par la case “Paiement”, vos coordonnées sont
                  enregistrées et l’offre sera appliquée sur votre prochaine échéance.
                </p>

                <p className="plan-informations">
                  Vous êtes actuellement sur le plan {subscription.stripe_price.display_name}. En
                  confirmant, ce dernier sera renouvelé le {subscription.current_period_end} avec la
                  réduction de 50%.
                </p>

                <div className="subscription-resume-cta">
                  <button
                    className="btn btn-secondary max-btn"
                    data-cy="btn-resume-subscription-offer"
                    disabled={loadingResumeSubscription}
                    onClick={() => handleResumeSubscription()}
                  >
                    Je profite de l’offre immédiatement
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionResumeOffer;
