import * as React from 'react';

import * as rudder from 'rudder-sdk-js';

type Analytics = typeof rudder;

async function rudderInitialize() {
  const rudderanalytics = await import('rudder-sdk-js');

  if (!process.env.RUDDER_CLIENT_WRITE_KEY || !process.env.RUDDER_DATA_PLANE_URL) {
    return undefined;
  }

  rudderanalytics.load(process.env.RUDDER_CLIENT_WRITE_KEY, process.env.RUDDER_DATA_PLANE_URL, {
    integrations: { All: true }, // load call options
  });

  return new Promise<Analytics>((resolve) => {
    rudderanalytics.ready(() => {
      // eslint-disable-next-line no-console
      console.debug('Rudder Analytics is ready');
    });

    resolve(rudderanalytics);
  });
}

let analytics: Analytics | undefined;

const useAnalytics = (): Analytics | undefined => {
  const [, forceReRender] = React.useState(0);

  React.useEffect(() => {
    if (
      !analytics &&
      typeof window !== 'undefined' &&
      process.env.RUDDER_CLIENT_WRITE_KEY &&
      process.env.RUDDER_CLIENT_WRITE_KEY.length &&
      process.env.RUDDER_DATA_PLANE_URL &&
      process.env.RUDDER_DATA_PLANE_URL.length
    ) {
      const loadAnalytics = async () => {
        const rudderAnalytics = await rudderInitialize();

        analytics = rudderAnalytics;

        // retry in 3 seconds
        if (!analytics) {
          setTimeout(loadAnalytics, 3000);
        }

        forceReRender((prev) => prev + 1);
      };
      loadAnalytics();
    }
  }, []);
  return analytics;
};

export { useAnalytics };
