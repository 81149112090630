import React, { useEffect, useState, useContext } from 'react';
import { ProductTourContext } from '../../../common/providers/ProductTourProvider';
import { UserContext } from '../../../common/providers/UserProvider';
import axios from 'axios';
import ConstructionTypeEdit from './ConstructionTypeEdit';
import TitleIconed from '../../../common/navigation/TitleIconed';
import LocalisationEdit from './LocalisationEdit';
import Toast from '../../../common/navigation/Toast';
import Switch from '../../../common/navigation/Switch';
import Phone from '../../../common/navigation/Phone';
import TradesCategoriesEdit from './TradesCategoriesEdit';
import UserMenu from '../../../common/navigation/UserMenu';
import { useAnalytics } from '../../../common/RudderStack';
import { BACKEND_URL } from '../../../config/environment';

const PreferencesList: React.FC<{ user: IUser }> = ({ user }) => {
  const { updateUser } = useContext(UserContext);
  const { isTourEnabled, triggerNextTourStep } = useContext(ProductTourContext);
  const [states, setStates] = useState<Array<IState>>(user.preference.states);
  const [extendStates, setExtendStates] = useState<boolean>(
    user.preference.extend_to_nearby_states
  );
  const [constructionTypes, setConstructionTypes] = useState<Array<string>>(
    user.preference.construction_types
  );
  const [tradeCategories, setTradesCategories] = useState<Array<ITrades>>(
    user.preference.trade_category_references
  );
  const [success, setSuccess] = useState<boolean>(true);
  const [constructionTypesAreDirty, setConstructionTypesAreDirty] = useState<boolean>(false);
  const [statesAreDirty, setStatesAreDirty] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [statesToggled, setStatesToggled] = useState<boolean>(false);
  const [constructionToggled, setConstructionToggled] = useState<boolean>(false);
  const [tradesCategoriesAreDirty, setTradesCategoriesAreDirty] = useState<boolean>(false);
  const [tradesCategoriesToggled, setTradesCategoriesToggled] = useState<boolean>(false);
  const analytics = useAnalytics();

  useEffect(() => {
    setDisabled(
      !states.length ||
        !constructionTypes.length ||
        !tradeCategories.length ||
        !(constructionTypesAreDirty || statesAreDirty || tradesCategoriesAreDirty)
    );
  }, [isTourEnabled, states, constructionTypes, tradeCategories, extendStates]);

  const openToast = () => {
    setSuccess(false);
    setTimeout(() => {
      setSuccess(true);
    }, 5 * 1000);
  };

  const constructionsAreEqual = (a: Array<string>, b: Array<string>): boolean =>
    a.sort().toString() === b.sort().toString();

  const statesAreEqual = (a: Array<IState>, b: Array<IState>): boolean =>
    a
      .map((s) => s.number)
      .sort()
      .toString() ===
    b
      .map((s) => s.number)
      .sort()
      .toString();

  const tradesCategoriesAreEqual = (a: Array<ITrades>, b: Array<ITrades>): boolean =>
    a
      .map((s) => s.name)
      .sort()
      .toString() ===
    b
      .map((s) => s.name)
      .sort()
      .toString();

  const initializeForm = () => {
    setConstructionTypesAreDirty(false);
    setStatesAreDirty(false);
    setTradesCategoriesAreDirty(false);
    setDisabled(true);
  };

  const updateFilters = () => {
    axios
      .put(`${BACKEND_URL}/api/user/preferences`, {
        extend_to_nearby_states: extendStates,
        construction_types: constructionTypes,
        states: states.map((s) => s.number),
        trade_category_references: tradeCategories,
      })
      .then(({ data }) => {
        analytics?.track('Filters Updated', {
          states: JSON.stringify(states),
          construction_types: JSON.stringify(constructionTypes),
          trade_category_references: JSON.stringify(tradeCategories),
        });

        openToast();

        if (isTourEnabled) {
          triggerNextTourStep();
        }
        //Update the global user context with the new preferences
        updateUser({ ...user, preference: data.preference });
        initializeForm();
      });
  };

  const updateStates = (state: IState) => {
    let newState: Array<IState>;
    if (states.some((item) => item.number === state.number)) {
      newState = states.filter((value) => value.number !== state.number);
    } else {
      newState = [...states, state];
    }
    setStates(newState);

    setStatesAreDirty(!statesAreEqual(user.preference.states, newState));
  };

  const updateAllStates = (stateList: Array<IState>) => {
    setStates(stateList);
    setStatesAreDirty(!statesAreEqual(user.preference.states, stateList));
  };
  const updateSwitch = () => {
    setExtendStates((v) => {
      const newState = !v;
      setStatesAreDirty((dirty) => dirty || newState !== user.preference.extend_to_nearby_states);

      return newState;
    });
  };

  const updateConstructionTypes = (constructionType: string) => {
    let newState: Array<string>;
    if (constructionTypes.includes(constructionType)) {
      newState = constructionTypes.filter((value) => value !== constructionType);
    } else {
      newState = [...constructionTypes, constructionType];
    }
    setConstructionTypes(newState);

    setConstructionTypesAreDirty(
      !constructionsAreEqual(newState, user.preference.construction_types)
    );
  };

  const updateTradesCategories = (trade_category: ITrades) => {
    let newTradeCategory: Array<ITrades>;
    if (tradeCategories.some((item) => item.name === trade_category.name)) {
      newTradeCategory = tradeCategories.filter((value) => value.name !== trade_category.name);
    } else {
      newTradeCategory = [...tradeCategories, trade_category];
    }
    setTradesCategories(newTradeCategory);

    setTradesCategoriesAreDirty(
      !tradesCategoriesAreEqual(user.preference.trade_category_references, newTradeCategory)
    );
  };

  const updateAllTradesCategories = (tradesCategoriesList: Array<ITrades>) => {
    setTradesCategories(tradesCategoriesList);
    setTradesCategoriesAreDirty(
      !tradesCategoriesAreEqual(user.preference.trade_category_references, tradesCategoriesList)
    );
  };

  const onCheckAllTypes = (types: Array<string>) => {
    setConstructionTypes(types);

    setConstructionTypesAreDirty(!constructionsAreEqual(types, user.preference.construction_types));
  };

  const diplayBadges = (a: Array<string>, displayAll: boolean) => {
    const data = displayAll ? a : a.slice(0, 5);

    return data.map((name, index) => (
      <span key={index} className="badge badge-light-blue">
        {name}
      </span>
    ));
  };

  const loadCards = () => (
    <div className="row">
      <div className="col-12">
        <div className="card card-body" style={{ borderRadius: '10px' }}>
          <h2 className="title-card-option">Départements</h2>

          <div className="mt-2">
            <span className="mini-title">Départements sélectionnés</span>
            <div>
              {states.length === 0 ? (
                <div className="m-3 text-muted">
                  Vous n’avez pas encore sélectionné de départements
                </div>
              ) : (
                diplayBadges(
                  states.map((s) => s.name),
                  statesToggled
                )
              )}

              <span
                className="badge badge-light-grey"
                role="button"
                onClick={() => setStatesToggled((t) => !t)}
                hidden={states.length <= 5}
              >
                {statesToggled ? 'Réduire' : `Tout voir... (${states.length - 5})`}
              </span>
            </div>
          </div>

          <span className="mini-title mt-3">Ajouter des départements</span>
          <LocalisationEdit
            updateAllStates={updateAllStates}
            updateStates={updateStates}
            states={states}
            background="#F2F4F8"
          />

          <hr style={{ marginRight: '-1.25rem', marginLeft: '-1.25rem' }} />

          <Switch
            id="nearby-states"
            data-cy="nearby-states"
            label="Étendre aux départements à proximité"
            checked={extendStates}
            onChange={updateSwitch}
          />
        </div>
      </div>

      <div className=".d-block .d-sm-none m-2" />

      <div className="col-12">
        <div className="card card-body" style={{ borderRadius: '10px' }}>
          <h2 className="title-card-option">Lots</h2>

          <div className="mt-2">
            <span className="mini-title">Lots sélectionnés</span>
            <div>
              {tradeCategories.length === 0 ? (
                <div className="m-3 text-muted">Vous n’avez pas encore sélectionné de Lots</div>
              ) : (
                diplayBadges(
                  tradeCategories.map((s) => s.name),
                  tradesCategoriesToggled
                )
              )}

              <span
                className="badge badge-light-grey"
                role="button"
                onClick={() => setTradesCategoriesToggled((t) => !t)}
                hidden={tradeCategories.length <= 5}
              >
                {tradesCategoriesToggled
                  ? 'Réduire'
                  : `Tout voir... (${tradeCategories.length - 5})`}
              </span>
            </div>
          </div>

          <span className="mini-title mt-3">Ajouter des lots</span>
          <TradesCategoriesEdit
            updateAllTrades={updateAllTradesCategories}
            updateTrades={updateTradesCategories}
            trades={tradeCategories}
            background="#F2F4F8"
          />

          <hr style={{ marginRight: '-1.25rem', marginLeft: '-1.25rem' }} />
        </div>
      </div>
      <div className=".d-block .d-sm-none m-2" />

      <div className="col-12 ">
        <div className="card card-body" style={{ borderRadius: '10px' }}>
          <h2 className="title-card-option">Types de constructions</h2>
          <div className="mt-2">
            <span className="mini-title">Types de constructions sélectionnés</span>
            <div>
              {constructionTypes.length === 0 ? (
                <div className="m-3" style={{ opacity: '0.5' }}>
                  Vous n’avez pas encore sélectionné de type de construction
                </div>
              ) : (
                diplayBadges(constructionTypes, constructionToggled)
              )}

              <span
                className="badge badge-light-grey"
                role="button"
                onClick={() => setConstructionToggled((t) => !t)}
                hidden={constructionTypes.length <= 5}
              >
                {constructionToggled ? 'Réduire' : `Tout voir... (${constructionTypes.length - 5})`}
              </span>
            </div>
          </div>

          <span className="mini-title mt-3">Ajouter des types de constructions</span>
          <ConstructionTypeEdit
            updateConstructionTypes={updateConstructionTypes}
            onCheckAllTypes={onCheckAllTypes}
            constructionTypes={constructionTypes}
            background="#F2F4F8"
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="noun_filter_activated" text="Critères de recherche" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description mb-5">
        Retrouvez ici les critères de recherche de vos projets. Vous pouvez modifier ces
informations en cliquant sur le bouton Enregistrer.
        <br />
        En maintenant ces informations à jour, nous vous proposons les chantiers les plus adaptés à
        vos besoins !
      </p>
      <div id="tour__filters-edit">
        {user && user.preference && loadCards()}
        <div style={{ height: 50, marginTop: 5 }}>
          <button
            disabled={disabled}
            type="button"
            onClick={updateFilters}
            id="save-preferences"
            data-cy="save-preferences"
            className="btn btn-secondary float-right"
            style={{ maxWidth: 240 }}
          >
            Enregistrer
          </button>
        </div>
      </div>
      <Toast
        message="Vos modifications ont bien été sauvegardées."
        title="Préférences de recherches"
        hidden={success}
        onClick={() => setSuccess(true)}
      />
    </>
  );
};

export default PreferencesList;
