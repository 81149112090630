import React from 'react';
import Checkbox from '../../../common/navigation/Checkbox';

export const originalConstructionTypeList = [
  'Logements',
  'Grandes surfaces',
  'Établissements de santé',
  'Tertiaire',
  'Autre',
];

const ConstructionTypeEdit: React.FC<{
  constructionTypes: Array<string>;
  updateConstructionTypes: (constructionType: string) => void;
  onCheckAllTypes: (types: Array<string>) => void;
  disabled?: Array<string>;
  background?: string;
}> = ({
  constructionTypes,
  updateConstructionTypes,
  onCheckAllTypes,
  disabled = [],
  background = '#fff',
}) => {
  const allAvailableTypes = () => originalConstructionTypeList.filter((t) => !disabled.includes(t));

  const areAllAvailableTypesChecked = () => {
    return constructionTypes.sort().join() === allAvailableTypes().sort().join();
  };

  const handleCheckAllConstructionTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // When Checking on All types we must ommit the disabled one
      onCheckAllTypes(allAvailableTypes());
    } else {
      onCheckAllTypes([]);
    }
  };

  return (
    <>
      <div
        className="multi-checkboxes profile mt-2"
        style={{ height: '260px', overflowY: 'scroll', backgroundColor: background }}
      >
        <Checkbox
          id="all-constructions-checkbox"
          data-cy="all-constructions-checkbox"
          onChange={handleCheckAllConstructionTypes}
          checked={areAllAvailableTypesChecked()}
          label={'Tous types de constructions'}
        />
        {originalConstructionTypeList.map((element, index) => (
          <Checkbox
            key={index}
            id={element}
            data-cy={element}
            onChange={() => updateConstructionTypes(element)}
            checked={constructionTypes.includes(element)}
            disabled={disabled.includes(element)}
            label={element}
          />
        ))}
      </div>
    </>
  );
};

export default ConstructionTypeEdit;
