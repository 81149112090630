import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import CircularLoader from '../../../common/navigation/CircularLoader';
import TitleIconed from '../../../common/navigation/TitleIconed';
import PurchaserWrapper from '../PurchaserWrapper';
import ProjectRequest from './ProjectRequest';
import Tab from '../Tab';
import { BACKEND_URL } from '../../../config/environment';

const ProjectRequestsValidation: React.FC = () => {
  const [projects, setProjects] = useState<Array<IProject>>(null);
  const [selectedProject, setSelectedProject] = useState<IProject>(null);
  const [projectRequests, setProjectRequests] = useState<Array<IProjectRequest>>(null);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [loadingProjectRequests, setLoadingProjectRequests] = useState<boolean>(true);
  const [projectError, setProjectError] = useState<boolean>(false);
  const [savingProjectRequests, setSavingProjectRequests] = useState<boolean>(false);

  const { token } = useParams<{ token: string }>();

  /** Get the projects. */
  useEffect(() => {
    getProjects();
  }, []);

  /** Get the project requests of the selected project. */
  useEffect(() => {
    if (selectedProject) {
      getProjectRequests();
    }
  }, [selectedProject]);

  /**
   * Get projects.
   */
  const getProjects = () => {
    setLoadingProjects(true);
    axios
      .get(`${BACKEND_URL}/api/public/purchasers/${token}/projects`, {
        params: { project_requests_status: ['requested'] },
      })
      .then(({ data }) => {
        setProjects(data.projects);
        setSelectedProject(data.projects.length ? data.projects[0] : null);
      })
      .catch(() => {
        setProjectError(true);
      })
      .finally(() => {
        setLoadingProjects(false);
      });
  };

  /**
   * Get the project requests for the selected project.
   */
  const getProjectRequests = () => {
    setLoadingProjectRequests(true);
    axios
      .get(`${BACKEND_URL}/api/public/purchasers/${token}/projects/${selectedProject.id}/project-requests`, {
        params: {
          status: ['requested'],
        },
      })
      .then(({ data }) => {
        setProjectRequests(data.project_requests);
      })
      .finally(() => {
        setLoadingProjectRequests(false);
      });
  };

  /**
   * Update the status of all the request trades for the project request id.
   * @param {validated | refused} status - The status of the request trade.
   * @param {string} projectRequestId - The project request id.
   */
  const updateRequestTradesStatus = (status: 'validated' | 'refused', projectRequestId: string) => {
    const newProjectRequests = projectRequests.slice();
    const index = projectRequests.findIndex(
      (projectRequest) => projectRequest.id === projectRequestId
    );
    const requestTrades = projectRequests[index].request_trades.map((requestTrade) => ({
      ...requestTrade,
      status: status,
    }));

    newProjectRequests[index].request_trades = requestTrades;

    setProjectRequests(newProjectRequests);
  };

  /**
   *  Update the status of a request trade id for a given project request.
   * @param {validated | refused} status - The status of the request trade.
   * @param {string} projectRequestId - The project request id.
   * @param {string} requestTradeId - The request trade id.
   */
  const updateRequestTradeStatus = (
    status: 'validated' | 'refused',
    projectRequestId: string,
    requestTradeId: string
  ) => {
    const newProjectRequests = projectRequests.slice();

    const index = projectRequests.findIndex(
      (projectRequest) => projectRequest.id === projectRequestId
    );
    const requestTrades = projectRequests[index].request_trades.map((requestTrade) =>
      requestTradeId === requestTrade.id
        ? {
            ...requestTrade,
            status: status,
          }
        : { ...requestTrade }
    );

    newProjectRequests[index].request_trades = requestTrades;

    setProjectRequests(newProjectRequests);
  };

  /**
   * Check if at least one project has no requested status.
   * @returns boolean
   */
  const getSavableProjects = () => {
    const savableProjectRequests = projectRequests.filter(
      (projectRequest) =>
        projectRequest.request_trades.filter((requestTrade) => requestTrade.status === 'requested')
          .length === 0
    );

    return savableProjectRequests;
  };

  /**
   * Save all the project requests for the selected project.
   */
  const saveRequestTrades = () => {
    setSavingProjectRequests(true);
    const promises: Array<Promise<void>> = [];

    getSavableProjects().forEach((projectRequest) => {
      // Prepare the data for sending to the server.
      const requestTrades = projectRequest.request_trades.map((requestTrade) => {
        return {
          id: requestTrade.id,
          status: requestTrade.status,
        };
      });
      promises.push(
        axios.put(`${BACKEND_URL}/api/public/purchasers/${token}/project-requests/${projectRequest.id}`, {
          request_trades: requestTrades,
        })
      );
    });

    Promise.all(promises).finally(() => {
      getProjects();
      setSavingProjectRequests(false);
    });
  };

  return (
    <PurchaserWrapper>
      <div className="container-fluid container-app">
        <div className="content-app">
          <div className="container-center">
            {loadingProjects ? (
              <div className="content">
                <div className="content-spinner">
                  <CircularLoader />
                </div>
              </div>
            ) : (
              <>
                <TitleIconed icon="check-plain" text="GESTION D’ACCÈS AUX ENTREPRISES" />
                <p className="sub-description">
                  Autorisez ou refusez les nouvelles demandes d&apos;accès sur les différents lots.
                </p>
                {projectError ? (
                  <div
                    className="alert alert-danger d-flex justify-content-between"
                    style={{ borderRadius: '20px' }}
                  >
                    <div>Lien expiré ou invalide.</div>
                  </div>
                ) : (
                  <>
                    {projects && projects.length > 0 ? (
                      <>
                        <Tab
                          projects={projects}
                          setSelectedProject={setSelectedProject}
                          selectedProject={selectedProject}
                        />
                        {loadingProjectRequests ? (
                          <div className="content">
                            <div className="content-spinner">
                              <CircularLoader />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="project-requests">
                              {projectRequests.map((projectRequest, index) => (
                                <ProjectRequest
                                  key={`project-request-${index}`}
                                  selectedProject={selectedProject}
                                  projectRequest={projectRequest}
                                  updateRequestTradesStatus={updateRequestTradesStatus}
                                  updateRequestTradeStatus={updateRequestTradeStatus}
                                />
                              ))}
                            </div>

                            <div className="global-button">
                              <button
                                onClick={() => saveRequestTrades()}
                                disabled={
                                  getSavableProjects().length === 0 || savingProjectRequests
                                }
                                className="btn btn-secondary save-button"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div
                        className="alert alert-success d-flex justify-content-between"
                        style={{ borderRadius: '20px' }}
                      >
                        <div>Aucune nouvelle demande à valider.</div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PurchaserWrapper>
  );
};

export default ProjectRequestsValidation;
