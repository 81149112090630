import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

const SubscriptionPromotion: React.FC<{
  handleGetPromotion: (promotionCode: string) => Promise<void>;
}> = ({ handleGetPromotion }) => {
  const promotionCodeFromParam = queryString.parse(location.search)?.promotion_code;
  const [promotionCode, setPromotionCode] = useState(promotionCodeFromParam ?? '');
  const [loading, setLoading] = useState(false);
  const [promotionError, setPromotionError] = useState(false);
  const [promotionSuccess, setPromotionSuccess] = useState(false);

  const handlePromotionCodeChange = (promotionCode: string) => {
    setPromotionCode(promotionCode);
  };

  useEffect(() => {
    if (promotionCodeFromParam) {
      setPromotionCode(promotionCodeFromParam);
      handleSubmit(promotionCodeFromParam);
    }
  }, [promotionCodeFromParam]);

  const handleSubmit = (promotionCode: string) => {
    setLoading(true);
    setPromotionSuccess(false);
    setPromotionError(false);

    handleGetPromotion(promotionCode)
      .then(() => {
        setPromotionSuccess(true);
      })
      .catch(() => {
        setPromotionError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {promotionError && (
        <div className="row mb-3 text-center alert alert-danger">
          <span className="">Votre coupon est expiré ou invalide.</span>
        </div>
      )}

      {promotionSuccess && (
        <div className="row mb-3 text-center alert alert-success">
          <span className="">Votre coupon a été appliqué avec succès.</span>
        </div>
      )}

      <div className="row card-promo mt-4">
        <div className="col-lg-3 col-sm-12 text-center">
          <span>J’ai un coupon de promotion :</span>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <input
            type="text"
            placeholder="Entrez votre code"
            className="form-control"
            onChange={(e) => handlePromotionCodeChange(e.target.value)}
            value={promotionCode}
          />
        </div>
        <div className="col-lg-3 col-sm-12 text-center">
          <button
            type="button"
            disabled={!promotionCode || loading}
            onClick={() => handleSubmit(promotionCode)}
            className="btn btn-secondary"
            style={{ maxWidth: 240 }}
          >
            Appliquer
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPromotion;
