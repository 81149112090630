import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from 'react';

const countries = new Map([
  [
    'BE',
    {
      key: 'BE',
      icon: '/Icons/flags/BE.png',
      name: 'Belgique',
      code: '32',
    },
  ],
  [
    'FR',
    {
      key: 'FR',
      icon: '/Icons/flags/FR.png',
      name: 'France',
      code: '33',
    },
  ],
  [
    'LU',
    {
      key: 'LU',
      icon: '/Icons/flags/LU.png',
      name: 'Luxembourg',
      code: '352',
    },
  ],
  [
    'CH',
    {
      key: 'CH',
      icon: '/Icons/flags/CH.png',
      name: 'Suisse',
      code: '41',
    },
  ],
  [
    'RE',
    {
      key: 'RE',
      icon: '/Icons/flags/FR.png',
      name: 'Réunion',
      code: '262',
    },
  ],
  [
    'GP',
    {
      key: 'GP',
      icon: '/Icons/flags/FR.png',
      name: 'Guadeloupe',
      code: '590',
    },
  ],
  [
    'GF',
    {
      key: 'GF',
      icon: '/Icons/flags/FR.png',
      name: 'Guyane',
      code: '594',
    },
  ],
  [
    'MQ',
    {
      key: 'MQ',
      icon: '/Icons/flags/FR.png',
      name: 'Martinique',
      code: '596',
    },
  ],
  [
    'YT',
    {
      key: 'YT',
      icon: '/Icons/flags/FR.png',
      name: 'Mayotte',
      code: '296',
    },
  ],
  [
    'NC',
    {
      key: 'NC',
      icon: '/Icons/flags/FR.png',
      name: 'Nouvelle-Calédonie',
      code: '687',
    },
  ],
  [
    'PF',
    {
      key: 'PF',
      icon: '/Icons/flags/FR.png',
      name: 'Polynésie française',
      code: '689',
    },
  ],
  [
    'PM',
    {
      key: 'PM',
      icon: '/Icons/flags/FR.png',
      name: 'Saint-Pierre-et-Miquelon',
      code: '508',
    },
  ],
  [
    'WF',
    {
      key: 'WF',
      icon: '/Icons/flags/FR.png',
      name: 'Wallis-et-Futuna',
      code: '681',
    },
  ],
]);
interface PhoneInputProps {
  onPhoneChange?: ChangeEventHandler<HTMLInputElement>;
  onCodeClick?: MouseEventHandler<HTMLButtonElement>;
  value?: { phone: string; code: string };
  disabled?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  onPhoneChange,
  onCodeClick,
  value = { phone: '', code: 'FR' },
  disabled = false,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleCodeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(false);

    if (onCodeClick) {
      onCodeClick(e);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onPhoneChange) {
      onPhoneChange(e);
    }
  };

  const formatCode = (code: string) => `(+${code})`;

  const selected = () => countries.get(value.code ?? 'FR');

  const displayChevron = () => {
    const source = visible ? '/Icons/chevron-up.svg' : '/Icons/chevron-down.svg';

    return <img src={source} height="15" width="15" />;
  };

  useEffect(() => {
    const onClickEvent = (e: MouseEvent) => {
      const menu = document.getElementById('phone-code-dropdown');
      const target = e.target as Node;

      if (!menu.contains(target)) {
        setVisible(false);
      }
    };

    document.addEventListener('click', onClickEvent);

    return () => document.removeEventListener('click', onClickEvent);
  }, []);

  return (
    <div className="phone-input-group">
      <div
        className={`code-wrapper ${disabled ? 'disabled' : ''}`}
        id="phone-code-dropdown"
        data-cy="phone-code-dropdown"
      >
        <button
          type="button"
          className="toggle"
          onClick={() => setVisible((v) => !v)}
          disabled={disabled}
        >
          <img className="flag-icon" src={selected().icon} alt={selected().name} />
          {formatCode(selected().code)}
          {displayChevron()}
        </button>

        <div className={`phone-dropdown ${visible ? 'show' : ''}`}>
          {Array.from(countries.values()).map((c) => (
            <button
              type="button"
              key={c.key}
              className="item"
              value={c.key}
              title={c.name}
              onClick={handleCodeClick}
            >
              <img className="flag-icon" src={c.icon} alt={c.name} />
              {formatCode(c.code)}
            </button>
          ))}
        </div>
      </div>

      <input
        id="phone"
        data-cy="phone"
        type="text"
        className="form-control"
        placeholder="Téléphone"
        autoComplete="off"
        name="phone"
        maxLength={20}
        value={value.phone ?? ''}
        onChange={handleOnChange}
        disabled={disabled}
      />
    </div>
  );
};

export default PhoneInput;
