import React from 'react';
import Button from '../../../common/navigation/Button';

const UpdateCardMonthly: React.FC<{
  displayAdvantages: boolean;
  displayButton: boolean;
  handleChange?: () => void;
  price: number;
  title: string;
}> = ({ displayAdvantages, displayButton, handleChange, price, title }) => {
  return (
    <div
      className="card card-subscription-monthly card-plan card-plan-monthly plan"
      style={displayAdvantages ? {} : { height: '250px' }}
    >
      <div className="card-header-title">
        <span>{title}</span>
      </div>
      <div className="card-title">
        <h3>Mensuel</h3>
      </div>
      <h1>
        {price}€<sup className="large">HT</sup>
      </h1>
      <h4 className="under-price">Facturé chaque mois</h4> <br />
      {displayAdvantages && (
        <ul className="check-list">
          <li className="icon-monthly">
            <b>Exclusivité</b> des chantiers
          </li>
          <li className="icon-monthly">
            <b>Adaptés</b> à votre activité
          </li>
          <li className="icon-monthly">
            Accès <b>illimité</b> aux consultations
          </li>
          <li className="icon-monthly">
            Abonnement <b>sans engagement</b>
          </li>
        </ul>
      )}
      {displayButton && (
        <div className="button">
          <Button color="btn-secondary" text={"Je m'abonne"} action={() => handleChange()} />
        </div>
      )}
    </div>
  );
};

export default UpdateCardMonthly;
