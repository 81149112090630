import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '../../common/navigation/Button';
import axios from 'axios';
import { useAnalytics } from '../../common/RudderStack';
import { BACKEND_URL } from '../../config/environment';

const OfferItem: React.FC<{ stripePrice: IStripePrice }> = ({ stripePrice }) => {
  const stripe = useStripe();
  const analytics = useAnalytics();
  const elements = useElements();

  const [cguAccepted, setCguAccepted] = useState<boolean>(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const handleCgu = () => {
    setCguAccepted(!cguAccepted);
  };

  /**
   * Get reccurency.
   *
   * @returns {string}
   */
  const getReccurency = (): string => {
    switch (stripePrice.price_after_expiration?.name) {
      case 'monthly':
        return 'mois';
      case 'quarterly':
        return 'trimestre';
      case 'annually':
        return 'an';
    }
  };

  const getCheckoutUrl = (price: IStripePrice) => {
    if (stripe && elements) {
      setLoadingCheckout(true);

      axios
        .post(`${BACKEND_URL}/api/stripe/trial-checkout-session`, { price_name: price.name })
        .then(({ data }) => {
          analytics?.track('Checkout Started', {
            revenue: price.price_excl_tax,
            currency: 'EUR',
            products: [
              {
                product_id: price.id,
                name: price.display_name,
                price: price.price_excl_tax,
                quantity: 1,
              },
            ],
          });

          stripe.redirectToCheckout({ sessionId: data.checkout_session_id }).finally(() => {
            setLoadingCheckout(false);
          });
        });
    }
  };

  return (
    <div
      key={`pp-${stripePrice.id}`}
      className="card mb-3 paid-now-card mt-3 card-subscription-monthly offer"
    >
      <div className="row no-gutters">
        <div className="col-lg-4">
          <div className="card card-plan card-plan-offer">
            <div
              className="card card-plan card-plan-offer plan"
              style={{ padding: '10px', boxShadow: 'none' }}
            >
              <div className="card-title">
                <h3>{stripePrice.display_name}</h3>
              </div>

              <h1 style={{ fontSize: '40px' }}>
                {stripePrice.price_excl_tax}€
                <sup className="large" style={{ fontSize: '25px' }}>
                  HT
                </sup>
              </h1>

              <p>
                Jusqu&apos;au <b>{stripePrice.end_of_trial}</b>
              </p>
              <p>
                Puis{' '}
                <span>
                  {stripePrice.price_after_expiration?.price_excl_tax}€HT/
                  <sub>{getReccurency()}</sub> <br />
                  par abonnement
                </span>
              </p>

              <ul className="check-list" style={{ top: 170 }}>
                <li className="icon-monthly">
                  <b>Exclusivité</b> des chantiers
                </li>
                <li className="icon-monthly">
                  <b>Adaptés</b> à votre activité
                </li>
                <li className="icon-monthly">
                  Accès <b>illimité</b> aux consultations
                </li>
                <li className="icon-monthly">
                  Abonnement <b>sans engagement</b>
                </li>
              </ul>
              <p />
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="card-body text-left">
            <div className="alert alert-success mt-4">
              Vous allez être redirigé vers notre plateforme sécurisée de paiement afin de saisir
              vos coordonnées bancaires.
            </div>
            <input type="checkbox" onChange={handleCgu} name="cgu" style={{ borderRadius: 0 }} /> En
            souscrivant un abonnement, vous acceptez les{' '}
            <a href={`${process.env.SAQARA_URL_CGV}`} target="_blank" rel="noreferrer">
              conditions générales de ventes de Saqara
            </a>
          </div>

          <div className="mt-1">
            <Button
              style={{ maxWidth: '240px' }}
              color="btn-secondary max-width-240"
              disabled={!cguAccepted}
              action={() => getCheckoutUrl(stripePrice)}
              text="Je m'abonne"
              loading={loadingCheckout}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferItem;
