import React, { useState, useContext } from 'react';
import axios from 'axios';
import Modal from './index';
import Button from '../navigation/Button';
import ErrorMessage from '../errors/ErrorMessage';
import { Link } from 'react-router-dom';
import PasswordInput from '../../components/authentication/PasswordInput';
import { useAnalytics } from '../RudderStack';
import { BACKEND_URL } from '../../config/environment';
import { UserContext } from '../../common/providers/UserProvider';

/**
 * This react component is used to see the login modal.
 * This modal is used to inform the user if the mail or the password is wrong.
 * @param props
 * @constructor
 */
const SignInModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
  toCall: () => void;
  switchModal: () => void;
}> = (props) => {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const analytics = useAnalytics();
  const { login } = useContext(UserContext);

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();

    if (email && password) {
      analytics?.track('User Logged In', {
        email: email,
      });

      const toMail = email.trim().toLowerCase();
      setLoading(true);
      axios
        .post(`${BACKEND_URL}/api/login`, { mail: toMail, password })
        .then(({ data }) => {
          analytics?.identify(data.id, {
            name: data.name,
            email: data.mail,
          });
          login(data);
          props.toCall();
        })
        .catch(() => {
          setError('Connexion échoué, veuillez réessayer.');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal hidden={props.hidden} closeModal={props.closeModal} maxWidth={600}>
      <div className="modal-body">
        <img
          src="/images/logo_saqara.png"
          alt="Logo chantier privé par Saqara"
          className="logo-external-project"
        />

        <p className="mt-4">
          Connectez-vous avec votre email
          <br />
          et votre mot de passe.
        </p>

        {error && <ErrorMessage text={error} />}

        <form onSubmit={handleLogin}>
          <input
            type="text"
            className="form-control"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <PasswordInput onChange={(e) => setPassword(e.target.value)} />

          <button
            className="btn btn-secondary mt-3"
            type="submit"
            disabled={loading || !email || !password}
          >
            Se connecter
          </button>
        </form>

        <div className="pt-3 pb-0">
          <Link
            id="link-forgot-password"
            data-cy="link-forgot-password"
            to="/reset-password"
            style={{ color: '#EDAE47', textDecoration: 'underline' }}
            href="#"
          >
            Mot de passe oublié
          </Link>
        </div>

        <Button
          color="btn-outline-secondary mt-5"
          text={"S'inscrire gratuitement"}
          action={() => props.switchModal()}
        />
      </div>
    </Modal>
  );
};

export default SignInModal;
