import React, { useState } from 'react';
import ContactModal from '../../common/Modal/ContactModal';

const TrialPaymentFailed: React.FC<{
  user: IUser;
}> = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ContactModal hidden={open} closeModal={() => setOpen(false)} />
      <div className="card card-body p-0">
        <div className="row no-gutters justify-content-center m-3">
          <h1 className="mx-auto text-center">PAIEMENT ECHOUÉ !</h1>
          <p className="p-2 mt-2">
            Votre paiement a été refusé. Pour pouvoir profiter de notre service, veuillez ajouter
            une méthode de paiement valide.
            <br />
            <br />
            <b>Nous sommes à votre disposition si vous avez la moindre question !</b>
          </p>

          <div className="col-lg-4">
            <button className="btn btn-outline-warning" onClick={() => setOpen(true)}>
              NOUS CONTACTER
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrialPaymentFailed;
