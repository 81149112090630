import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import SignInModal from '../../common/Modal/SignInModal';
import SignUpModal from '../../common/Modal/SignUpModal';
import SelectTradeModal from '../../common/Modal/SelectTradeModal';
import CircularLoader from '../../common/navigation/CircularLoader';
import { BACKEND_URL } from '../../config/environment';

const Landing: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  const [user, setUser] = useState<IUser>(null);
  const [project, setProject] = useState<IProject>(undefined);
  const [signInModalOpen, setSignInModalOpen] = useState<boolean>(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState<boolean>(false);
  const [lotsOpen, setLotsOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [selectedTrade, setSelectedTrade] = useState<Array<string>>([]);

  useEffect(() => {
    setLoading(true);
    getUser();
    const query = queryString.parse(location.search);
    axios
      .get(`${BACKEND_URL}/api/public/projects/external/${query.projectId}`)
      .then(({ data }) => {
        setProject(data.project);
      })
      .catch(() => {
        setErrorMessage("Désolé, ce projet n'existe pas ou n'est pas disponible sans inscription.");
      });
  }, []);

  const getUser = () => {
    axios
      .get(`${BACKEND_URL}/api/user`)
      .then(({ data }) => {
        if (data.user && data.user.is_subscriber) {
          history.push('/consultation_open');
        } else {
          setUser(data.user);
          setLoading(false);
        }
      })
      .catch(() => {
        setLotsOpen(false);
        setLoading(false);
      });
  };

  const switchModal = () => {
    setSignUpModalOpen(!signUpModalOpen);
    setSignInModalOpen(!signInModalOpen);
  };

  const openLotsModal = () => {
    setLotsOpen(true);
    setLoading(true);
    setSignUpModalOpen(false);
    setSignInModalOpen(false);
    getUser();
  };

  return (
    <div className="container height-100">
      <SignInModal
        hidden={signInModalOpen}
        closeModal={() => setSignInModalOpen(false)}
        toCall={() => openLotsModal()}
        switchModal={() => switchModal()}
      />
      <SignUpModal
        hidden={signUpModalOpen}
        closeModal={() => setSignUpModalOpen(false)}
        toCall={() => openLotsModal()}
        switchModal={() => switchModal()}
      />

      {project && !loading && (
        <SelectTradeModal
          project={project}
          hidden={lotsOpen}
          closeModal={() => setLotsOpen(false)}
          toCall={() => history.push('/landing/formulas')}
          user={user}
          forceAccess={true}
          selectedTrades={selectedTrade}
          setSelectedTrades={setSelectedTrade}
          openConsultation={user?.open_consultation}
        />
      )}

      <Link to="/">
        <img
          src="/images/logo_horizontal_saqara.png"
          alt="Chantier Privé par Saqara"
          className="logo-external-project"
        />
      </Link>

      <div className="d-flex align-items-center" style={{ height: 'calc(100% - 80px)' }}>
        <div className="card card-body no-padding card-external-project">
          {errorMessage && (
            <div className="p-20">
              <div className="alert alert-danger">{errorMessage}</div>
            </div>
          )}

          {(!project && !errorMessage) ||
            (loading && (
              <div className="p-20">
                <CircularLoader />
              </div>
            ))}

          {project && !loading && !lotsOpen && (
            <>
              <img src={project.image_path} alt={`${project.name} image`} />

              <div className="p-20">
                <h1 className="text-center">{project.name}</h1>
                <p className="mt-2 text-left">
                  <span className="co-sub-detail">Description du projet :</span>{' '}
                  {project.description}
                </p>
                <div className="row">
                  <div className="col-7">
                    <span className="co-sub-detail">Début d&apos;appel d&apos;offres :</span>
                    {project.begin_of_offering_date
                      ? ` le ${project.begin_of_offering_date}`
                      : ' NC'}
                    <br />
                    {!project.is_ended && project.end_of_offering_date && (
                      <>
                        <span className="co-sub-detail">Fin d&apos;appel d&apos;offres :</span>
                        <span> le {project.end_of_offering_date}</span>
                      </>
                    )}
                    {!project.is_coming ? (
                      <span style={{ color: '#75C493' }}> Ouvert à la consultation</span>
                    ) : (
                      <span style={{ color: '#CEC7C7', fontWeight: 'bold' }}>
                        Bientôt ouvert à la consultation
                      </span>
                    )}
                    <br />
                    <span className="co-sub-detail">Début des travaux :</span>
                    {project.is_building_started ||
                    (!project.is_building_started && !project.building_site_start_date)
                      ? ' NC'
                      : ` le ${project.building_site_start_date}`}
                  </div>
                  <div className="col-5">
                    <span className="co-sub-detail">Type d&apos;opération :</span>{' '}
                    {project.construction_type ?? ' NC'}
                    <br />
                    <span className="co-sub-detail">Ville :</span> {project.city ?? ' NC'}
                    <br />
                    <span className="co-sub-detail">Code postal :</span> {project.zip_code ?? ' NC'}
                  </div>
                </div>

                <button
                  className="btn btn-secondary mt-4"
                  onClick={() => (user ? openLotsModal() : setSignUpModalOpen(true))}
                >
                  ACCÉDER AU DCE
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Landing;
