import React, { useState, useContext } from 'react';
import axios from 'axios';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { UserContext } from '../../common/providers/UserProvider';
import { BACKEND_URL } from '../../config/environment';
import ProjectPublicListElement from './ProjectPublicListElement';

const ProjectPublicRequest: React.FC<{
  project?: IProject;
  user: IUser;
  reload?: () => void;
  projectRequest?: IProjectRequest;
  projectPublicRequest?: boolean;
}> = ({ user, reload, project, projectRequest, projectPublicRequest }) => {
  const { refreshUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState(user.first_name ?? '');
  const [lastName, setLastName] = useState(user.last_name ?? '');
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // /**
  //  * Create a project public request.
  //  *
  //  * @param event Form event.
  //  */
  // const handleCreateProjectPublicRequest = (event: React.FormEvent) => {
  //   event.preventDefault();
  //   setErrorMessage(null);
  //   setDisabled(true);
  //   axios
  //     .post('/api/project-public-requests', {
  //       first_name: firstName,
  //       last_name: lastName,
  //       open_consultation: user.open_consultation,
  //     })
  //     .then(() => {
  //       refreshUser();
  //       reload();
  //     })
  //     .catch((err) => {
  //       const error = err?.response?.data?.errorMessage;
  //       setErrorMessage(error ? error : 'Une erreur est survenue.');
  //       setDisabled(false);
  //     });
  // };

  /**
   * Send an information request.
   *
   * @param event Form event.
   */
  const handleSendInformationRequest = (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage(null);
    setDisabled(true);
    axios
      .post(`${BACKEND_URL}/api/information-requests`, {
        first_name: firstName,
        last_name: lastName,
      })
      .then(() => {
        refreshUser();
        reload();
      })
      .catch((err) => {
        const error = err?.response?.data?.errorMessage;
        setErrorMessage(error ? error : 'Une erreur est survenue.');
        setDisabled(false);
      });
  };

  return (
    <div className="row">
      <div className="project-request-box">
        {!project ? (
          <div>
            <div
              className="card card-body blurred-card-consultation with-flash"
              style={{ marginLeft: '15px' }}
            >
              <div style={{ position: 'relative' }}>
                <img src={'images/blurred_project.png'} className="card-img-top" alt="Projet" />
              </div>

              <div
                className="content"
                style={{ paddingTop: 25, paddingLeft: 25, paddingRight: 25 }}
              >
                <span className="blurred-title"></span>

                <span className="blurred-description"></span>

                <span className="blurred-sub-description"></span>

                <button className="btn btn-secondary btn-card-consultation" disabled={true}>
                  Accéder au projet
                </button>
              </div>
            </div>
          </div>
        ) : (
          <ProjectPublicListElement
            key={`public-project`}
            project={project}
            projectPublicRequest={projectPublicRequest ? true : false}
            projectRequest={projectRequest}
            user={user}
            marginTop={0}
          />
        )}
        <div className="project-request">
          {errorMessage && <ErrorMessage text={errorMessage} />}

          <h2>Demandez un renseignement</h2>

          <p>
            Découvrez la puissance de Chantier Privé, demandez{' '}
            <span className="orange">un renseignement</span>. Il existe certainement des chantiers à
            proximité de votre société. Pour le découvrir, indiquez vos prénom et nom. Nos
            conseillés vous rappellent dans les meilleurs délais.
          </p>

          <form onSubmit={handleSendInformationRequest}>
            <div>
              <input
                type="text"
                name="first_name"
                placeholder="Prénom"
                disabled={disabled}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
              <input
                type="text"
                name="last_name"
                placeholder="Nom"
                disabled={disabled}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>

            <button
              type="submit"
              className="btn btn-secondary"
              disabled={
                disabled || !firstName || !lastName || !firstName.trim() || !lastName.trim()
              }
            >
              Demander un renseignement
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProjectPublicRequest;
