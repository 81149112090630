import React from 'react';
import Modal from './index';
import { Link } from 'react-router-dom';

/**
 * This react component is used to prevent account deletion.
 * @param props
 */
const PreventAccountDeleteModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
}> = ({ hidden, closeModal }) => {
  return (
    <Modal hidden={hidden} maxWidth={900} closeModal={closeModal}>
      <div className="modal-body">
        <img src="/Icons/warning-red.svg" alt="Icône danger" />

        <p className="mt-4">
          Vous êtes sur le point de supprimer définitivement votre compte Chantier Privé.
          <br />
          <br />
          <b>
            Vous pouvez choisir de résilier votre abonnement afin de conserver votre compte et vos
            données.
          </b>
          <br />
          <br />
          Êtes-vous sûre de vouloir supprimer votre compte ?
        </p>

        <div className="d-flex justify-content-center">
          <Link
            onClick={() => (document.body.style.overflow = 'auto')}
            to="/settings/account/delete"
            className="btn btn-outline-secondary mx-1 mt-2 max-btn"
          >
            Supprimer mon compte
          </Link>

          <Link
            onClick={() => (document.body.style.overflow = 'auto')}
            to="/settings/subscription/cancel"
            className="btn btn-secondary mx-1 mt-2 max-btn"
          >
            Résilier mon abonnement
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default PreventAccountDeleteModal;
