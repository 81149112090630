import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import TitleIconed from '../../common/navigation/TitleIconed';
import { useHistory } from 'react-router';
import { UserContext } from '../../common/providers/UserProvider';
import axios from 'axios';
import { useAnalytics } from '../../common/RudderStack';
import ErrorMessage from '../../common/errors/ErrorMessage';
import CircularLoader from '../../common/navigation/CircularLoader';
import { BACKEND_URL } from '../../config/environment';

type Reason = 'no_project' | 'no_result' | 'no_need' | 'enought_project' | 'too_expensive';

type NoNeed = 'not_ready' | 'project_too_large' | 'too_expensive';

type NoNeedFormDefinition = {
  not_ready: { text: string };
  project_too_large: { text: string };
  too_expensive: { text: string };
};

type ReasonFormDefinition = {
  no_project: { text: string };
  no_result: { text: string };
  enought_project: { text: string };
  no_need: { text: string };
  too_expensive: { text: string };
};

const CancelSubscription: React.FC<{ user: IUser }> = ({ user }) => {
  const { refreshUser } = useContext(UserContext);
  const [reason, setReason] = useState<Reason>(null);
  const [gainedContacts, setGainedContacts] = useState<boolean>(undefined);

  const history = useHistory();
  const analytics = useAnalytics();
  const [step, setStep] = useState(0);
  const [emotion, setEmotion] = useState(null);
  const [departements, setDepartements] = useState<string | null>('');
  const [trades, setTrades] = useState<string | null>('');
  const [typeConstructions, setTypeConstructions] = useState<string | null>('');

  const [isComingBack, setIsComingBack] = useState<boolean>(undefined);
  const [projectCount, setProjectCount] = useState<string>('');
  const [gainedDeals, setGainedDeals] = useState<boolean>(undefined);
  const [noNeed, setNoNeed] = useState<NoNeed>(null);

  const [loadingCancelSubscription, setLoadingCancelSubscription] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  /**
   * Send Feedback.
   */
  const sendFeedback = () => {
    setLoadingCancelSubscription(true);
    axios
      .post(`${BACKEND_URL}/api/unsubscribers/feedback`, {
        feedback: emotion,
      })
      .then(() => {
        setLoadingCancelSubscription(false);
        history.push('/settings/subscription');
      })
      .catch((err) => {
        const error = err?.response?.data?.errorMessage;
        setError(error ? error : 'Une erreur est survenue.');
      });
  };

  useEffect(() => {
    if (!user.is_stripe_subscriber || user.is_on_grace_period) {
      history.push('/settings/subscription');
    }
  }, []);

  const reasons: ReasonFormDefinition = {
    no_project: {
      text: "J'aurais aimé trouver plus de DCE correspondant à mon activité.",
    },
    no_result: {
      text: "J'aurais aimé obtenir un marché.",
    },
    enought_project: {
      text: "J'ai trouvé assez de DCE.",
    },
    no_need: {
      text: "Je ne souhaite plus faire de projet en Appel d'Offre.",
    },
    too_expensive: {
      text: 'L’abonnement chantier privé me coûte trop cher.',
    },
  };

  const noNeeds: NoNeedFormDefinition = {
    not_ready: {
      text: 'Je ne suis peut être pas prêt.',
    },
    project_too_large: {
      text: 'Les projets sont trop volumineux.',
    },
    too_expensive: {
      text: 'Cela me côute trop chère.',
    },
  };

  const handleReasonChange = (reason: Reason) => {
    setReason(reason);
  };

  const handleNoNeedChange = (noNeed: NoNeed) => {
    setNoNeed(noNeed);
  };

  const isDisabled = () => {
    return !reason;
  };

  const handleSubmit = () => {
    setLoadingCancelSubscription(true);
    analytics?.track('Button Clicked', {
      location: 'unsubscription form',
      text: 'Confirmer',
    });

    axios
      .post(`${BACKEND_URL}/api/unsubscribed-users`, {
        reason: reason,
        departements: departements,
        trades: trades,
        type_constructions: typeConstructions,
        project_count: projectCount,
        no_need: noNeed,
        gained_contacts: gainedContacts,
        is_coming_back: isComingBack,
        gained_deals: gainedDeals,
      })
      .then(() => {
        setLoadingCancelSubscription(false);
        refreshUser();
      })
      .finally(() => {
        setStep(5);
      });
  };

  return (
    <>
      <TitleIconed icon="noun_filter_activated" text="Résilier mon abonnement" />
      <div className="cancelation-cards-wrapper card mt-4">
        {step === 0 && (
          <>
            <div className="cancelation-cards-head">
              <h2 className="cancelation-card-title">Outch. On est triste de vous voir partir !</h2>
              <h3 className="cancelation-card-sub-title">
                Quelle raison vous pousse à résilier votre abonnement ?
              </h3>
              <p className="cancelation-card-info">
                Vos réponses sont importantes pour nous, elles nous aident à améliorer
                l&apos;expérience sur notre plateforme. Ceci ne vous prendra que quelques secondes.
              </p>
            </div>

            <div className="card-body">
              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="no_project"
                  id="no_project"
                  onChange={() => handleReasonChange('no_project')}
                />
                <label htmlFor="no_project">{reasons['no_project'].text}</label>
              </div>

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="no_result"
                  id="no_result"
                  onChange={() => handleReasonChange('no_result')}
                />
                <label htmlFor="no_result">{reasons['no_result'].text}</label>
              </div>

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="enought_project"
                  id="enought_project"
                  onChange={() => handleReasonChange('enought_project')}
                />
                <label htmlFor="enought_project">{reasons['enought_project'].text}</label>
              </div>

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="no_need"
                  id="no_need"
                  onChange={() => handleReasonChange('no_need')}
                />
                <label htmlFor="no_need">{reasons['no_need'].text}</label>
              </div>

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="too_expensive"
                  id="too_expensive"
                  onChange={() => handleReasonChange('too_expensive')}
                />
                <label htmlFor="too_expensive">{reasons['too_expensive'].text}</label>
              </div>

              <div className="cancelation-card-button">
                <div className="mr-2">
                  <Link to={`/settings/subscription`}>
                    <button
                      type="button"
                      id="button-cancel"
                      data-cy="button-cancel"
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        analytics?.track('Button Clicked', {
                          location: 'unsubscription form',
                          text: 'Annuler',
                        })
                      }
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="ml-2">
                  <button
                    disabled={isDisabled()}
                    onClick={() => setStep(1)}
                    id="button-continue"
                    data-cy="button-continue"
                    className="btn btn-secondary"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 1 && reason === 'no_project' && (
          <>
            <div className="card-body">
              <div className="cancelation-cards-head">
                <h2 className="cancelation-card-title">
                  Outch. On est triste de vous voir partir !
                </h2>
                <h3 className="cancelation-card-sub-title">
                  Quelle raison vous pousse à résilier votre abonnement ?
                </h3>
                <p className="cancelation-card-info">
                  Vos réponses sont importantes pour nous, elles nous aident à améliorer
                  l&apos;expérience sur notre plateforme. Ceci ne vous prendra que quelques
                  secondes.
                </p>
              </div>

              <div className="card-body">
                <div className="col-lg-12">
                  En savoir plus sur votre activité...
                  <input
                    type="text"
                    onChange={(e) => setDepartements(e.target.value)}
                    value={departements || ''}
                    className="form-control auth-input mt-2"
                    placeholder="Insérez le(s) département(s)"
                  />
                </div>
                <div className="col-lg-12 mt-4">
                  Les lots qui vous intéressent
                  <input
                    type="text"
                    onChange={(e) => setTrades(e.target.value)}
                    value={trades || ''}
                    className="form-control auth-input mt-2"
                    placeholder="Insérez le(s) lot(s)"
                  />
                </div>
                <div className="col-lg-12 mt-4">
                  Les types de constructions qui vous intéressent
                  <input
                    type="text"
                    onChange={(e) => setTypeConstructions(e.target.value)}
                    value={typeConstructions || ''}
                    className="form-control auth-input mt-2"
                    placeholder="Insérez le(s) type(s)"
                  />
                </div>
              </div>

              <div className="cancelation-card-button">
                <div className="mr-2">
                  <Link to={`/settings/subscription`}>
                    <button
                      type="button"
                      id="button-cancel"
                      data-cy="button-cancel"
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        analytics?.track('Button Clicked', {
                          location: 'unsubscription form',
                          text: 'Annuler',
                        })
                      }
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="ml-2">
                  <button
                    disabled={!departements || !trades || !typeConstructions}
                    onClick={() => setStep(2)}
                    id="button-continue"
                    data-cy="button-continue"
                    className="btn btn-secondary"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 1 && reason === 'enought_project' && (
          <>
            <div className="cancelation-cards-head">
              <h2 className="cancelation-card-title">Outch. On est triste de vous voir partir !</h2>
              <h3 className="cancelation-card-sub-title">
                Avez-vous remporté un marché avec nous ?
              </h3>
              <p className="cancelation-card-info">
                Vos réponses sont importantes pour nous, elles nous aident à améliorer l’expérience
                sur notre plateforme. Ceci ne vous prendra que quelques secondes.
              </p>
            </div>

            <div className="card-body">
              <div className="reason-element">
                <input
                  name="enough_project"
                  type="radio"
                  id="enough_project_yes"
                  onChange={() => setGainedDeals(true)}
                />
                <label htmlFor="enough_project_yes">Oui</label>
              </div>

              <div className="reason-element">
                <input
                  name="enough_project"
                  type="radio"
                  id="enough_project_no"
                  onChange={() => setGainedDeals(false)}
                />
                <label htmlFor="enough_project_no">Non</label>
              </div>

              <div className="cancelation-card-button">
                <div className="mr-2">
                  <Link to={`/settings/subscription`}>
                    <button
                      type="button"
                      id="button-cancel"
                      data-cy="button-cancel"
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        analytics?.track('Button Clicked', {
                          location: 'unsubscription form',
                          text: 'Annuler',
                        })
                      }
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="ml-2">
                  <button
                    disabled={typeof gainedDeals === 'undefined'}
                    onClick={() => setStep(3)}
                    id="button-continue"
                    data-cy="button-continue"
                    className="btn btn-secondary"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 1 && reason === 'no_need' && (
          <>
            <div className="cancelation-cards-head">
              <h2 className="cancelation-card-title">Outch. On est triste de vous voir partir !</h2>
              <h3 className="cancelation-card-sub-title">
                Pour quelles raisons vous ne souhaitez plus faire de projet en appel d’offre ?
              </h3>
              <p className="cancelation-card-info">
                Vos réponses sont importantes pour nous, elles nous aident à améliorer l’expérience
                sur notre plateforme. Ceci ne vous prendra que quelques secondes.
              </p>
            </div>

            <div className="card-body">
              <div className="reason-element">
                <input
                  name="no_need"
                  type="radio"
                  id="no_need_1"
                  onChange={() => handleNoNeedChange('not_ready')}
                />
                <label htmlFor="no_need_1">{noNeeds['not_ready'].text}</label>
              </div>

              <div className="reason-element">
                <input
                  name="no_need"
                  type="radio"
                  id="no_need_2"
                  onChange={() => handleNoNeedChange('project_too_large')}
                />
                <label htmlFor="no_need_2">{noNeeds['project_too_large'].text}</label>
              </div>

              <div className="reason-element">
                <input
                  name="no_need"
                  type="radio"
                  id="no_need_3"
                  onChange={() => handleNoNeedChange('too_expensive')}
                />
                <label htmlFor="no_need_3">{noNeeds['too_expensive'].text}</label>
              </div>

              <div className="cancelation-card-button">
                <div className="mr-2">
                  <Link to={`/settings/subscription`}>
                    <button
                      type="button"
                      id="button-cancel"
                      data-cy="button-cancel"
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        analytics?.track('Button Clicked', {
                          location: 'unsubscription form',
                          text: 'Annuler',
                        })
                      }
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="ml-2">
                  <button
                    disabled={!noNeed}
                    onClick={() => setStep(3)}
                    id="button-continue"
                    data-cy="button-continue"
                    className="btn btn-secondary"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {((step === 2 && reason === 'no_project') || (step === 1 && reason === 'no_result')) && (
          <>
            <div className="cancelation-cards-head">
              <h2 className="cancelation-card-title">Outch. On est triste de vous voir partir !</h2>
              <h3 className="cancelation-card-sub-title">Combien de DCE avez vous chiffré ?</h3>
              <p className="cancelation-card-info">
                Vos réponses sont importantes pour nous, elles nous aident à améliorer
                l&apos;expérience sur notre plateforme. Ceci ne vous prendra que quelques secondes.
              </p>
            </div>

            <div className="card-body">
              <div className="reason-element">
                <input
                  name="project_count"
                  type="radio"
                  id="project_count_1"
                  onChange={() => setProjectCount('0-5')}
                />
                <label htmlFor="project_count_1">0-5</label>
              </div>

              <div className="reason-element">
                <input
                  name="project_count"
                  type="radio"
                  id="project_count_2"
                  onChange={() => setProjectCount('5-10')}
                />
                <label htmlFor="project_count_2">5-10</label>
              </div>

              <div className="reason-element">
                <input
                  name="project_count"
                  type="radio"
                  id="project_count_3"
                  onChange={() => setProjectCount('10-20')}
                />
                <label htmlFor="project_count_3">10-20</label>
              </div>

              <div className="reason-element">
                <input
                  name="project_count"
                  type="radio"
                  id="project_count_4"
                  onChange={() => setProjectCount('Plus que 20')}
                />
                <label htmlFor="project_count_4">Plus que 20</label>
              </div>

              <div className="cancelation-card-button">
                <div className="mr-2">
                  <Link to={`/settings/subscription`}>
                    <button
                      type="button"
                      id="button-cancel"
                      data-cy="button-cancel"
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        analytics?.track('Button Clicked', {
                          location: 'unsubscription form',
                          text: 'Annuler',
                        })
                      }
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="ml-2">
                  <button
                    disabled={!projectCount}
                    onClick={() => setStep(3)}
                    id="button-continue"
                    data-cy="button-continue"
                    className="btn btn-secondary"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {((step === 3 && reason !== 'no_result') ||
          (step === 4 && reason === 'no_result') ||
          (step === 1 && reason === 'too_expensive')) && (
          <>
            {loadingCancelSubscription && <CircularLoader />}
            {!loadingCancelSubscription && (
              <div>
                <div className="cancelation-cards-head">
                  <h2 className="cancelation-card-title">
                    Outch. On est triste de vous voir partir !
                  </h2>
                  <h3 className="cancelation-card-sub-title">
                    Envisagez vous de revenir sur Chantier Privé dans le futur ?
                  </h3>
                  <p className="cancelation-card-info">
                    Vos réponses sont importantes pour nous, elles nous aident à améliorer
                    l&apos;expérience sur notre plateforme. Ceci ne vous prendra que quelques
                    secondes.
                  </p>
                </div>

                <div className="card-body">
                  <div className="reason-element">
                    <input
                      name="is_coming_back"
                      type="radio"
                      id="is_coming_back_yes"
                      value={reason || ''}
                      onChange={() => setIsComingBack(true)}
                    />
                    <label htmlFor="is_coming_back_yes">Oui</label>
                  </div>

                  <div className="reason-element">
                    <input
                      name="is_coming_back"
                      type="radio"
                      id="is_coming_back_no"
                      value={reason || ''}
                      onChange={() => setIsComingBack(false)}
                    />
                    <label htmlFor="is_coming_back_no">Non</label>
                  </div>

                  <div className="cancelation-card-button">
                    <div className="mr-2">
                      <Link to={`/settings/subscription`}>
                        <button
                          type="button"
                          id="button-cancel"
                          data-cy="button-cancel"
                          className="btn btn-outline-secondary"
                          onClick={() =>
                            analytics?.track('Button Clicked', {
                              location: 'unsubscription form',
                              text: 'Annuler',
                            })
                          }
                        >
                          Annuler
                        </button>
                      </Link>
                    </div>
                    <div className="ml-2">
                      <button
                        disabled={typeof isComingBack === 'undefined'}
                        onClick={() => handleSubmit()}
                        id="button-continue"
                        data-cy="button-continue"
                        className="btn btn-secondary"
                      >
                        Confirmer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {step === 3 && reason === 'no_result' && (
          <>
            <div className="cancelation-cards-head">
              <h2 className="cancelation-card-title">Outch. On est triste de vous voir partir !</h2>
              <h3 className="cancelation-card-sub-title">
                Avez-vous pu vous créer des contacts chez nos partenaire MOA/MOE ?
              </h3>
              <p className="cancelation-card-info">
                Vos réponses sont importantes pour nous, elles nous aident à améliorer l’expérience
                sur notre plateforme. Ceci ne vous prendra que quelques secondes.
              </p>
            </div>

            <div className="card-body">
              <div className="reason-element">
                <input
                  name="gained_contacts"
                  type="radio"
                  id="gained_contacts_yes"
                  onChange={() => setGainedContacts(true)}
                />
                <label htmlFor="gained_contacts_yes">Oui</label>
              </div>

              <div className="reason-element">
                <input
                  name="gained_contacts"
                  type="radio"
                  id="gained_contacts_no"
                  onChange={() => setGainedContacts(false)}
                />
                <label htmlFor="gained_contacts_no">Non</label>
              </div>

              <div className="cancelation-card-button">
                <div className="mr-2">
                  <Link to={`/settings/subscription`}>
                    <button
                      type="button"
                      id="button-cancel"
                      data-cy="button-cancel"
                      className="btn btn-outline-secondary"
                      onClick={() =>
                        analytics?.track('Button Clicked', {
                          location: 'unsubscription form',
                          text: 'Annuler',
                        })
                      }
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="ml-2">
                  <button
                    disabled={typeof gainedContacts === 'undefined'}
                    onClick={() => setStep(4)}
                    id="button-continue"
                    data-cy="button-continue"
                    className="btn btn-secondary"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 5 && (
          <>
            <div className="cancelation-cards-head">
              <h2 className="cancelation-card-title">Outch. On est triste de vous voir partir !</h2>
              <h3 className="cancelation-card-sub-title">Une dernière étape</h3>
              <p className="cancelation-card-info">
                Nous souhaitons vous offrir la possibilité de consulter notre E-Book et de regarder
                notre Webinar pour vous donner quelques derniers conseils sur comment gérer une
                réponse à un appel d’offre.
              </p>
            </div>

            <div className="card-body">
              <div className="text-center mt-4">
                <button
                  onClick={() => {
                    setStep(6);
                    window.open('https://www.youtube.com/watch?v=3cD-h2nTEf4', '_blank');
                  }}
                  type="button"
                  className="btn btn-primary"
                  style={{ maxWidth: 400, marginBottom: 30, marginRight: 25 }}
                >
                  REGARDER NOTRE WEBINAR
                </button>
                <button
                  onClick={() => {
                    setStep(6);
                    window.open(
                      'https://chantierprive-production-data-bucket.s3.eu-west-3.amazonaws.com/documents/ebook.pdf',
                      '_blank'
                    );
                  }}
                  type="button"
                  className="btn btn-secondary"
                  style={{ maxWidth: 400, marginBottom: 30 }}
                >
                  TÉLÉCHARGER NOTRE E-BOOK
                </button>
              </div>
              <div className="my-4">
                {' '}
                <Link to="#" onClick={() => setStep(6)} className="skip-step">
                  {' '}
                  Passer cette étape{' '}
                </Link>{' '}
              </div>
            </div>
          </>
        )}

        {step === 6 && (
          <>
            {error && <ErrorMessage text={error} />}
            {loadingCancelSubscription && <CircularLoader />}
            {!loadingCancelSubscription && (
              <div>
                <div className="cancelation-cards-head">
                  <h2 className="cancelation-card-title">
                    Votre demande de résiliation d’abonnement a bien été prise en compte.
                  </h2>
                  <h3 className="cancelation-card-sub-title">
                    NOTEZ VOTRE EXPERIENCE CHANTIER PRIVE{' '}
                  </h3>
                  <p className="cancelation-card-info">
                    Vos réponses sont importantes pour nous, elles nous aident à améliorer
                    l&apos;expérience sur notre plateforme. Ceci ne vous prendra que quelques
                    secondes.
                  </p>
                </div>

                <div className="card-body" style={{ textAlign: 'center' }}>
                  <div className="emotions">
                    <span
                      className={`emotion ${emotion === 0 && 'active'}`}
                      onClick={() => setEmotion(0)}
                    >
                      <img src="/Icons/sad.png" alt="sad" />
                    </span>

                    <span
                      className={`emotion ${emotion === 1 && 'active'}`}
                      onClick={() => setEmotion(1)}
                    >
                      <img src="/Icons/subtract.png" alt="subtract" />
                    </span>

                    <span
                      className={`emotion ${emotion === 2 && 'active'}`}
                      onClick={() => setEmotion(2)}
                    >
                      <img src="/Icons/happy.png" alt="happy" />
                    </span>
                  </div>
                  <p className="cancelation-card-info">
                    Votre accès à Chantier Privé sera bloqué à la fin de votre période en cours.
                    Vous pouvez à tout moment réactiver votre abonnement via votre espace Des
                    questions ? N&apos;hésitez pas à nous solliciter via le chat en bas à droite de
                    votre écran !
                  </p>

                  <button
                    id="button-disconnect"
                    data-cy="button-disconnect"
                    type="button"
                    className="btn btn-secondary mt-2"
                    style={{ maxWidth: '200px' }}
                    onClick={() => sendFeedback()}
                    disabled={emotion == null}
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CancelSubscription;
