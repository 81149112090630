import React from 'react';
import { Link } from 'react-router-dom';

const typeNames = [
  'warning',
  'danger',
  'success',
  'primary_information',
  'secondary_information',
  'third_information',
] as const;

type Type = typeof typeNames[number];

const types: { [key in Type]: { icon: string; background: string } } = {
  warning: {
    icon: 'warning',
    background: 'yellow',
  },
  danger: {
    icon: 'danger',
    background: 'red',
  },
  success: {
    icon: 'success',
    background: 'green',
  },
  primary_information: {
    icon: 'information',
    background: 'blue',
  },
  secondary_information: {
    icon: 'information',
    background: 'orange',
  },
  third_information: {
    icon: '',
    background: 'green',
  },
};

const TopMessage: React.FC<{
  message: string;
  type: Type;
  link?: string;
  linkText?: string;
  close?: () => void;
}> = ({ message, type, link, linkText, close }) => {
  return (
    <div className="top-message">
      <div className={types[type].background + ' top-message-wrapper'}>
        <span className={types[type].icon + ' top-message-icon'}></span>
        {link ? (
          <Link to={link} className="top-message-text">
            {message}
          </Link>
        ) : (
          <span className="top-message-text">{message}</span>
        )}

        {link && linkText && (
          <Link className="top-message-link" to={link}>
            {linkText}
          </Link>
        )}
        {close && (
          <img alt="Fermer" src="/Icons/cross.svg" className="message-close" onClick={close} />
        )}
      </div>
    </div>
  );
};

export default TopMessage;
