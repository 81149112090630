import React, { createContext, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { useAnalytics } from '../RudderStack';
import { BACKEND_URL } from '../../config/environment';

const UserContext = createContext<{
  user: IUser | null;
  fetched: boolean;
  login: (user: IUser) => void;
  loginFailed: () => void;
  updateUser: (user: IUser) => void;
  refreshUser: () => Promise<AxiosResponse<{ user: IUser }>>;
  logout: () => void;
}>({
  user: null,
  fetched: false,
  login: null,
  loginFailed: null,
  logout: null,
  updateUser: null,
  refreshUser: null,
});

const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUser>(null);
  const [fetched, setFetched] = useState<boolean>(false);
  const analytics = useAnalytics();

  const login = (user: IUser) => {
    setUser(user);
    setFetched(true);
    Sentry.setUser({ email: user.mail });

    analytics?.identify(user.id, {
      name: user.name,
      email: user.mail,
    });
    if (user.bearer) {
      localStorage.setItem('authToken', user.bearer);
    }
  };

  const loginFailed = () => {
    setUser(null);
    setFetched(true);
  };

  const logout = () => {
    setUser(null);
    setFetched(true);

    analytics?.track('User Logged Out');
  };

  const updateUser = (user: IUser) => {
    setUser(user);
  };

  const refreshUser = async (): Promise<AxiosResponse<{ user: IUser }>> => {
    return axios
      .get(`${BACKEND_URL}/api/user`)
      .then(({ data }) => {
        if (data.user && data.user.mail) {
          updateUser(data.user);
          return data.user;
        } else {
          loginFailed();
        }
      })
      .catch(() => {
        loginFailed();
      });
  };

  return (
    <UserContext.Provider
      value={{ user, login, loginFailed, logout, fetched, updateUser, refreshUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
