import React, { forwardRef, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import PhoneInput from '../../../common/navigation/PhoneInput';
import { BACKEND_URL } from '../../../config/environment';

const UserInformationEdit = forwardRef<
  { saveUser: () => Promise<IUser> },
  { user: IUser; onChange: (isDirty: boolean) => void }
>(function UserInformationEditComponent(props, ref) {
  const [firstName, setFirstName] = useState<string>(props.user.first_name);
  const [lastName, setLastName] = useState<string>(props.user.last_name);
  const [mail, setMail] = useState<string>(props.user.mail);
  const [phone, setPhone] = useState<string>(props.user.phone);
  const [phoneCode, setPhoneCode] = useState<string>(props.user.phone_country_code);
  const [user] = useState<IUser>(props.user);

  const onChange = props.onChange;

  useImperativeHandle(ref, () => ({
    async saveUser(): Promise<IUser> {
      const data = await axios.put<IPaginateUser['data']>(`${BACKEND_URL}/api/user`, {
        first_name: firstName,
        last_name: lastName,
        mail: mail,
        phone: phone,
        phone_country_code: phoneCode,
      });
      return data.data.user;
    },
  }));

  const handleOnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    dispatchOnFieldChange(name, value);
  };

  const handleOnCodeClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;

    dispatchOnFieldChange('phone_country_code', value);
  };

  /**
   * Handles on input events for the user informations fields
   * Updates fields state & dispatches onChange event informing
   * if the fields are similar to the original data
   *
   * @param string input name
   * @param string input value
   */
  const dispatchOnFieldChange = (name: string, value: string) => {
    const original = new Map(Object.entries(props.user));

    switch (name) {
      case 'first_name':
        setFirstName(value);
        break;
      case 'last_name':
        setLastName(value);
        break;
      case 'email':
        setMail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'phone_country_code':
        setPhoneCode(value);
        break;
      default:
        break;
    }

    onChange(original.get(name) !== value);
  };

  return (
    <div className="col-sm mb-3">
      <h2 className="title-card-option">
        <span>Vous</span>
        {(user && user.profile === 3 && <span className="manager-title">Manager</span>) ||
          (user && user.managers?.length > 0 && user.profile === 0 && (
            <span className="account-title">Géré par : {user.managers[0].mail}</span>
          ))}
      </h2>
      <br />
      <input
        className="form-control auth-input mt-2"
        placeholder="Prénom"
        name="first_name"
        data-cy="first_name"
        onChange={handleOnChangeEvent}
        defaultValue={firstName}
      />
      <input
        className="form-control auth-input mt-2"
        placeholder="Nom"
        name="last_name"
        data-cy="last_name"
        onChange={handleOnChangeEvent}
        defaultValue={lastName}
      />
      <input
        className="form-control auth-input mt-2"
        placeholder="Email"
        name="email"
        data-cy="email"
        disabled={true}
        onChange={handleOnChangeEvent}
        defaultValue={mail}
      />
      <PhoneInput
        onPhoneChange={handleOnChangeEvent}
        onCodeClick={handleOnCodeClickEvent}
        value={{ phone, code: phoneCode }}
      />
    </div>
  );
});

export default UserInformationEdit;
