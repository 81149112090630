import axios from 'axios';
import React, { useState } from 'react';
import Button from '../../../common/navigation/Button';
import { useStripe } from '@stripe/react-stripe-js';
import { useAnalytics } from '../../../common/RudderStack';
import { BACKEND_URL } from '../../../config/environment';

const CardAnnually: React.FC<{
  promotionPrice?: number;
  promotionDiscount?: number;
  promotionCode?: string;
  promotionDuration?: 'once' | 'forever' | 'repeating';
  prices: Array<IStripePrice>;
}> = ({ promotionPrice, promotionDiscount = 0, promotionCode, promotionDuration, prices }) => {
  const basePrice = 950;
  const baseMarketingPrice = 1188;
  const basePromotion = 238;
  const price = promotionPrice ?? basePrice;
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const analytics = useAnalytics();

  const getCheckoutUrl = () => {
    if (stripe) {
      setLoading(true);
      axios
        .post(`${BACKEND_URL}/api/stripe/checkout-session`, {
          price_name: 'annually',
          promotion_code: promotionCode,
        })
        .then(({ data }) => {
          const stripePrice: IStripePrice = data.stripe_price;

          analytics?.track('Checkout Started', {
            revenue: stripePrice.price_excl_tax,
            currency: 'EUR',
            product_id: stripePrice.id,
            name: stripePrice.display_name,
            price: stripePrice.price_excl_tax,
            quantity: 1,
          });

          stripe.redirectToCheckout({ sessionId: data.checkout_session_id }).then(() => {
            setLoading(false);
          });
        });
    }
  };

  return (
    <>
      <div className="card price-request-card-subscription-annually price-request-card-plan card-plan-annually subscription-plan">
        <div className="price-request-header-title">
          <span></span>
        </div>
        <div className="card-title pb-3">
          <h3>Creation de compte</h3>
        </div>
        <h1 className="price">
          +{price}
          <sup className="large">€HT</sup>
        </h1>

        <div className="card-title">
          <h3 className="mb-3">
            <strong>Formule intégrale</strong>
          </h3>
        </div>
        <p className="subtitle-request-price">Découvrez tous les projets :</p>
        <ul className="check-list-price-request">
          <li className="icon-annually">Par départements</li>
          <li className="icon-annually">Par lots</li>
          <li className="icon-annually">Par secteur d&apos;activité</li>
          <li className="icon-annually">Avec accès aux coordonnées du maître d&apos;ouvrage</li>
          <li className="icon-annually">Avec accès aux DCEs</li>
          <li className="icon-annually">Avec possibilité de réponse</li>
          <li className="icon-annually">Avec une formation personnalisée</li>
        </ul>
        <p className="subtitle-request-price">
          Votre facturation sans engagement, résiliez à tout moment.
        </p>
        <div className="button">
          <Button
            color="btn-primary"
            text={"S'abonner"}
            loading={loading}
            action={() => getCheckoutUrl()}
          />
        </div>
        <p />
      </div>
    </>
  );
};

export default CardAnnually;
