import React, { forwardRef, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../../config/environment';

const CompanyInformationEdit = forwardRef<
  { saveCompany: () => Promise<ICompany> },
  { company: ICompany; onChange: (isDirty: boolean) => void; isEditable: boolean }
>(function CompanyInformationEditComponent(props, ref) {
  const [siret, setSiret] = useState<number>(props.company.siret);
  const [address, setAddress] = useState<string>(props.company.address);
  const [zipCode, setZipCode] = useState<string>(props.company.zip_code);
  const [city, setCity] = useState<string>(props.company.city);

  useImperativeHandle(ref, () => ({
    async saveCompany(): Promise<ICompany> {
      const data = await axios.put(`${BACKEND_URL}/api/companies/${props.company.id}`, {
        siret: siret,
        address: address,
        zip_code: zipCode,
        city: city,
      });
      return data.data.company;
    },
  }));

  const handleOnChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    dispatchOnChangeEvent(name, value);
  };

  const dispatchOnChangeEvent = (name: string, value: string) => {
    const original = new Map(Object.entries(props.company));

    switch (name) {
      case 'address':
        setAddress(value);
        break;
      case 'zip_code':
        setZipCode(value);
        break;
      case 'city':
        setCity(value);
        break;
      default:
        break;
    }

    props.onChange(original.get(name) !== value);
  };

  return (
    <div className="col-sm">
      <h2 className="title-card-option">Votre entreprise</h2>
      <br />
      <input
        className="form-control auth-input"
        placeholder="Siret"
        onChange={(e) => setSiret(parseInt(e.target.value, 10))}
        disabled
        defaultValue={siret}
      />
      <input
        className="form-control auth-input mt-2"
        placeholder="Adresse"
        name="address"
        onChange={handleOnChangeEvent}
        defaultValue={address}
        disabled={!props.isEditable}
      />
      <input
        className="form-control auth-input mt-2"
        placeholder="Code postal"
        name="zip_code"
        onChange={handleOnChangeEvent}
        defaultValue={zipCode}
        disabled={!props.isEditable}
      />
      <input
        className="form-control auth-input mt-2"
        placeholder="Ville"
        name="city"
        onChange={handleOnChangeEvent}
        defaultValue={city}
        disabled={!props.isEditable}
      />
    </div>
  );
});

export default CompanyInformationEdit;
