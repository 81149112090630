import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import BoxMailUnverified from '../../common/navigation/BoxMailUnverified';
import CircularLoader from '../../common/navigation/CircularLoader';
import TitleIconed from '../../common/navigation/TitleIconed';
import BoxContactSupport from '../../common/navigation/BoxContactSupport';
import ProjectListElement from './ProjectListElement';
import ProjectPublicListElement from './ProjectPublicListElement';
import Pagination from '../../common/navigation/Pagination';
import BoxResearch from '../../common/navigation/BoxResearch';
import Toast from '../../common/navigation/Toast';
import ProjectListElementPromote from './ProjectListElementPromote';
import queryString from 'query-string';
import Phone from '../../common/navigation/Phone';
import ProjectPublicRequest from './ProjectPublicRequest';
import { TopMessageContext } from '../../common/providers/TopMessageProvider';
import UserMenu from '../../common/navigation/UserMenu';
import ProjectRequested from './ProjectRequested';
import UserUnsubscribed from './UserUnsubscribed';
import ErrorPayment from './ErrorPayment';
import { BACKEND_URL } from '../../config/environment';
import Banner from '../banner/Banner';

const constructionTypes = [
  'Logements',
  'Grandes surfaces',
  'Établissements de santé',
  'Tertiaire',
  'Autre',
];

const ProjectList: React.FC<{
  user: IUser;
}> = ({ user }) => {
  const { displayTopMessage, closeTopMessage } = useContext(TopMessageContext);
  const [userStateFilters, setUserStateFilters] = useState<Array<IState>>(user.preference.states);
  const [userConstructionTypeFilters, setUserConstructionTypeFilters] = useState<Array<string>>(
    user.preference.construction_types
  );
  const [userTradesCategoriesFilters, setUserTradesCategoriesFilters] = useState<Array<ITrades>>(
    user.preference.trade_category_references
  );
  const [projectPublicRequest, setProjectPublicRequest] = useState<IProjectPublicRequest>(null);
  const [projectPublicRequestFetched, setProjectPublicRequestFetched] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectRequests, setProjectRequests] = useState<IProjectRequest[]>([]);
  const [projectPublics, setProjectPublics] = useState<IProject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastPage, setLastPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hiddenToast, setHiddenToast] = useState<boolean>(true);
  const [toastMsg, setToastMsg] = useState<string>(null);
  const [subscription, setSubscription] = useState<ISubscription>(null);

  /**
   * Fetch a page of projects
   *
   * @param page Page number.
   * @param stateFilters State filters.
   * @param constructionTypeFilters Construction type filters.
   * @param tradesCategoriesFilters Trades Categories  filters.
   *
   */
  const fetchPage = (
    page: number,
    stateFilters: IState[],
    constructionTypeFilters: string[],
    tradesCategoriesFilters: ITrades[]
  ) => {
    setIsLoading(true);
    setProjectPublics([]);

    const url = getProjectQueryUrl(
      page,
      stateFilters,
      constructionTypeFilters,
      tradesCategoriesFilters
    );

    axios
      .get(`${BACKEND_URL}/api/project-public-requests`)
      .then(({ data }) => {
        setProjectPublicRequest(data.project_public_request);
      })
      .finally(() => {
        setProjectPublicRequestFetched(true);
      });

    axios
      .get<IPaginateProject>(url)
      .then(({ data }) => {
        setProjects(data.projects.data);
        setProjectRequests(data.project_requests);
        setLastPage(data.projects.last_page);
        setCurrentPage(data.projects.current_page);
        setProjectPublics(data.project_publics);
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * Get query url with filters
   *
   * @param page Page number.
   * @param stateFilters State filters.
   * @param constructionTypeFilters Construction type filters.
   * @param tradesCategoriesFilters Construction type filters.
   *
   */
  const getProjectQueryUrl = (
    page: number,
    stateFilters: IState[],
    constructionTypeFilters: string[],
    tradesCategoriesFilters: ITrades[]
  ): string => {
    const states = stateFilters.map((state) => state.number);
    const types = constructionTypeFilters.map((type) => constructionTypes.indexOf(type));
    const trades = tradesCategoriesFilters.map((trade_category) => trade_category.id);

    let url = `${BACKEND_URL}/api/projects?page=${page}`;
    if (states.length)
      url += `&${queryString.stringify({ states: states }, { arrayFormat: 'bracket' })}`;

    if (types.length)
      url += `&${queryString.stringify({ construction_types: types }, { arrayFormat: 'bracket' })}`;
    if (trades.length)
      url += `&${queryString.stringify({ trades_categories: trades }, { arrayFormat: 'bracket' })}`;
    return url;
  };

  const getAssociatedProjectRequest = (projectId: string): IProjectRequest => {
    return projectRequests.find((projectRequest) => projectRequest.project_id === projectId);
  };

  const getEnrichedProject = (projectId: string): IProject => {
    const projectRequest = getAssociatedProjectRequest(projectId);

    if (projectRequest) {
      return projectRequest.project;
    }

    return projects.find((project) => project.id === projectId);
  };

  const updateProject = (project: IProject) => {
    setProjects((list) => list.map((p) => (p.id === project.id ? project : p)));

    setProjectRequests((list) =>
      list.map((request) =>
        request.project_id === project.id
          ? {
              ...request,
              project,
            }
          : request
      )
    );
  };

  /**
   * ProjectListElement callback after a request was sent
   * of whether adding or removing project from favorites
   *
   * @param value Project is a favorite
   * @param projectId Project Id
   */
  const onFavoriteChange = (value: boolean, projectId: string): void => {
    updateProject({
      ...projects.find((p) => p.id === projectId),
      is_a_favorite: value,
    });

    setToastMsg(
      value
        ? 'Le projet a bien été ajouté aux favoris.'
        : 'Le projet a bien été retiré des favoris.'
    );
    setHiddenToast(false);
    setTimeout(() => {
      setHiddenToast(true);
    }, 3000);
  };

  const getSubscription = () => {
    return axios.get<{ subscription: ISubscription }>(`${BACKEND_URL}/api/subscriptions`).then(({ data }) => {
      setSubscription(data.subscription);
    });
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const loadMessageUnsubscribers = () => {
    switch (user.subscription_status) {
      case 'disputed':
      case 'past_due':
      case 'failure':
        return <ErrorPayment user={user} subscription={subscription} />;
      case 'canceled':
        return <UserUnsubscribed user={user} subscription={subscription} />;
      default:
        return <ProjectRequested user={user} />;
    }
  };
  return (
    <div id="tour__projects-list">
      <div className="d-flex">
        <div className="p-2 w-75 my-first-step">
          <TitleIconed icon="noun_complete_activated" text="Projets" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description">
        Retrouvez ici la liste de tous les projets répondants à vos critères de recherche
        personnalisés.
      </p>

      <Banner />

      {!user.is_mail_confirmed && <BoxMailUnverified user={user} />}

      {!isLoading && projects.length === 0 && <BoxContactSupport />}
      <BoxResearch
        preferences={user.preference}
        refreshProjects={(stateFilters, constructionTypeFilters, tradesCategoriesFilters) => {
          setUserStateFilters(stateFilters);
          setUserConstructionTypeFilters(constructionTypeFilters);
          setUserTradesCategoriesFilters(tradesCategoriesFilters);
          fetchPage(1, stateFilters, constructionTypeFilters, tradesCategoriesFilters);
        }}
      />
      {isLoading && <CircularLoader />}

      {!user.is_subscriber && !user.subscription_status && !user.information_asked_at && (
        <ProjectPublicRequest
          reload={() => {
            fetchPage(
              currentPage,
              userStateFilters,
              userConstructionTypeFilters,
              userTradesCategoriesFilters
            );
            displayTopMessage(
              'Votre demande a bien été envoyée ! Notre équipe vous recontacte dans les plus brefs délais.',
              'success'
            );
            setTimeout(function () {
              closeTopMessage();
            }, 5000);
          }}
          user={user}
          project={projectPublics ? projectPublics[0] : null}
          projectPublicRequest={projectPublicRequest ? true : false}
          projectRequest={
            projectPublics[0] ? getAssociatedProjectRequest(projectPublics[0].id) : null
          }
        />
      )}

      {!user.is_subscriber && user.subscription_status && loadMessageUnsubscribers()}

      {projects.length > 0 && (
        <>
          {!user.is_subscriber && projectPublics && projectPublics.length > 0 && (
            <div className="row" key="project-publics">
              {projectPublics.map(
                (publicProject, index) =>
                  // First element is ignored if user hasn't submitted an information request.
                  (user.information_asked_at || index != 0) && (
                    <ProjectPublicListElement
                      key={`public-project-${index}`}
                      project={publicProject}
                      projectPublicRequest={projectPublicRequest ? true : false}
                      projectRequest={getAssociatedProjectRequest(publicProject.id)}
                      user={user}
                      reload={() =>
                        fetchPage(
                          currentPage,
                          userStateFilters,
                          userConstructionTypeFilters,
                          userTradesCategoriesFilters
                        )
                      }
                      marginTop={4}
                    />
                  )
              )}
            </div>
          )}

          <div className="row">
            {!isLoading &&
              projects.map((project, index) => (
                <ProjectListElement
                  key={`project-${index}`}
                  projectPublicRequest={!!projectPublicRequest}
                  project={getEnrichedProject(project.id)}
                  projectRequest={getAssociatedProjectRequest(project.id)}
                  user={user}
                  projectPublics={projectPublics.length}
                  reload={() =>
                    fetchPage(
                      currentPage,
                      userStateFilters,
                      userConstructionTypeFilters,
                      userTradesCategoriesFilters
                    )
                  }
                  onFavoriteChange={(value: boolean) => onFavoriteChange(value, project.id)}
                />
              ))}

            {!user.is_subscriber && projects.length < 9 && <ProjectListElementPromote />}
          </div>
          <Pagination
            lastPage={lastPage}
            currentPage={currentPage}
            toCall={(page) =>
              fetchPage(
                page,
                userStateFilters,
                userConstructionTypeFilters,
                userTradesCategoriesFilters
              )
            }
          />
        </>
      )}

      {projects.length === 0 && (
        <div className="alert alert-info mt-4" style={{ borderRadius: '20px' }}>
          Désolé, aucun projet n&apos;a été trouvé avec vos critères de recherche.
        </div>
      )}
      <Toast
        title="Chantier Privé"
        message={toastMsg}
        hidden={hiddenToast}
        onClick={() => setHiddenToast(true)}
        top={true}
        fixedPosition={true}
      />
    </div>
  );
};

export default ProjectList;
