import axios from 'axios';
import React, { useState } from 'react';
import Button from '../../../common/navigation/Button';
import { useStripe } from '@stripe/react-stripe-js';
import { useAnalytics } from '../../../common/RudderStack';
import { BACKEND_URL } from '../../../config/environment';

const CardQuarterly: React.FC<{
  promotionPrice?: number;
  promotionDiscount?: number;
  promotionCode?: string;
  promotionDuration?: 'once' | 'forever' | 'repeating';
  prices: Array<IStripePrice>;
}> = ({ promotionPrice, promotionDiscount = 0, promotionCode, promotionDuration, prices }) => {
  const basePrice = 297;
  const price = promotionPrice ?? basePrice;
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const analytics = useAnalytics();

  const getCheckoutUrl = () => {
    if (stripe) {
      setLoading(true);
      axios
        .post(`${BACKEND_URL}/api/stripe/checkout-session`, {
          price_name: 'quarterly',
          promotion_code: promotionCode,
        })
        .then(({ data }) => {
          const stripePrice: IStripePrice = data.stripe_price;

          analytics?.track('Checkout Started', {
            revenue: stripePrice.price_excl_tax,
            currency: 'EUR',
            product_id: stripePrice.id,
            name: stripePrice.display_name,
            price: stripePrice.price_excl_tax,
            quantity: 1,
          });

          stripe.redirectToCheckout({ sessionId: data.checkout_session_id }).then(() => {
            setLoading(false);
          });
        });
    }
  };

  return (
    <div className="card price-request-card-subscription-quarterly price-request-card-plan card-plan-quarterly subscription-plan mb-3">
      <div className="price-request-header-title">
        <span></span>
      </div>
      <div className="card-title pb-3">
        <h3>Creation de compte</h3>
      </div>
      <h1 className="price">
        +{price}
        <sup className="large">€HT</sup>
      </h1>
      {promotionDiscount > 0 && (
        <h4 className="under-price">
          Au lieu de <b className="line-blue">{basePrice}€</b>
          <sup className="small-blue">HT</sup>
        </h4>
      )}

      <div className="card-title">
        <h3 className="mb-3">
          <strong>Avec abonnement trimestriel</strong>
        </h3>
      </div>
      <p className="subtitle-request-price">Découvrez tous les projets :</p>
      <ul className="check-list-price-request">
        <li className="icon-quarterly">Par départements</li>
        <li className="icon-quarterly">Par lots</li>
        <li className="icon-quarterly">Par secteur d'activité</li>
        <li className="icon-quarterly">Avec accès aux coordonnées du maître d'ouvrage</li>
        <li className="icon-quarterly">Avec accès aux DCEs</li>
        <li className="icon-quarterly">Avec possibilité de réponse</li>
        <li className="cancel-icon-quarterly">Sans formation personnalisée</li>
      </ul>
      <p className="subtitle-request-price">
        Votre facturation chaque trimestre, sans engagement, résiliez à tout moment.
      </p>
      <div className="button">
        <Button
          color="btn-secondary"
          text={"S'abonner"}
          loading={loading}
          action={() => getCheckoutUrl()}
        />
      </div>
      <p />
    </div>
  );
};

export default CardQuarterly;
