import departments from '../../../components/settings/preferences/departments.json';

const getBorderedStateByNumber = (number: string): IBorderedState =>
  departments.filter((dep) => dep.number === number)[0];

const toState = (state: IBorderedState): IState => ({
  number: state.number,
  name: state.name,
});

const getStateByNumber = (number: string): IState => toState(getBorderedStateByNumber(number));

/**
 * Get the initial States with each respective neighbors
 *
 * @param states Initial states
 * @returns states
 */
export const extendStates = (states: Array<IState>): Array<IState> => {
  const numbers = new Set<string>();

  states.forEach((state) => {
    numbers.add(state.number);

    getBorderedStateByNumber(state.number).neighbors.map((number) => numbers.add(number));
  });

  return Array.from(numbers).map((n) => getStateByNumber(n));
};

/**
 * Get all States
 *
 * @returns states
 */
const getStates = (): Array<IState> => departments.map(toState);

export default getStates;
