import React, { useState, useContext } from 'react';
import axios from 'axios';
import Modal from './index';
import { ProgressBar } from 'react-bootstrap';
import Button from '../navigation/Button';
import { Link } from 'react-router-dom';
import UpdateCardMonthly from '../../components/subscriptions/prices/UpdateCardMonthly';
import UpdateCardQuarterly from '../../components/subscriptions/prices/UpdateCardQuarterly';
import UpdateCardAnnually from '../../components/subscriptions/prices/UpdateCardAnnually';
import { SubscriptionContext } from '../../common/providers/SubscriptionProvider';
import { BACKEND_URL } from '../../config/environment';

/**
 * This react component is used to swap subscription to annual.
 * @param props
 */
const SwapSubscriptionModal: React.FC<{
  subscription: ISubscription;
  hidden: boolean;
  priceName: string;
  closeModal: () => void;
  refresh: () => Promise<void>;
}> = ({ subscription, hidden, priceName, closeModal, refresh }) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressBar, setProgressBar] = useState<number>(50);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const { refreshSubscription } = useContext(SubscriptionContext);

  const changeSubscription = () => {
    setLoading(true);
    axios
      .post(`${BACKEND_URL}/api/stripe/change-subscription`, { price_name: priceName })
      .then(() => {
        refreshSubscription();
        setStep(2);
        setHasErrors(false);
      })
      .catch(() => {
        setLoading(false);
        setHasErrors(true);
      });
  };

  const handleChange = () => {
    setStep(1);
    setProgressBar(100);
  };

  const cancelEditSubscription = () => {
    setStep(0);
    setProgressBar(50);
    closeModal();
    setHasErrors(false);
  };

  const handleCloseModal = () => {
    refresh().then(() => {
      closeModal();
    });
  };

  return (
    <Modal hidden={hidden} maxWidth={900} closeModal={closeModal}>
      <div className="modal-body">
        {(step === 0 || step === 1) && (
          <div>
            <h1>MODIFIER VOTRE ABONNEMENT</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb" style={{ backgroundColor: 'inherit' }}>
                <li className="breadcrumb-item" aria-current="page">
                  <a onClick={() => setStep(0)} className="text-dark" style={{ cursor: 'pointer' }}>
                    <b>Sélectionnez l’abonnement</b>
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <span className="text-dark">Confirmez</span>
                </li>
              </ol>
            </nav>
            <div className="progressBar" style={{ paddingLeft: 15, paddingRight: 15 }}>
              <ProgressBar now={progressBar} style={{ height: 9 }} />
            </div>
            <br />
            <br />
          </div>
        )}

        {step === 0 && (
          <>
            <div className="plans plan-modal mb-3">
              <>
                {subscription.stripe_price.name === 'monthly' && (
                  <UpdateCardMonthly
                    displayAdvantages={true}
                    displayButton={false}
                    price={99}
                    handleChange={handleChange}
                    title={'ABONNEMENT ACTUEL'}
                  />
                )}
                {subscription.stripe_price.name === 'quarterly' && (
                  <UpdateCardQuarterly
                    displayAdvantages={true}
                    displayButton={false}
                    price={297}
                    handleChange={handleChange}
                    title={'ABONNEMENT ACTUEL'}
                  />
                )}

                <UpdateCardAnnually
                  displayAdvantages={true}
                  displayButton={true}
                  price={950}
                  handleChange={handleChange}
                  title={'FUTUR ABONNEMENT'}
                />
              </>
            </div>
            <div className="my-4">
              <Link
                to="/settings/subscription/cancel"
                id="unsubscribe"
                data-cy="unsubscribe"
                className="link-unsubscribe"
              >
                Résilier votre abonnement
              </Link>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div className="plans plan-modal mb-3">
              <>
                {subscription.stripe_price.name === 'monthly' && (
                  <UpdateCardMonthly
                    displayAdvantages={false}
                    displayButton={false}
                    price={99}
                    handleChange={handleChange}
                    title={'ABONNEMENT ACTUEL'}
                  />
                )}
                {subscription.stripe_price.name === 'quarterly' && (
                  <UpdateCardQuarterly
                    displayAdvantages={false}
                    displayButton={false}
                    price={297}
                    handleChange={handleChange}
                    title={'ABONNEMENT ACTUEL'}
                  />
                )}
                <img
                  src="/Icons/mathematical-sign.png"
                  className="sign"
                  style={{ width: '30px', height: '30px' }}
                />
                <UpdateCardAnnually
                  displayAdvantages={false}
                  displayButton={false}
                  price={950}
                  handleChange={handleChange}
                  title={'FUTUR ABONNEMENT'}
                />
              </>
            </div>
            <div className="bottom-modal-content">
              {!hasErrors && (
                <p className="mb-5">
                  Vous êtes sur le point de passer à l&apos;abonnement annuel. Merci de confirmer
                  votre choix.
                </p>
              )}
              {hasErrors && (
                <p className="mb-5 text-danger">
                  Une erreur est survenue. Veuillez sélectionner un autre mode de paiement ou
                  réessayer utlérieurement.
                </p>
              )}
              <div className="bottom-button-modal">
                <div className="card-body button-body col-sm-5">
                  <Link to="#" onClick={cancelEditSubscription} className="link-cancel">
                    ANNULER
                  </Link>
                </div>

                <div className="card-body button-body col-sm-5">
                  <Button
                    color="btn subscription btn-primary"
                    text={'CONFIRMER'}
                    loading={loading}
                    action={() => changeSubscription()}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <div>
            <h1>CHANGEMENT EFFECTUÉ !</h1>
            <p className="mt-2">
              Le changement d&apos;abonnement a été effectué avec succès.
              <br />
              <b>
                Les changements peuvent prendre quelques minutes avant d&apos;apparaître sur votre
                espace.
              </b>
            </p>

            <button onClick={() => handleCloseModal()} className="btn btn-secondary mt-2">
              Terminer
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SwapSubscriptionModal;
