import React from 'react';

import SideBar from './SideBar';
import $ from 'jquery';

const PurchaserWrapper: React.FC = ({ children }) => {
  const openBurger = () => {
    if ($('#top').css('display') !== 'none') {
      if ($('.sidebar').css('display') === 'none') {
        $('.content-app').hide();
        $('.sidebar').slideDown(500);
      } else {
        $('.sidebar').slideUp(500, () => {
          $('.content-app').show();
        });
      }
    }
  };

  return (
    <>
      <div id="top">
        <img src="/images/logo_saqara.png" alt="Logo chantier privé par Saqara" />
        <span className="menu-icon">
          <img src="/images/burger.svg" alt="Menu" onClick={() => openBurger()} />
        </span>
      </div>

      <div className="container-fluid container-app purchaser-container">
        <SideBar toCall={() => openBurger()} />
        <div className="content-app">
          <div style={{ flexGrow: 1 }}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default PurchaserWrapper;
