import React from 'react';
import Modal from './index';
import { useAnalytics } from '../../common/RudderStack';

/**
 * This react component is used to see the subscription confirmation modal.
 * This modal is used to confirm to the user that a subscription have been
 * made.
 * @param props
 * @constructor
 */
const SubscriptionConfirmationModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
  refresh: () => void;
}> = ({ hidden, closeModal, refresh }) => {
  const analytics = useAnalytics();

  const SubscriptionConfirmation = () => {
    analytics?.track('The subscription confirmation');
    refresh();
  };
  return (
    <Modal hidden={hidden} maxWidth={600} closeModal={() => closeModal()} hideCloseButton={true}>
      <div className="modal-body">
        <h1>VOUS ÊTES ABONNÉ(E) À CHANTIER PRIVÉ !</h1>
        <p className="mt-2">
          Vous pouvez désormais accéder à l’ensemble des projets !
          <br />
          Nous restons à votre disposition pour chacune de vos étapes.
          <br />
          <b>L&apos;activation de votre abonnement peut prendre quelques minutes.</b>
        </p>

        <button onClick={() => SubscriptionConfirmation()} className="btn btn-secondary mt-2">
          Commencer
        </button>
      </div>
    </Modal>
  );
};

export default SubscriptionConfirmationModal;
