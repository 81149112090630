import React from 'react';

import { IStep } from '../../interfaces/IStep';

export const tourPaths = ['/consultation_open#tour'];

export const version = 2;

export const skipLabel = '<p class="skip-text">Passer le tutoriel</p>';

export const steps: Array<IStep> = [
  {
    id: 'tour__intro',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Merci pour votre inscription</h1>
        <p>
          Bienvenue sur l’application <span>Chantier Privé</span> ! Accédez dès maintenant à des
          projets adaptés à vos besoins.
        </p>
        <p>
          Laissez-vous guider et découvrez les fonctionnalités phares de l’application{' '}
          <span>Chantier Privé</span>.
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'intro-step',
    nextLabel: 'Démarrer',
  },
  {
    id: 'tour__profile',
    position: 'right',
    element: '#tour__profile',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Gérez votre compte</h1>
        <p className="intro-step-content">
          Tout d’abord rendez-vous sur l’onglet «&#8239;Votre compte&#8239;» afin de remplir les
          champs importants.
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Suivant',
  },
  {
    id: 'tour__profile-edit',
    position: 'left',
    element: '#tour__profile-edit',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Complétez vos informations</h1>
        <p className="intro-step-content">
          Bienvenue sur votre espace personnel. Commencez par remplir les champs nom et prénom pour
          compléter votre profil et cliquez sur{' '}
          <span className="intro-step-content-highlight">«&#8239;Enregistrer&#8239;»</span> ou sur{' '}
          <span className="intro-step-content-highlight">«&#8239;Suivant&#8239;»</span> pour
          continuer.
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Suivant',
    jumpStepId: 'tour__filters',
    redirection: '/settings/profile',
  },
  {
    id: 'tour__filters',
    position: 'right',
    element: '#tour__filters',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Optimisez vos recherches</h1>
        <p className="intro-step-content">
          Renseignez vos critères de recherche et accédez à des programmes sélectionnés en fonction
          de vos besoins.
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Suivant',
  },
  {
    id: 'tour__filters-edit',
    position: 'left',
    element: '#tour__filters-edit',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Optimisez vos recherches</h1>
        <p className="intro-step-content">
          Sélectionnez vos critères favoris et cliquez sur le bouton{' '}
          <span className="intro-step-content-highlight"> « Enregistrer »</span> ou sur{' '}
          <span className="intro-step-content-highlight">«&#8239;Suivant&#8239;»</span> pour
          continuer.
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'custom-step',
    jumpStepId: 'tour__projects',
    nextLabel: 'Suivant',
    redirection: '/settings/preferences',
  },
  {
    id: 'tour__projects',
    position: 'right',
    element: '#tour__projects',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Trouvez des chantiers</h1>
        <p className="intro-step-content">
          Nous allons trier les meilleurs projets pour vous ! Allons faire un tour sur la page des
          projets.
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Suivant',
  },
  {
    id: 'tour__projects-list',
    position: 'left',
    element: '#tour__projects-list',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Trouvez vos projets</h1>
        <p className="intro-step-content">
          Vous retrouverez sur cette page des projets adaptés à vos besoins. Il vous suffit de
          cliquer sur un projet pour faire une demande d’accès au DCE.
        </p>
      </div>
    ),
    disableInteraction: true,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Suivant',
    redirection: '/consultation_open',
  },
  {
    id: 'tour__asked-projects',
    position: 'right',
    element: '#tour__asked-projects',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Vos demandes d&apos;accès</h1>
        <p className="intro-step-content">
          Retrouvez ici vos demandes d’accès aux DCE. Le donneur d&apos;ordre se charge de traiter
          vos demandes dans les meilleurs délais.
        </p>
      </div>
    ),
    disableInteraction: true,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Suivant',
  },
  {
    id: 'tour__subscriptions',
    position: 'right',
    element: '#tour__subscriptions',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Gérez votre abonnement</h1>
        <p className="intro-step-content">
          Vous pouvez à tout moment gérer votre abonnement ici et profiter d’avantages exclusifs.
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Terminer',
  },
  {
    id: 'tour__subscriptions-plan',
    position: 'right',
    element: '#tour__subscriptions-plan',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">Profitez de Chantier Privé</h1>
        <p className="intro-step-content">
          Vous profitez actuellement de l’abonnement gratuit. Passez au premium pour profiter
          d’avantages exclusifs !
        </p>
      </div>
    ),
    disableInteraction: true,
    showButtons: true,
    tooltipClass: 'custom-step',
    nextLabel: 'Terminer',
    conditions: ['free'],
    redirection: '/settings/subscription',
  },
  {
    id: 'tour__outro',
    intro: (
      <div id="tour__step">
        <h1 className="intro-step-title">
          Chantier privé n&apos;a plus de secret pour vous&#8239;!
        </h1>
        <p>
          Nous espérons que vous profiterez pleinement de votre expérience sur Chantier
          Privé&#8239;!
          <br />
          <br />
          <b>
            Une question ? N’hésitez pas à nous contacter via le chat situé en bas à droite de votre
            écran pour tout renseignement.
          </b>
        </p>
      </div>
    ),
    disableInteraction: false,
    showButtons: true,
    tooltipClass: 'intro-step',
    nextLabel: 'Trouver des chantiers',
  },
];
