import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { UserContext } from '../../common/providers/UserProvider';
import Button from '../../common/navigation/Button';
import PhoneInput from '../../common/navigation/PhoneInput';
import queryString from 'query-string';
import axios from 'axios';
import { useAnalytics } from '../../common/RudderStack';
import Modal from '../../common/Modal';
import { BACKEND_URL } from '../../config/environment';

const CompleteRegistration = () => {
  const analytics = useAnalytics();
  const { user, login } = useContext(UserContext);
  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const queryParams = queryString.parse(location.search);
  const [email] = useState<string>(queryParams.email);
  const [siret, setSiret] = useState<string>(null);
  const [phoneCode, setPhoneCode] = useState<string>('FR');
  const [phone, setPhone] = useState<string>(null);
  const [password, setPassword] = useState<string>(null);
  const [conditionsAccepted, setConditionsAccepted] = useState<boolean>(false);
  const [communicationAuthorized, setCommunicationAuthorized] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);

  const sendLoginRequest = (email: string, password: string) => {
    axios
      .post(`${BACKEND_URL}/api/login`, { mail: email, password })
      .then(({ data }) => {
        login(data);
      })
      .catch(() => {
        setError('E-mail ou mot de passe incorrect.');
      });
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);

    if (isSignedUp) {
      sendLoginRequest(email.trim().toLowerCase(), password);
    }
  };

  const signUp = () => {
    if (email && password && siret && phone) {
      setLoading(true);
      const toMail = email.trim().toLowerCase();
      const toSiret = siret.trim().replace(/\s/g, '');
      const toPhone = phone.trim();
      axios
        .post(`${BACKEND_URL}/api/register`, {
          mail: toMail,
          password,
          siret: toSiret,
          phone: toPhone,
          phone_code: phoneCode,
          conditions_accepted: conditionsAccepted,
          communication_authorized: communicationAuthorized,
        })
        .then(({ data }) => {
          analytics?.identify(
            data.user.id,
            {
              name: data.user.name,
              email: data.user.mail,
            },
            () => {
              analytics?.track('User Registered', {
                email: data.user.mail,
              });
            }
          );

          setIsModalVisible(true);
          setIsSignedUp(true);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;

          setError(error ? error : 'SIRET, téléphone ou mot de passe incorrect.');
        })
        .finally(() => setLoading(false));
    } else {
      setError('Merci de remplir tous les champs.');
    }
  };

  const getRedirectPath = () => {
    const pathname = '/register/formulas';

    return pathname;
  };

  return (
    <div className="container height-100">
      {user && user.mail && <Redirect to={{ pathname: getRedirectPath() }} />}

      <div className="d-flex align-items-center row height-100">
        <div className="col-lg-8 offset-lg-2">
          <div className="card card-body card-login-and-register mt-3">
            <img
              src="/images/logo_saqara.png"
              className="logo"
              alt="Logo chantier privé par Saqara"
            />

            <div className="container">
              {error && <ErrorMessage text={error} />}

              <input
                id="email"
                data-cy="email"
                type="text"
                className="form-control"
                placeholder="E-mail"
                name="email"
                readOnly={true}
                value={email}
              />
              <input
                id="siret"
                data-cy="siret"
                type="text"
                className="form-control"
                placeholder="SIRET"
                autoComplete="off"
                name="siret"
                onChange={(e) => setSiret(e.target.value)}
              />

              <PhoneInput
                onCodeClick={(e) => setPhoneCode(e.currentTarget.value)}
                onPhoneChange={(e) => setPhone(e.target.value)}
                value={{ phone, code: phoneCode }}
              />

              <input
                id="password"
                data-cy="password"
                type="password"
                className="form-control"
                placeholder="Mot de passe"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="text-left mt-2">
                <input
                  id="cgu_authorized"
                  data-cy="cgu_authorized"
                  type="checkbox"
                  name="cgu_authorized"
                  checked={conditionsAccepted}
                  onChange={(e) => setConditionsAccepted(e.target.checked)}
                />{' '}
                J&apos;ai lu et j&apos;accepte les{' '}
                <a
                  href={`${process.env.BUCKET_URL}/documents/cgu.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  conditions générales d&apos;utilisation
                </a>
              </div>

              <div className="text-left mt-2">
                <input
                  id="sms_authorized"
                  data-cy="sms_authorized"
                  type="checkbox"
                  name="sms_authorized"
                  checked={communicationAuthorized}
                  onChange={(e) => setCommunicationAuthorized(e.target.checked)}
                />{' '}
                J&apos;accepte de recevoir des communications promotionnelles de la part de Chantier
                Privé
              </div>

              <Button
                id="button-create-account"
                data-cy="button-create-account"
                color="btn-secondary mt-3"
                text={'Finaliser mon inscription'}
                action={() => signUp()}
                loading={loading}
                disabled={!email || !password || !siret || !phone || !conditionsAccepted}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal hidden={isModalVisible} maxWidth={600} closeModal={handleCloseModal}>
        <div className="card">
          <div className="card-body p-lg-5">
            <h2 className="title-card-option mb-4">Mail de confirmation envoyé</h2>

            <div>
              <p className="text-center">
                Nous venons d&apos;envoyer un lien de confirmation sur votre addresse mail.
                <br />
                Pensez à bien vérifier dans vos spams si vous n&apos;avez rien reçu.
              </p>
            </div>

            <div className="col-lg-8 offset-lg-2">
              <Button
                id="button-validate-user-modal"
                data-cy="button-validate-user-modal"
                color="btn-secondary mt-3"
                text={'Valider'}
                action={handleCloseModal}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompleteRegistration;
