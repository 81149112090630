import React, { useState, useEffect } from 'react';
import ConstructionTypeEdit from '../../components/settings/preferences/ConstructionTypeEdit';
import { useAnalytics } from '../RudderStack';
interface ConstructionTypesDropdownProps {
  types: Array<string>;
  disabled: Array<string>;
  onChange: (types: Array<string>) => void;
}

const ConstructionTypesDropdown: React.FC<ConstructionTypesDropdownProps> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [constructionTypes, setConstructionTypes] = useState<Array<string>>(props.types);
  const analytics = useAnalytics();

  /**
   * Closes dropdowns when clicking outside of the dropdown
   */
  useEffect(() => {
    const onClickEvent = (e: MouseEvent) => {
      const menu = document.getElementById('construction-dropdown');
      const target = e.target as Node;

      if (!menu.contains(target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('click', onClickEvent);

    return () => document.removeEventListener('click', onClickEvent);
  }, []);

  const updateConstructionTypes = (constructionType: string) => {
    const eventData = {
      filter_type: 'Construction Type',
      filter_key: constructionType,
      filter_value: '',
    };

    if (constructionTypes.includes(constructionType)) {
      setConstructionTypes(constructionTypes.filter((value) => value !== constructionType));
      eventData.filter_type = 'unchecked';
    } else {
      eventData.filter_key = 'checked';
      setConstructionTypes([...constructionTypes, constructionType]);
    }

    analytics?.track('Filter Changed', eventData);
  };

  const updateAllConstructionTypes = (types: Array<string>) => {
    setConstructionTypes(types);

    analytics?.track('Filter Changed', {
      filter_type: 'Construction Type',
      filter_key: 'All',
      filter_value: types.length ? 'checked' : 'unchecked',
    });
  };

  const onTypesApply = () => {
    props.onChange(constructionTypes.filter((t) => !props.disabled.includes(t)));

    setIsVisible(false);
  };

  const onTypesCancel = () => {
    setConstructionTypes(props.types);
    setIsVisible(false);
  };

  const displaySelected = (a: Array<string>): string => {
    const str = a.join('; ');

    return str.length > 63 ? str.substr(0, 63) : str;
  };

  return (
    <div
      id="construction-dropdown"
      data-cy="construction-dropdown"
      className={'search-dropdown' + (isVisible ? ' show' : '')}
    >
      <div className="toggle mt-0" role="button" onClick={() => setIsVisible((v) => !v)}>
        <div className="toggle-header">
          <span className="title">Types de constructions</span>
          {props.types.length ? (
            <b className="selected" data-cy="constructions-types-selected">
              {displaySelected(props.types)}
            </b>
          ) : (
            <span className="text">Par types de constructions...</span>
          )}
        </div>
        <span className="caret"></span>
      </div>

      <div className={'menu' + (isVisible ? ' show' : '')}>
        <div className="menu-element">
          <div className="mobile-header" onClick={() => setIsVisible((v) => !v)}>
            <span className="title">Types de constructions</span>
            <span className="caret"></span>
          </div>

          <ConstructionTypeEdit
            updateConstructionTypes={updateConstructionTypes}
            onCheckAllTypes={updateAllConstructionTypes}
            constructionTypes={constructionTypes}
            disabled={props.disabled}
          />
        </div>

        <hr className="mt-0" />

        <div className="menu-element">
          <div className="dropdown-actions">
            <button
              type="button"
              data-cy="cancel-constructions"
              className="btn btn-outline-primary mr-1"
              onClick={onTypesCancel}
            >
              Annuler
            </button>

            <button
              type="button"
              data-cy="apply-constructions"
              className="btn btn-primary"
              onClick={onTypesApply}
              disabled={constructionTypes.filter((t) => !props.disabled.includes(t)).length === 0}
            >
              Appliquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConstructionTypesDropdown;
