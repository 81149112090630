import React from 'react';

const ProjectRequest: React.FC<{
  projectRequest: IProjectRequest;
}> = ({ projectRequest }) => {
  /**
   * Get the formatted localisation for project request company.
   * @returns string
   */
  const getCompanyLocalisation = () => {
    const city = projectRequest.account.company.city ?? '-';
    const zipCode = projectRequest.account.company.zip_code
      ? '(' + projectRequest.account.company.zip_code.substr(0, 2) + ')'
      : '';

    return (city + ' ' + zipCode).trim();
  };

  return (
    <tr className="project-request-row">
      <td>
        <div className="company">
          <span title={projectRequest.account.company.name ?? '-'} className="company-name">
            {projectRequest.account.company.name ?? '-'}
          </span>
          <span className="company-separator">|</span>
          <span title={getCompanyLocalisation()} className="company-city">
            {getCompanyLocalisation()}
          </span>
        </div>
        <div className="company-siret">
          <span>
            <b>SIRET : </b>
            {projectRequest.account.company.siret}
          </span>
        </div>
      </td>
      <td className="phone">
        <span>{projectRequest.account.main_user.phone ?? '-'}</span>
      </td>
      <td>
        <a
          title={projectRequest.account.main_user.mail}
          href={`mailto:${projectRequest.account.main_user.mail}`}
          className="company-mail"
        >
          {projectRequest.account.main_user.mail ?? '-'}
        </a>
      </td>
      <td>
        <ul className="request-trade-list">
          {projectRequest.request_trades.map((requestTrade, index) => {
            return (
              <li key={`request-trade-${index}`} className="request-trade">
                {requestTrade.status === 'validated' && (
                  <div title={requestTrade.trade.name} className="activated">
                    <img src="/Icons/check-circle.svg" alt="check circle" />
                    <span className="request-trade-name">{requestTrade.trade.name}</span>
                  </div>
                )}
                {requestTrade.status === 'refused' && (
                  <div title={requestTrade.trade.name} className="refused">
                    <img src="/Icons/cross-circle.svg" alt="cross circle" />
                    <span className="request-trade-name">{requestTrade.trade.name}</span>
                  </div>
                )}

                {requestTrade.status === 'requested' && (
                  <div title={requestTrade.trade.name} className="requested">
                    <span className="request-trade-name">{requestTrade.trade.name}</span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </td>
    </tr>
  );
};

export default ProjectRequest;
