import React from 'react';
import { useHistory } from 'react-router';

const ProjectListElementPromote: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <div className="col-lg-4 col-sm-6 col-md-6">
        <div className="card card-body mt-4 card-promotion">
          <h2>
            Accédez à tous
            <br />
            les projets
          </h2>
          <h1>En vous abonnant</h1>
          <p>
            Et trouvez rapidement de nombreux chantiers répondant à vos critères de localisation, de
            taille et de type...
          </p>

          <button
            className="btn btn-primary mt-3"
            onClick={() => history.push('/settings/subscription')}
          >
            S&apos;abonner
          </button>
        </div>
      </div>
    </>
  );
};

export default ProjectListElementPromote;
