import React, { useState, useContext } from 'react';
import axios from 'axios';
import Modal from './index';
import Button from '../navigation/Button';
import ErrorMessage from '../errors/ErrorMessage';
import PhoneInput from '../../common/navigation/PhoneInput';
import { useAnalytics } from '../RudderStack';
import { BACKEND_URL } from '../../config/environment';
import { UserContext } from '../../common/providers/UserProvider';

/**
 * This react component is used to see the login modal.
 * This modal is used to inform the user if the mail or the password is wrong.
 * @param props
 * @constructor
 */
const SignUpModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
  toCall: () => void;
  switchModal: () => void;
}> = (props) => {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [siret, setSiret] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [phoneCode, setPhoneCode] = useState<string>('FR');
  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [conditionsAccepted, setConditionsAccepted] = useState<boolean>(true);
  const [communicationAuthorized, setCommunicationAuthorized] = useState<boolean>(true);
  const analytics = useAnalytics();
  const { login } = useContext(UserContext);

  function signUp() {
    if (email && password && siret && phone) {
      setLoading(true);
      const toMail = email.trim().toLowerCase();
      const toSiret = siret.trim().replace(/\s/g, '');
      const toPhone = phone.trim();
      axios
        .post(`${BACKEND_URL}/api/register`, {
          mail: toMail,
          password,
          siret: toSiret,
          phone: toPhone,
          phone_code: phoneCode,
          conditions_accepted: conditionsAccepted,
          communication_authorized: communicationAuthorized,
          open_consultation: true,
        })
        .then(({ data }) => {
          analytics?.identify(
            data.user.id,
            {
              name: data.user.name,
              email: data.user.mail,
            },
            () => {
              analytics?.track('User Registered', {
                email: data.user.mail,
              });
            }
          );

          axios
            .post(`${BACKEND_URL}/api/login`, { mail: toMail, password })
            .then(({ data }) => {
              login(data);
              props.toCall();
            })
            .catch(() => {
              setError('Connexion échoué, veuillez réessayer.');
            });
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setError(error ? error : 'Email ou mot de passe incorrect.');
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <Modal hidden={props.hidden} closeModal={props.closeModal} maxWidth={600}>
      <div className="modal-body">
        <img
          src="/images/logo_saqara.png"
          alt="Logo chantier privé par Saqara"
          className="logo-external-project"
        />

        <p className="mt-4">
          L&apos;inscription est <b>gratuite</b> et <b>sans engagement</b>.
        </p>

        {error && <ErrorMessage text={error} />}

        <input
          type="text"
          className="form-control"
          placeholder="Email"
          autoComplete="off"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          className="form-control"
          placeholder="SIRET"
          autoComplete="off"
          name="siret"
          onChange={(e) => setSiret(e.target.value)}
        />
        <PhoneInput
          onCodeClick={(e) => setPhoneCode(e.currentTarget.value)}
          onPhoneChange={(e) => setPhone(e.target.value)}
          value={{ phone, code: phoneCode }}
        />
        <input
          type="password"
          className="form-control"
          placeholder="Mot de passe"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="text-left mt-2">
          <input
            id="cgu_authorized"
            data-cy="cgu_authorized"
            type="checkbox"
            name="cgu_authorized"
            checked={conditionsAccepted}
            onChange={(e) => setConditionsAccepted(e.target.checked)}
          />{' '}
          J&apos;ai lu et j&apos;accepte les{' '}
          <a href={`${process.env.SAQARA_URL_CGV}`} target="_blank" rel="noreferrer">
            conditions générales d&apos;utilisation de Saqara
          </a>
        </div>

        <div className="text-left mt-2">
          <input
            id="sms_authorized"
            data-cy="sms_authorized"
            type="checkbox"
            name="sms_authorized"
            checked={communicationAuthorized}
            onChange={(e) => setCommunicationAuthorized(e.target.checked)}
          />{' '}
          J&apos;accepte de recevoir des communications promotionnelles de la part de Chantier Privé
        </div>

        <Button
          color="btn-secondary mt-3"
          text={"S'inscrire gratuitement"}
          action={() => signUp()}
          loading={loading}
          disabled={!email || !password || !siret || !phone}
        />

        <Button
          color="btn-outline-secondary mt-5"
          text="Se connecter"
          action={() => props.switchModal()}
        />
      </div>
    </Modal>
  );
};

export default SignUpModal;
