import React from 'react';
import { Link } from 'react-router-dom';

const QuestionsList: React.FC<{ category: IFaqCategory; subCategory: IFaqCategory }> = ({
  category,
  subCategory,
}) => {
  return (
    <div className="col-md-8">
      <hr className="border-top hr-col-md-8" />
      {subCategory.faq_questions.map((question: IFaqQuestion) => (
        <p key={`q_item_${question.id}`}>
          {'> '}
          <Link
            to={`/settings/faq/${category.slug}/${subCategory.slug}/${question.slug}`}
            className="text-dark no-uppercase"
          >
            {question.question}
          </Link>
        </p>
      ))}
    </div>
  );
};

export default QuestionsList;
