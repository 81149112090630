import React from 'react';

/**
 * This react component is used to display the title of the current section.
 * @param props
 * @constructor
 */
const TitleIconed: React.FC<{ icon: string; text: string }> = (props) => (
  <div className="large-title w-75">
    <img src={`/Icons/${props.icon}.svg`} alt={props.text} />
    <h1>{props.text}</h1>
  </div>
);

export default TitleIconed;
