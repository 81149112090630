import React from 'react';

const Switch: React.FC<{
  id: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
}> = ({ id, checked = false, label, onChange }) => {
  return (
    <div className="switch-wrapper">
      {/* <div className="mt-2"> */}
      <label htmlFor={id} style={{ fontSize: '14px' }} className="mt-2">
        {label}
      </label>
      {/* </div> */}
      {/* <div className=""> */}
      <div className="custom-control custom-switch custom-switch-lg states-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          data-cy={id}
          onChange={onChange}
          checked={checked}
        />
        {/* The label tag needs to be here unless the switch won't initialize */}
        <label className="custom-control-label" htmlFor={id}></label>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Switch;
