import trades_categories from '../../../components/settings/preferences/trades_categories.json';

const toTradeCategory = (trades_category: ITrades) => ({
  id: trades_category.id,
  name: trades_category.name,
});

const getTradesCategories = (): Array<ITrades> => trades_categories.map(toTradeCategory);

export default getTradesCategories;
