import axios from 'axios';
import React, { useContext } from 'react';
import { UserContext } from '../../common/providers/UserProvider';
import { BACKEND_URL } from '../../config/environment';

const LogASBanner: React.FC = () => {
  const { user } = useContext(UserContext);

  const bannerStyle = {
    position: 'fixed',
    bottom: '-15px',
    left: 0,
    width: '100%',
    zIndex: 999,
  } as React.CSSProperties;

  const shouldDisplayBanner = () => {
    return user && user.is_testing_as === true;
  };

  const disableLogAs = () => {
    axios.get(`${BACKEND_URL}/api/test-as-user/disable`).then((): void => {
      window.location.reload();
    });
  };

  const showProperBanner = (user: IUser) => {
    if (user.profile === 1) {
      return (
        <div className="alert alert-danger" style={bannerStyle}>
          Vous êtes en train d'utiliser l'application avec le profil de <b>{user.mail}</b> -{' '}
          <a href="#" onClick={disableLogAs}>
            Désactiver
          </a>
        </div>
      );
    } else {
      return (
        <div className="alert alert-danger" style={bannerStyle}>
          Vous êtes en train d’administrer le compte de <b>{user.mail}</b> Toutes modifications
          apportées sera appliquée à ce profil. -{' '}
          <a href="#" onClick={disableLogAs}>
            Retourner à mon compte
          </a>
        </div>
      );
    }
  };

  return shouldDisplayBanner() ? showProperBanner(user) : (
      <div></div>
  )
};

export default LogASBanner;
