import React, { useEffect, useState, useContext } from 'react';

import { UserContext } from '../../common/providers/UserProvider';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import LoginCarousel from './LoginCarousel';
import LoginModal from '../../common/Modal/LoginModal';
import Button from '../../common/navigation/Button';
import Toast from '../../common/navigation/Toast';
import PasswordInput from './PasswordInput';
import { useAnalytics } from '../../common/RudderStack';
import { BACKEND_URL, V2_URL } from '../../config/environment';

export default function Login() {
  const history = useHistory();
  const location = useLocation();
  const { offer, coupon, referral_code } = queryString.parse(location.search);
  const { user, login } = useContext(UserContext);
  const [mail, setMail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const analytics = useAnalytics();

  const getUrlWithParams = (baseUrl?: string) => {
    const query = queryString.stringify({ coupon: coupon, offer: offer });

    if (query && query.length > 0) {
      baseUrl += '/?' + queryString.stringify({ coupon: coupon, offer: offer });
    }
    return baseUrl;
  };

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();

    if (mail && password) {
      analytics?.track('Login', {
        email: mail,
      });

      const toMail = mail.trim().toLowerCase();
      setLoading(true);
      axios
        .post(`${BACKEND_URL}/api/login`, { mail: toMail, password })
        .then(({ data }) => {
          if (data.preference.v2_is_active) {
            window.location.href = V2_URL;
            return;
          }
          login(data);
        })
        .catch(() => {
          setOpen(true);
        })
        .finally(() => setLoading(false));
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const openToast = () => {
    setSuccess(false);

    setTimeout(() => {
      setSuccess(true);
      setMessage('');
      setTitle('');
    }, 5 * 1000);
  };

  //Handle cases where URL contains query params
  useEffect(() => {
    window.location.href = V2_URL;
    const query = queryString.parse(location.search);

    if (query.disconnected !== undefined && query.disconnected === 'true') {
      setMessage('Votre session a expiré. Veuillez vous reconnecter.');
      setTitle('Session expirée');
      openToast();
    }

    if (query.success !== undefined && query.success === 'true') {
      setMessage('Votre mot de passe a bien été modifié.');
      setTitle('Modification confirmée');
      openToast();
    }

    if (query.created !== undefined && query.created === 'true') {
      setMessage('Votre compte a bien été créé.');
      setTitle("Vérification d'e-mail");
      openToast();
    }

    if (query.created === 'failure') {
      setMessage("Le lien de confirmation d'e-mail est invalide ou expiré.");
      setTitle('Vérification impossible.');
    }

    if (
      query.created !== undefined ||
      query.success !== undefined ||
      query.disconnected !== undefined
    )
      setSuccess(false);
    setTimeout(() => {
      setSuccess(true);
      setMessage('');
      setTitle('');
    }, 5 * 1000);
  }, []);

  const getRedirectPath = () => {
    let pathname = '/consultation_open';

    if (offer) {
      pathname = `/offers/${offer}`;
    } else if (referral_code) {
      pathname = `/settings/subscription`;
    } else if (window.localStorage.getItem('redirect')) {
      pathname = window.localStorage.getItem('redirect');
      window.localStorage.removeItem('redirect');
    }

    return pathname;
  };

  const getRedirectSearch = () => {
    let search = '';

    if (coupon) {
      search = `?coupon=${coupon}`;
    } else if (referral_code) {
      search = `?referral_code=${referral_code}`;
    }

    return search;
  };

  return (
    <>
      {user && user.mail && (
        <Redirect to={{ pathname: getRedirectPath(), search: getRedirectSearch() }} />
      )}
      <LoginModal hidden={open} closeModal={closeModal} />
      <div className="container height-100">
        <div className="d-flex align-items-vertically row height-100">
          <div className="col-lg-6">
            <div className="card card-body card-auth mt-3">
              <img
                src="/images/logo_saqara.png"
                className="logo"
                alt="Logo chantier privé par Saqara"
              />

              <p className="mt-4">
                Connectez-vous avec votre e-mail
                <br />
                et votre mot de passe.
              </p>

              <form onSubmit={handleLogin}>
                <input
                  id="email"
                  data-cy="email"
                  type="text"
                  className="form-control"
                  placeholder="E-mail"
                  onChange={(e) => setMail(e.target.value)}
                />

                <PasswordInput onChange={(e) => setPassword(e.target.value)} />

                <button
                  id="button-login"
                  data-cy="button-login"
                  className="btn btn-secondary mt-3"
                  type="submit"
                  disabled={loading || !mail || !password}
                >
                  Se connecter
                </button>
              </form>

              <Link
                onClick={() => analytics?.track('Forgot Password Clicked')}
                id="link-forgot-password"
                data-cy="link-forgot-password"
                to="/reset-password"
                className="mt-3"
                href="#"
              >
                Mot de passe oublié
              </Link>

              <Button
                id="button-register-redirect"
                data-cy="button-register-redirect"
                color="btn-outline-secondary mt-5"
                text={"S'inscrire gratuitement"}
                action={() => history.push(getUrlWithParams('/register'))}
              />
            </div>
          </div>
          <div className="col-lg-6 pl-5">
            <LoginCarousel />
          </div>
        </div>
      </div>
      <Toast message={message} title={title} hidden={success} onClick={() => setSuccess(true)} />
    </>
  );
}
