import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import TitleIconed from '../../../common/navigation/TitleIconed';
import HelpBanner from './HelpBanner';
import Phone from '../../../common/navigation/Phone';
import UserMenu from '../../../common/navigation/UserMenu';
import { BACKEND_URL } from '../../../config/environment';
import Banner from '../../banner/Banner';

const MainCategoryList: React.FC<{ user: IUser }> = ({ user }) => {
  const [categories, setCategories] = useState<Array<IFaqCategory>>([]);
  /**
   *  Get the categories
   */
  useEffect(() => {
    axios.get(`${BACKEND_URL}/api/public/faq-categories`).then(({ data }) => {
      setCategories(data.categories);
    });
  }, []);

  return (
    <>
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="noun_filter_activated" text="Centre d'Aide" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description">
        Posez ici toutes vos questions et obtenez rapidement des réponses de notre part ou de la
        communauté.
      </p>
      <Banner />
      <div className="cards-wrapper">
        {categories.map((category, key) => (
          <div className="card" key={`cat-${key}`}>
            <h2 className={`card-header header-${key}`}>
              <Link
                to={`/settings/faq/${category.slug}/${category.sub_categories[0].slug}`}
                className="text-white"
              >
                {category.title}
              </Link>
            </h2>
            <div className="card-body">
              {category.sub_categories.map(
                (sub) =>
                  sub.is_shown && (
                    <p key={sub.id}>
                      {'> '}
                      <Link to={`/settings/faq/${category.slug}/${sub.slug}`}>{sub.title}</Link>
                    </p>
                  )
              )}
            </div>
          </div>
        ))}
      </div>
      <HelpBanner />
    </>
  );
};

export default MainCategoryList;
