import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import Modal from './index';
import { ToastContext } from '../../common/providers/ToastProvider';
import Checkbox from '../navigation/Checkbox';
import { useAnalytics } from '../RudderStack';
import moment from 'moment';
import { BACKEND_URL } from '../../config/environment';

const SelectTradeModal: React.FC<{
  project: IProject;
  hidden: boolean;
  closeModal: () => void;
  toCall?: () => void;
  closableModal?: () => void;
  forceAccess?: boolean;
  user: IUser;
  selectedTrades: Array<string>;
  setSelectedTrades: React.Dispatch<React.SetStateAction<string[]>>;
  openConsultation: boolean;
}> = ({
  project,
  hidden,
  closeModal,
  toCall,
  closableModal,
  user,
  forceAccess,
  selectedTrades,
  setSelectedTrades,
  openConsultation,
}) => {
  const trades = project.trades;
  const [disabled, setDisabled] = useState<boolean>(false);
  const { displayToast } = useContext(ToastContext);
  const analytics = useAnalytics();
  const handleClose = () => {
    setDisabled(true);
    if (user.is_subscriber || forceAccess) {
      axios
        .post(`${BACKEND_URL}/api/project-requests`, {
          project_id: project.id,
          trades: selectedTrades,
          open_consultation: openConsultation,
        })
        .then(() => {
          analytics?.track('Project Requested', {
            name: project.name,
            purchaser_name: project.purchaser_name,
            city: project.city,
            zip_code: project.zip_code,
            construction_type: project.construction_type,
            begin_of_offering_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
            end_of_offering_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
            building_site_start_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
            building_site_end_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
            is_coming: project.is_coming,
            is_flash: project.is_flash,
            is_external: project.is_external,
            selected_trades: selectedTrades,
          });

          displayToast('Votre demande a bien été prise en compte.');
        })
        .catch(() => {
          displayToast('Une erreur inconnue est survenue.');
        })
        .finally(() => {
          setDisabled(false);
          toCall();
          closeModal();
        });
    } else {
      toCall();
      closeModal();
    }
  };

  const handleChange = (trade: ITrade) => {
    analytics?.track('Trade Selected', {
      trade_name: trade.name,
      project_name: project.name,
    });

    let tmp = selectedTrades;

    if (selectedTrades.includes(trade.id)) tmp = selectedTrades.filter((id) => id !== trade.id);
    else tmp = [...selectedTrades, trade.id];

    setSelectedTrades(tmp as string[]);
  };

  /* Trigger clicking outside event to close the modal */
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!modalRef.current.contains(event.target) && closableModal) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <Modal hidden={hidden} maxWidth={600} closeModal={closeModal}>
      <div className="modal-body" ref={modalRef}>
        <h1>SÉLECTIONNEZ VOS LOTS !</h1>
        <p className="mt-4">Sur quel(s) lot(s) souhaitez-vous être consulté ?</p>
        <div className="multi-checkboxes mt-4 text-left">
          {trades &&
            trades.map((trade: ITrade, index: number) => (
              <Checkbox
                className="no-padding trade-check"
                key={`trade-${index}`}
                checked={!!selectedTrades.includes(trade.id)}
                onChange={() => handleChange(trade)}
                label={`${trade.name} ${trade.slice ? `(${trade.slice})` : ''}`}
              />
            ))}
        </div>
        <button
          id={
            selectedTrades.length === 0
              ? 'button-trades-access-dce-disabled'
              : 'button-trades-access-dce'
          }
          data-cy={
            selectedTrades.length === 0
              ? 'button-trades-access-dce-disabled'
              : 'button-trades-access-dce'
          }
          type="button"
          className="btn btn-secondary mt-4"
          onClick={() => handleClose()}
          disabled={selectedTrades.length === 0 || disabled}
        >
          ACCÉDER AU DCE
        </button>
      </div>
    </Modal>
  );
};

export default SelectTradeModal;
