import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TitleIconed from '../../common/navigation/TitleIconed';
import Toast from '../../common/navigation/Toast';
import Pagination from '../../common/navigation/Pagination';
import ProjectListElement from '../projects/ProjectListElement';
import { Link } from 'react-router-dom';
import Phone from '../../common/navigation/Phone';
import UserMenu from '../../common/navigation/UserMenu';
import { BACKEND_URL } from '../../config/environment';
import Banner from '../banner/Banner';

const FavoriteProjectsList: React.FC<{ user: IUser }> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [paginated, setPaginated] = useState<IPaginateProjectFavorite>({
    project_favorites: {
      data: [],
      total: 0,
      last_page: 1,
      current_page: 1,
    },
  });
  const [hiddenToast, setHiddenToast] = useState<boolean>(true);
  const [toastMsg, setToastMsg] = useState<string>(null);

  const { user } = props;

  /**
   * Fetch a page of projects
   *
   */
  const fetchPage = () => {
    const projects = paginated.project_favorites;
    const currentPage = projects.current_page;
    getFavoriteProjectsRequest(currentPage).then(({ data }) => setPaginated(data));
  };

  const getFavoriteProjectsRequest = (page = 1) =>
    axios.get<IPaginateProjectFavorite>(
      `${BACKEND_URL}/api/v2/users/me/projects/favorites?page=${page}`
    );

  useEffect(() => {
    setIsLoading(true);
    getFavoriteProjectsRequest()
      .then(({ data }) => setPaginated(data))
      .finally(() => setIsLoading(false));
  }, []);

  /**
   * ProjectListElement callback after a request was sent
   * of whether adding or removing project from favorites
   *
   * @param value Project is a favorite
   */
  const onFavoriteChange = (value: boolean): void => {
    const projects = paginated.project_favorites;
    const currentPage = projects.current_page;
    const page = projects.data.length > 1 ? currentPage : Math.max(1, currentPage - 1);

    getFavoriteProjectsRequest(page).then(({ data }) => setPaginated(data));

    setToastMsg(
      value
        ? 'Le projet a bien été ajouté aux favoris.'
        : 'Le projet a bien été retiré des favoris.'
    );
    setHiddenToast(false);
    setTimeout(() => {
      setHiddenToast(true);
    }, 3 * 1000);
  };

  const onPageChange = (page: number): void => {
    getFavoriteProjectsRequest(page).then(({ data }) => setPaginated(data));
  };

  return (
    <>
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="star-light" text="Favoris" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description">Retrouvez ici la liste de vos projets favoris.</p>
      <Banner />
      {paginated.project_favorites.data.length > 0 && (
        <>
          <div className="row">
            {paginated.project_favorites.data &&
              paginated.project_favorites.data.map((projectFavorite: IProjectFavorite, index) => (
                <ProjectListElement
                  key={`project-${index}`}
                  project={projectFavorite.project}
                  projectRequest={
                    projectFavorite.project.project_requests.length
                      ? projectFavorite.project.project_requests[0]
                      : null
                  }
                  user={user}
                  reload={() => fetchPage()}
                  onFavoriteChange={onFavoriteChange}
                />
              ))}
            <Toast
              title="Chantier Privé"
              message={toastMsg}
              hidden={hiddenToast}
              onClick={() => setHiddenToast(true)}
              top={true}
              fixedPosition={true}
            />
          </div>
          <Pagination
            lastPage={paginated.project_favorites.last_page}
            currentPage={paginated.project_favorites.current_page}
            toCall={onPageChange}
          />
        </>
      )}
      {!isLoading && paginated.project_favorites.data.length === 0 && (
        <div
          className="alert alert-info d-flex justify-content-between"
          style={{ borderRadius: '20px' }}
        >
          <div>
            <div className="icon-info"></div>
            <div className="text">
              Vous n&apos;avez pas encore ajouté de projet(s) dans votre liste de favoris.
            </div>
          </div>
          <Link to="consultation_open">CONSULTER LES PROJETS</Link>
        </div>
      )}
    </>
  );
};

export default FavoriteProjectsList;
