import React, { useState, useEffect /*, useContext*/ } from 'react';
import axios from 'axios';
import CardQuarterly from './prices/CardQuarterly';
import CardAnnually from './prices/CardAnnually';
import TitleIconed from '../../common/navigation/TitleIconed';
import Phone from '../../common/navigation/Phone';
import UserMenu from '../../common/navigation/UserMenu';
import SubscriptionPromotion from './SubscriptionPromotion';
import { BACKEND_URL } from '../../config/environment';
// import queryString from 'query-string';
// import { ToastContext } from '../../common/providers/ToastProvider';
// import BoxSponsorship from '../../common/navigation/BoxSponsorship';
//import ReferralCode from '../sponsorships/ReferralCode';
import PriceRequestCardAnnually from './prices/PriceRequestCardAnnually';
import CircularLoader from '../../common/navigation/CircularLoader';
import ErrorMessage from '../../common/errors/ErrorMessage';
import OfferItem from '../offers/OfferItem';
import PriceRequestCardFree from './prices/PriceRequestCardFree';

const SubscriptionList: React.FC<{
  user: IUser;
}> = ({ user }) => {
  const [promotions, setPromotions] = useState(null);
  const [prices, setPrices] = useState<Array<IStripePrice>>(null);
  const [specialPrices, setSpecialPrices] = useState<Array<IStripePrice>>(null);
  const [error, setError] = useState(null);
  const [loadingPrice, setLoadingPrice] = useState(true);
  const [hasFoundAnnually, setHasFoundAnnually] = useState(false);
  const [hasFoundQuarterly, setHasFoundQuarterly] = useState(false);
  const [displayPromotion, ] = useState(true);

  // const { referral_code } = queryString.parse(location.search);
  // const [referralCode, setReferralCode] = useState<string>(referral_code);
  // const [isExist, setIsExist] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);
  //const { displayToast } = useContext(ToastContext);

  // const handleOnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   setReferralCode(event.target.value);
  // };

  // useEffect(() => {
  //   if (referral_code) {
  //     referralCodeIsExist();
  //   }
  // }, [referral_code]);

  // const referralCodeIsExist = () => {
  //   setLoading(true);
  //   axios
  //     .post(`${BACKEND_URL}/api/check-referral-code`, { referral_code: referralCode })
  //     .then(() => {
  //       setIsExist(true);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setIsExist(false);
  //       setLoading(false);
  //       displayToast('Le code de parrainage est invalide.');
  //     });
  // };

  useEffect(() => {
    if (!specialPrices) {
      axios
        .get(`${BACKEND_URL}/api/prices/proposed`)
        .then(({ data }) => {
          setSpecialPrices(data.prices);
        })
        .catch(() => {
          setError("Cette offre est invalide ou n'est plus disponible.");
        })
        .finally(() => {
          setLoadingPrice(false);
        });
    } else {
      setLoadingPrice(false);
    }
  }, []);

  useEffect(() => {
    if (user.account.price_requests?.some((element) => element.name === 'annually')) {
      setHasFoundAnnually(true);
    }

    if (user.account.price_requests?.some((element) => element.name === 'quarterly')) {
      setHasFoundQuarterly(true);
    }

    if (!prices) {
      axios
        .get(`${BACKEND_URL}/api/prices/proposed/subscription`)
        .then(({ data }) => {
          setPrices(data.prices);
        })
        .catch(() => {
          setError("Cette offre est invalide ou n'est plus disponible.");
        })
        .finally(() => {
          setLoadingPrice(false);
        });
    } else {
      setLoadingPrice(false);
      setError("Cette offre est invalide ou n'est plus disponible.");
    }
  }, []);

  const handleGetPromotion = (promotionCode: string) => {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`${BACKEND_URL}/api/stripe/promotion`, { params: { promotion_code: promotionCode } })
        .then(({ data }) => {
          setPromotions(data.promotion);
          return resolve();
        })
        .catch((err) => {
          setPromotions(null);
          return reject(err);
        });
    });
  };

  // // 👇️ check if account_price_requests contains annually
  // const hasFoundAnnually = user.account.account_price_requests?.some((element) => {
  //   if (element.name == 'annually') {
  //     return true;
  //   }
  //   return false;
  // });

  // // 👇️ check if account_price_requests contains quarterly
  // const hasFoundQuarterly = user.account.account_price_requests?.some((element) => {
  //   if (element.name == 'quarterly') {
  //     return true;
  //   }
  //   return false;
  // });

  const displayCards = () => {
    if (specialPrices?.length > 0 && prices?.length == 0) {
      return;
    }

    if (prices?.some((item) => item.name === 'quarterly' || item.name === 'annually')) {
      return (
        <>
          {prices?.some((item) => item.name === 'quarterly') && <CardQuarterly prices={prices} />}
          {prices?.some((item) => item.name === 'annually') && <CardAnnually prices={prices} />}
        </>
      );
    } else {
      return (
        <>
          <PriceRequestCardFree taken={true} />
          <PriceRequestCardAnnually foundAnnually={hasFoundAnnually} />
        </>
      );
    }
  };

  return (
    <div>
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="noun_filter_activated" text="S’ABONNER À CHANTIER PRIVÉ" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description mb-5">
        Sélectionnez le plan de votre choix en fonction de vos besoins !
      </p>
      {/* {loading && <CircularLoader />}
      {!loading && (
        <>
          <ReferralCode
            referralCode={referralCode}
            isExist={isExist}
            referralCodeIsExist={referralCodeIsExist}
            handleOnChangeEvent={handleOnChangeEvent}
          />

          {isExist && <BoxSponsorship referralCode={referralCode} />}

          {!isExist && ( */}
      {displayPromotion && (<SubscriptionPromotion handleGetPromotion={handleGetPromotion} />)}
      <div
        className="plans mb-3 mt-5"
        id="tour__subscriptions-plan"
        data-cy="tour-subscriptions-plan"
      >
        {error && <ErrorMessage text={error} />}
        {loadingPrice ? <CircularLoader /> : displayCards()}
      </div>

      {specialPrices && specialPrices.map((stripePrice) => (
        <OfferItem key={`offer-${stripePrice.id}`} stripePrice={stripePrice} />
      ))}
    </div>
  );
};

export default SubscriptionList;
