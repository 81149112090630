import React, { useEffect, useState } from 'react';
import LocalisationEdit from '../../components/settings/preferences/LocalisationEdit';
import { useAnalytics } from '../RudderStack';
import Switch from './Switch';

interface StatesDropdownProps {
  states: Array<IState>;
  nearbyStates: boolean;
  onChange: (states: Array<IState>, extend: boolean) => void;
}

const StatesDropdown: React.FC<StatesDropdownProps> = ({ onChange, ...props }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [states, setStates] = useState<Array<IState>>(props.states);
  const [extendNearby, setExtendNearby] = useState<boolean>(props.nearbyStates);
  const analytics = useAnalytics();

  const onApply = () => {
    onChange(states, extendNearby);

    setIsVisible(false);
  };

  const onCancel = () => {
    setStates(props.states);
    setExtendNearby(false);
    setIsVisible(false);
  };

  const updateStates = (state: IState) => {
    const eventData = {
      filter_type: 'Department',
      filter_key: state.name,
      filter_value: '',
    };

    if (states.some((item) => item.number === state.number)) {
      setStates(states.filter((value) => value.number !== state.number));
      eventData.filter_value = 'unchecked';
    } else {
      eventData.filter_value = 'checked';
      setStates([...states, state]);
    }

    analytics?.track('Filter Changed', eventData);
  };

  const updateAllStates = (stateList: Array<IState>) => {
    analytics?.track('Filter Changed', {
      filter_type: 'Department',
      filter_key: 'All',
      filter_value: stateList.length ? 'checked' : 'unchecked',
    });

    setStates(stateList);
  };

  const displaySelected = (a: Array<IState>): string => {
    const str = a.map((s) => `${s.name} (${s.number})`).join('; ');

    return str.length > 63 ? str.substr(0, 63) : str;
  };

  /**
   * Closes dropdowns when clicking outside of the dropdown
   */
  useEffect(() => {
    const onClickEvent = (e: MouseEvent) => {
      const menu = document.getElementById('states-dropdown');
      const target = e.target as Node;

      if (!menu.contains(target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('click', onClickEvent);

    return () => document.removeEventListener('click', onClickEvent);
  }, []);

  useEffect(() => {
    const app = document.getElementById('app');
    if (app.scrollHeight < 710) {
      app.style.height = '710px';
    }
  }, [isVisible]);

  return (
    <div
      id="states-dropdown"
      data-cy="states-dropdown"
      className={'search-dropdown' + (isVisible ? ' show' : '')}
    >
      <div className="toggle mt-0" role="button" onClick={() => setIsVisible((p) => !p)}>
        <div className="toggle-header">
          <span className="title">Départements</span>
          {props.states.length ? (
            <b className="selected" data-cy="departments-selected">
              {displaySelected(props.states)}
            </b>
          ) : (
            <span className="text">Trouvez des chantiers dans votre zone...</span>
          )}
        </div>
        <span className="caret"></span>
      </div>
      <div className={'menu' + (isVisible ? ' show' : '')}>
        <div className="menu-element">
          <b>Rechercher par département(s)</b>

          <div className="mobile-header" onClick={() => setIsVisible((p) => !p)}>
            <span className="title">Départements</span>
            <span className="caret"></span>
          </div>

          <LocalisationEdit
            updateAllStates={updateAllStates}
            updateStates={updateStates}
            states={states}
          />
        </div>

        <hr className="mt-0" />

        <div className="menu-element">
          <Switch
            id="extend-proximity-states"
            data-cy="extend-proximity-states"
            label="Étendre aux départements de proximité"
            checked={extendNearby}
            onChange={() => setExtendNearby((v) => !v)}
          />
        </div>
        <hr />

        <div className="menu-element">
          <div className="dropdown-actions">
            <button
              type="button"
              data-cy="cancel-department"
              className="btn btn-outline-primary mr-1"
              onClick={onCancel}
            >
              Annuler
            </button>

            <button
              type="button"
              data-cy="apply-department"
              className="btn btn-primary"
              onClick={onApply}
              disabled={states.length === 0}
            >
              Appliquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatesDropdown;
