import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from '../Modal';
import { BACKEND_URL } from '../../config/environment';

/**
 * This react component is used to resend a verification email.
 * @constructor
 */
const BoxMailUnverified: React.FC<{ user: IUser }> = (props) => {
  const [emailAlreadySent, setEmailAlreadySent] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const sendMailConfirmation = () => {
    if ((Date.now() - new Date(props.user.invitation_sent_at).getTime()) / 1000 / 60 < 10) {
      setEmailAlreadySent(true);
      setShowModal(true);

      return;
    }

    setEmailAlreadySent(false);

    axios.post(`${BACKEND_URL}/api/resend-invitation`).then(() => {
      setShowModal(true);
    });
  };

  return (
    <>
      <Modal hidden={showModal} maxWidth={500} closeModal={() => setShowModal(false)}>
        <div className="card card-body">
          <h1 className="mb-3">Confirmation envoyée !</h1>

          {emailAlreadySent && (
            <p style={{ fontSize: 16 }}>
              Un lien de vérification a déjà été envoyé sur votre adresse e-mail. Merci de vérifier
              vos spams.
            </p>
          )}

          {!emailAlreadySent && (
            <p style={{ fontSize: 16 }}>
              Nous venons d&apos;envoyer un lien à votre adresse e-mail. Merci de vérifier dans vos
              spams si vous n&apos;avez rien reçu.
            </p>
          )}

          <button className="btn btn-secondary max-btn mt-3" onClick={() => setShowModal(false)}>
            Valider
          </button>
        </div>
      </Modal>

      <div className="alert alert-red mt-3 d-flex justify-content-between text-left">
        <div>
          <div className="icon-danger" />
          <div className="text">
            Pour demander l&apos;accès aux projets, votre adresse e-mail doit être vérifiée.
          </div>
        </div>
        <Link to="#" style={{ color: '#FFFFFF' }} onClick={() => sendMailConfirmation()}>
          VÉRIFIER MON ADRESSE MAIL
        </Link>
      </div>
    </>
  );
};

export default BoxMailUnverified;
