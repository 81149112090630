import React, { useEffect, useRef, useState, ChangeEvent, useContext } from 'react';
import axios from 'axios';
import UserInformation from './UserInformation';
import CompanyInformation from './CompanyInformation';
import UserInformationEdit from './UserInformationEdit';
import CompanyInformationEdit from './CompanyInformationEdit';
import TitleIconed from '../../../common/navigation/TitleIconed';
import Toast from '../../../common/navigation/Toast';
import BoxMailUnverified from '../../../common/navigation/BoxMailUnverified';
import { UserContext } from '../../../common/providers/UserProvider';
import { ProductTourContext } from '../../../common/providers/ProductTourProvider';
import ErrorMessage from '../../../common/errors/ErrorMessage';
import Phone from '../../../common/navigation/Phone';
import UserMenu from '../../../common/navigation/UserMenu';
import PreventAccountDeleteModal from '../../../common/Modal/PreventAccountDeleteModal';
import { useHistory } from 'react-router';
import { BACKEND_URL } from '../../../config/environment';
import Banner from '../../banner/Banner';

const PersonnalInformationList: React.FC<{ user: IUser }> = ({ user }) => {
  const history = useHistory();
  const { updateUser } = useContext(UserContext);
  const { isTourEnabled, triggerNextTourStep } = useContext(ProductTourContext);

  const userRef = useRef<{ saveUser: () => Promise<IUser> }>();
  const companyRef = useRef<{ saveCompany: () => Promise<ICompany> }>();

  const [company, setCompany] = useState<ICompany>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(true);
  const [passwordSuccess, setPasswordSuccess] = useState<boolean>(true);

  const [userError, setUserError] = useState<string>(null);
  const [companyError, setCompanyError] = useState<string>(null);
  const [companyIsEditable, setCompanyIsEditable] = useState<boolean>(false);

  // Edit password
  const [currentPassword, setCurrentPassword] = useState<string | null>('');
  const [newPassword, setNewPassword] = useState<string | null>('');
  const [newConfirmedPassword, setNewConfimedPassword] = useState<string | null>('');
  const [passwordError, setPasswordError] = useState<string>(null);

  const [userInformationDirty, setUserInformationDirty] = useState<boolean>(false);
  const [companyInformationDirty, setCompanyInformationDirty] = useState<boolean>(false);

  const [openPreventAccountDeleteModal, setOpenPreventAccountDeleteModal] = useState<boolean>(
    false
  );

  useEffect(() => {
    axios.get(`${BACKEND_URL}/api/companies/${user.account.company_id}`).then((data) => {
      setCompany(data.data.company);
      setCompanyIsEditable(data.data.is_editable);
    });
  }, []);

  useEffect(() => {
    if (isTourEnabled) {
      setIsEdit(true);
    }
  }, [isTourEnabled]);

  useEffect(() => {
    if (newPassword && newConfirmedPassword) {
      if (newPassword !== newConfirmedPassword) {
        setPasswordError('Les nouveaux mots de passe ne correspondent pas.');
      } else {
        setPasswordError(null);
      }
    }
  }, [newPassword, newConfirmedPassword]);

  const loadCards = () =>
    isEdit ? (
      <div className="row">
        <UserInformationEdit
          ref={userRef}
          user={user}
          onChange={(isDirty) => setUserInformationDirty(isDirty)}
        />

        <CompanyInformationEdit
          ref={companyRef}
          company={company}
          onChange={(isDirty) => setCompanyInformationDirty(isDirty)}
          isEditable={companyIsEditable}
        />
      </div>
    ) : (
      <div className="row">
        <UserInformation user={user} />
        <CompanyInformation company={company} />
      </div>
    );

  const switchCards = async () => {
    if (isEdit) {
      setUserError(null);
      setCompanyError(null);
      let success = true;
      try {
        const company = await companyRef.current.saveCompany();
        if (company) {
          setCompany(company);
        }
      } catch (err) {
        success = false;
        const error = err?.response?.data?.errorMessage;
        setCompanyError(
          error ? error : "Erreur lors de l'enregistrement de vos informations d'entreprise."
        );
      }

      try {
        const user = await userRef.current.saveUser();
        if (user) {
          updateUser(user);
        }
      } catch (err) {
        success = false;
        const error = err?.response?.data?.errorMessage;
        setUserError(
          error ? error : "Erreur lors de l'enregistrement de vos informations personnelles."
        );
      }

      if (success) {
        setSuccess(false);
        setIsEdit(false);

        setTimeout(() => {
          setSuccess(true);
        }, 5 * 1000);

        if (isTourEnabled) {
          triggerNextTourStep();
        }
      }
    } else {
      setIsEdit(true);
      setUserInformationDirty(false);
      setCompanyInformationDirty(false);
    }
  };

  const changePassword = () => {
    setPasswordError(null);

    axios
      .put(`${BACKEND_URL}/api/user/password`, {
        current: currentPassword,
        password: newPassword,
        password_confirmation: newConfirmedPassword,
      })
      .then(() => {
        setPasswordSuccess(false);
        setTimeout(() => {
          setPasswordSuccess(true);
        }, 5 * 1000);

        setCurrentPassword('');
        setNewPassword('');
        setNewConfimedPassword('');
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          setPasswordError(err?.response?.data?.error);
        } else {
          const error = err?.response?.data?.errors;
          setPasswordError(error ? error : 'Une erreur est survenue.');
        }
      });
  };

  const handleSwitchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const isChecked = event.target.checked;

    axios
      .put(`${BACKEND_URL}/api/user/preferences/email`, {
        name,
        value: isChecked,
      })
      .then(() => {
        updateEmailPreferencesState(name, isChecked);
      });
  };

  const updateEmailPreferencesState = (name: string, isChecked: boolean) => {
    let data = {};
    switch (name) {
      case 'promotion':
        data = {
          promotion: isChecked,
        };
        break;
      case 'promotion_sms':
        data = {
          promotion_sms: isChecked,
        };
        break;
      case 'new_project_available':
        data = {
          new_project_available: isChecked,
        };
        break;
      case 'dce_requests_confirmation':
        data = {
          dce_requests_confirmation: isChecked,
        };
        break;
      case 'dce_validated_confirmation':
        data = {
          dce_validated_confirmation: isChecked,
        };
        break;
      case 'new_project_with_criteria_available':
        data = {
          new_project_with_criteria_available: isChecked,
        };
        break;
      default:
        break;
    }

    updateUser({
      ...user,
      preference: {
        ...user.preference,
        email_settings: {
          ...user.preference.email_settings,
          ...data,
        },
      },
    });
  };

  const handleDeleteAccountClick = () => {
    if (user.is_stripe_subscriber && !user.is_on_grace_period) {
      setOpenPreventAccountDeleteModal(true);
    } else {
      history.push('/settings/account/delete');
    }
  };

  return (
    <>
      <PreventAccountDeleteModal
        hidden={openPreventAccountDeleteModal}
        closeModal={() => setOpenPreventAccountDeleteModal(false)}
      />
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="noun_filter_activated" text="Votre compte" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description mb-3">
        Retrouvez ici toutes vos informations personnelles et celles de votre entreprise.
        <br />
        Des informations à jour permettent au service client de vous accompagner au mieux lors de
        l’utilisation de Chantier Privé.
      </p>
      <Banner />
      {user && user.profile !== 1 && (
        <div className="alert alert-info">
          Si vous souhaitez rejoindre une équipe veuillez nous contacter via le numéro +33 01 87 66
          22 54 ou via e-mail à l&apos;adresse contact@chantierprive.fr
        </div>
      )}
      {user && !user.is_mail_confirmed && <BoxMailUnverified user={user} />}
      <div id="tour__profile-edit" data-cy="tour__profile-edit">
        {userError && <ErrorMessage text={userError} />}
        {companyError && <ErrorMessage text={companyError} />}

        <div className="card card-body">
          {user && company && loadCards()}
          <br />
          <div className="d-flex justify-content-between">
            <button
              id={isEdit ? 'button-save-informations' : 'button-edit-informations'}
              data-cy={isEdit ? 'button-save-informations' : 'button-edit-informations'}
              type="button"
              onClick={() => switchCards()}
              className="btn btn-secondary"
              style={{ maxWidth: 240 }}
              disabled={isEdit && !(userInformationDirty || companyInformationDirty)}
            >
              {isEdit ? 'Enregistrer' : 'Modifier'}
            </button>
            <button
              id="button-reset-informations"
              data-cy="button-reset-informations"
              className="btn btn-outline-secondary ml-2"
              style={{ maxWidth: 240 }}
              hidden={!isEdit || isTourEnabled}
              onClick={() => setIsEdit(false)}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>

      <div className="mt-5"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
            <h2 className="title-card-option">Modifiez votre mot de passe</h2>

            {passwordError && <ErrorMessage text={passwordError} />}
            <div className="row">
              <div className="col-lg-4">
                <input
                  type="password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  value={currentPassword}
                  className="form-control auth-input mt-2"
                  placeholder="Mot de passe actuel"
                />
              </div>
              <div className="col-lg-4">
                <input
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                  className="form-control auth-input mt-2"
                  placeholder="Nouveau mot de passe"
                />
              </div>
              <div className="col-lg-4">
                <input
                  type="password"
                  onChange={(e) => setNewConfimedPassword(e.target.value)}
                  value={newConfirmedPassword}
                  className="form-control auth-input mt-2"
                  placeholder="Confirmer le nouveau mot de passe"
                />
              </div>
            </div>

            <button
              type="button"
              data-cy="button-edit-password"
              id="button-edit-password"
              className="btn btn-secondary mt-3"
              disabled={
                !currentPassword ||
                !newPassword ||
                !currentPassword ||
                newPassword !== newConfirmedPassword
              }
              onClick={() => changePassword()}
              style={{ maxWidth: 240 }}
            >
              Modifier le mot de passe
            </button>
          </div>
        </div>
      </div>

      <div className="mt-5"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
            <h2 className="title-card-option">Préférences e-mails</h2>

            <div className="row mt-2" style={{ marginRight: 5 }}>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col mt-2">
                    <label htmlFor="promotional-email-check">
                      Recevoir des e-mails promotionnels
                    </label>
                  </div>
                  <div className="col-3">
                    <div className="custom-control custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        data-cy="promotional-email-check"
                        id="promotional-email-check"
                        name="promotion"
                        onChange={handleSwitchOnChange}
                        checked={user?.preference?.email_settings?.promotion}
                      />
                      {/* The label tag needs to be here unless the switch won't initialize */}
                      <label className="custom-control-label" htmlFor="promotional-email-check" />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col mt-2">
                    <label htmlFor="promotional-sms-check">Recevoir des SMS promotionnels</label>
                  </div>
                  <div className="col-3">
                    <div className="custom-control custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        data-cy="promotional-sms-check"
                        id="promotional-sms-check"
                        name="promotion_sms"
                        onChange={handleSwitchOnChange}
                        checked={user?.preference?.email_settings?.promotion_sms}
                      />
                      {/* The label tag needs to be here unless the switch won't initialize */}
                      <label className="custom-control-label" htmlFor="promotional-sms-check" />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <label htmlFor="dce-requests-confirmation-switch">
                      Recevoir un e-mail pour accuser réception de mes demandes d&apos;accès DCE
                    </label>
                  </div>
                  <div className="col-3">
                    <div className="custom-control custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        data-cy="dce-requests-confirmation-switch"
                        id="dce-requests-confirmation-switch"
                        name="dce_requests_confirmation"
                        onChange={handleSwitchOnChange}
                        checked={user?.preference?.email_settings?.dce_requests_confirmation}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="dce-requests-confirmation-switch"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-3"></div>
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <label htmlFor="dce-validated-confirmation-check">
                      Recevoir un e-mail pour m&apos;informer de la validation de mes demandes
                      d&apos;accès
                    </label>
                  </div>
                  <div className="col-3">
                    <div className="custom-control custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        data-cy="dce-validated-confirmation-check"
                        id="dce-validated-confirmation-check"
                        name="dce_validated_confirmation"
                        onChange={handleSwitchOnChange}
                        checked={user?.preference?.email_settings?.dce_validated_confirmation}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="dce-validated-confirmation-check"
                      />
                    </div>
                  </div>
                </div>

                <div className="d-none d-sm-block d-md-none mt-3"></div>

                <div className="row" hidden={user?.is_subscriber}>
                  <div className="col">
                    <label htmlFor="new-project-available-switch">
                      Recevoir un e-mail lorsqu&apos;un nouveau projet est disponible sur Chantier
                      Privé
                    </label>
                  </div>
                  <div className="col-3">
                    <div className="custom-control custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        data-cy="new-project-available-switch"
                        id="new-project-available-switch"
                        name="new_project_available"
                        onChange={handleSwitchOnChange}
                        checked={user?.preference?.email_settings?.new_project_available}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="new-project-available-switch"
                      />
                    </div>
                  </div>
                </div>

                <div className="d-none d-sm-block d-md-none mt-3"></div>

                <div className="row mt-3" hidden={!user?.is_subscriber}>
                  <div className="col">
                    <label htmlFor="new-project-with-criteria-switch">
                      Recevoir un e-mail lorsqu&apos;un nouveau projet correspond à mes critères
                    </label>
                  </div>
                  <div className="col-3">
                    <div className="custom-control custom-switch custom-switch-lg">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        data-cy="new-project-with-criteria-switch"
                        id="new-project-with-criteria-switch"
                        name="new_project_with_criteria_available"
                        onChange={handleSwitchOnChange}
                        checked={
                          user?.preference?.email_settings?.new_project_with_criteria_available
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="new-project-with-criteria-switch"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="card card-body">
            <span
              onClick={() => handleDeleteAccountClick()}
              className="delete-link-account"
              data-cy="delete-link-account"
            >
              Supprimer votre compte
            </span>
          </div>
        </div>
      </div>

      <Toast
        message="Votre mot de passe a bien été modifié."
        title="Mot de passe"
        hidden={passwordSuccess}
        onClick={() => setPasswordSuccess(true)}
      />

      <Toast
        message="Vos modifications ont bien été sauvegardées."
        title="Votre compte"
        hidden={success}
        onClick={() => setSuccess(true)}
      />
    </>
  );
};

export default PersonnalInformationList;
