import React from 'react';

const HelpBanner: React.FC = () => {
  return (
    <div className="card mt-4">
      <div className="card-body text-center card-faq-contact pt-5 pb-5">
        <h5>Vous ne trouvez pas la réponse à votre question ?</h5>
        <div className="mt-4 mb-3">
          <span>Contactez notre service support :</span>
          <a href="mailto:contact@chantierprive.fr" className="ml-3">
            <img src="/Icons/icon-email.svg" className="mr-2" />
            contact@chantierprive.fr
          </a>
          <a href="tel:+33187662254" className="ml-3">
            <img src="/Icons/icon-phone.svg" className="mr-2" />
            01 87 66 60 76
          </a>
        </div>
        <span>
          ou directement <b>via le Chat</b> en bas à droite de votre écran
        </span>
      </div>
    </div>
  );
};

export default HelpBanner;
