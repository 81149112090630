import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TitleIconed from '../../../common/navigation/TitleIconed';
import axios from 'axios';
import { BACKEND_URL } from '../../../config/environment';

type Reason = 'no_project' | 'no_result' | 'no_need' | 'not_adapted' | 'other';

type SubReason =
  | 'free_project_not_asked'
  | 'free_project_non_relevant_construction_type'
  | 'free_project_non_relevant_contractor_type'
  | 'free_project_non_relevant_localisation'
  | 'free_project_non_relevant_trade_type'
  | 'free_project_non_relevant_project_size'
  | 'free_project_not_succeed'
  | 'free_project_negative_answer'
  | 'enought_project'
  | 'close_company'
  | 'how_to_access_project'
  | 'other_partner'
  | 'too_expensive'
  | 'other';

type ReasonFormDefinition = {
  no_project: {
    text: string;
    sub_reason: {
      free_project_not_asked: string;
      free_project_non_relevant: {
        text: string;
        sub_reason: {
          construction_type: string;
          contractor_type: string;
          localisation: string;
          trade_type: string;
          project_size: string;
        };
      };
      free_project_not_succeed: string;
      free_project_negative_answer: string;
      other: string;
    };
  };
  no_result: {
    text: string;
  };
  no_need: {
    text: string;
    sub_reason: {
      enought_project: string;
      close_company: string;
      other: string;
    };
  };
  not_adapted: {
    text: string;
    sub_reason: {
      how_to_access_project: string;
      other_partner: string;
      too_expensive: string;
      other: string;
    };
  };
  other: {
    text: string;
  };
};

const DeleteAccount = () => {
  const [reason, setReason] = useState<Reason>();
  const [subReason, setSubReason] = useState<SubReason>();
  const [otherComment, setOtherComment] = useState<string>('');
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);

  const reasons: ReasonFormDefinition = {
    no_project: {
      text: "Je n'ai pas trouvé de DCE intéressants.",
      sub_reason: {
        free_project_not_asked: 'Je n’ai pas fait ma demande gratuite de DCE.',
        free_project_not_succeed: 'J’ai fait une demande gratuite de DCE qui n’a pas abouti',
        free_project_non_relevant: {
          text: 'J’ai demandé gratuitement un DCE qui n’était pas pertinent pour moi',
          sub_reason: {
            construction_type: 'Le type de construction',
            contractor_type: "Le type de donneur d'ordre",
            localisation: 'La zone géographique',
            trade_type: 'Le type de lots',
            project_size: 'La taille du projet',
          },
        },
        free_project_negative_answer: 'J’ai eu une réponse négative à ma demande gratuite de DCE',
        other: 'Autres.',
      },
    },
    no_result: {
      text: "Je n'ai pas obtenu de résultats positifs suite à la sélection pour l'appel d'offres.",
    },
    no_need: {
      text: "Je n'ai plus besoin de trouver de nouveaux projets.",
      sub_reason: {
        enought_project:
          "J'ai assez de projets en cours, je n'ai plus le temps ou l'utilité d'utiliser la plateforme.",
        close_company: 'Je ferme mon entreprise.',
        other: 'Autres.',
      },
    },
    not_adapted: {
      text: 'Je trouve que la plateforme n’est pas adaptée à mes besoins',
      sub_reason: {
        how_to_access_project: 'Je ne sais pas comment accéder aux DCE',
        other_partner: "J'ai trouvé un autre partenaire de prospection.",
        too_expensive: 'Je trouve le prix trop élevé / Je rencontre des difficultés de trésorerie.',
        other: 'Autres.',
      },
    },
    other: {
      text: "J'ai d'autres raisons.",
    },
  };

  const handleReasonChange = (reason: Reason) => {
    setReason(reason);
    setSubReason(undefined);
  };

  const handleSubReasonChange = (subReason: SubReason) => {
    setSubReason(subReason);
  };

  const handleOtherCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherComment(event.target.value);
  };

  const isDisabled = () => {
    if (reason === 'no_result' || reason === 'other') {
      return false;
    }

    return !reason || !subReason;
  };

  const handleSubmit = () => {
    setDeletingUser(true);
    axios
      .post(`${BACKEND_URL}/api/deleted-users`, {
        reason: reason,
        sub_reason: subReason,
        other_comment: otherComment,
      })
      .then(() => {
        setDeletionConfirmed(true);
      })
      .finally(() => {
        setDeletingUser(false);
      });
  };

  return (
    <>
      <TitleIconed icon="noun_filter_activated" text="Supprimer mon compte" />
      <div className="deletion-cards-wrapper card mt-4">
        {!deletionConfirmed ? (
          <>
            <div className="deletion-cards-head">
              <h2 className="deletion-card-title">Outch. On est triste de vous voir partir !</h2>
              <h3 className="deletion-card-sub-title">
                Quelle raison vous pousse à supprimer votre compte ?
              </h3>
              <p className="deletion-card-info">
                Vos réponses sont importantes pour nous, elles nous aident à améliorer
                l&apos;expérience sur notre plateforme. Ceci ne vous prendra que quelques secondes.
              </p>
            </div>

            <div className="card-body">
              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="no_project"
                  id="no_project"
                  value={reason}
                  onChange={() => handleReasonChange('no_project')}
                />
                <label>{reasons['no_project'].text}</label>
              </div>

              {reason === 'no_project' && (
                <div className="sub-reason-box">
                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="free_project_not_asked"
                      id="free_project_not_asked"
                      checked={subReason === 'free_project_not_asked'}
                      onChange={() => handleSubReasonChange('free_project_not_asked')}
                    />
                    <label>{reasons['no_project'].sub_reason['free_project_not_asked']}</label>

                    {subReason === 'free_project_not_asked' && (
                      <>
                        <p className="mb-1">
                          <i>
                            Vous n’avez pas encore profité de votre projet gratuit ! Souhaitez vous
                            faire votre première demande de DCE ?
                          </i>
                        </p>

                        <Link className="free-project-ask" to="/consultation_open">
                          Je demande mon projet gratuit
                        </Link>
                      </>
                    )}
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="free_project"
                      id="free_project"
                      checked={
                        subReason === 'free_project_non_relevant_construction_type' ||
                        subReason === 'free_project_non_relevant_contractor_type' ||
                        subReason === 'free_project_non_relevant_localisation' ||
                        subReason === 'free_project_non_relevant_trade_type' ||
                        subReason === 'free_project_non_relevant_project_size'
                      }
                      onChange={() =>
                        handleSubReasonChange('free_project_non_relevant_construction_type')
                      }
                    />
                    <label>
                      {reasons['no_project'].sub_reason['free_project_non_relevant'].text}
                    </label>
                  </div>

                  {(subReason === 'free_project_non_relevant_construction_type' ||
                    subReason === 'free_project_non_relevant_contractor_type' ||
                    subReason === 'free_project_non_relevant_localisation' ||
                    subReason === 'free_project_non_relevant_trade_type' ||
                    subReason === 'free_project_non_relevant_project_size') && (
                    <>
                      <div className="sub-label">
                        <i>Vous auriez préféré un DCE selon :</i>
                      </div>
                      <div className="free-project-non-relevant">
                        <div className="free-project-non-relevant-element">
                          <input
                            name="free_project_non_relevant"
                            type="radio"
                            data-cy="free_project_non_relevant_construction_type"
                            checked={subReason === 'free_project_non_relevant_construction_type'}
                            onChange={() =>
                              handleSubReasonChange('free_project_non_relevant_construction_type')
                            }
                          />
                          <label>
                            {
                              reasons['no_project'].sub_reason['free_project_non_relevant']
                                .sub_reason['construction_type']
                            }
                          </label>
                        </div>

                        <div className="free-project-non-relevant-element">
                          <input
                            name="free_project_non_relevant"
                            type="radio"
                            data-cy="free_project_non_relevant_contractor_type"
                            id="free_project_non_relevant_contractor_type"
                            checked={subReason === 'free_project_non_relevant_contractor_type'}
                            onChange={() =>
                              handleSubReasonChange('free_project_non_relevant_contractor_type')
                            }
                          />
                          <label>
                            {
                              reasons['no_project'].sub_reason['free_project_non_relevant']
                                .sub_reason['contractor_type']
                            }
                          </label>
                        </div>

                        <div className="free-project-non-relevant-element">
                          <input
                            name="free_project_non_relevant"
                            type="radio"
                            data-cy="free_project_non_relevant_localisation"
                            id="free_project_non_relevant_localisation"
                            checked={subReason === 'free_project_non_relevant_localisation'}
                            onChange={() =>
                              handleSubReasonChange('free_project_non_relevant_localisation')
                            }
                          />
                          <label>
                            {
                              reasons['no_project'].sub_reason['free_project_non_relevant']
                                .sub_reason['localisation']
                            }
                          </label>
                        </div>

                        <div className="free-project-non-relevant-element">
                          <input
                            name="free_project_non_relevant"
                            type="radio"
                            data-cy="free_project_non_relevant_trade_type"
                            id="free_project_non_relevant_trade_type"
                            checked={subReason === 'free_project_non_relevant_trade_type'}
                            onChange={() =>
                              handleSubReasonChange('free_project_non_relevant_trade_type')
                            }
                          />
                          <label>
                            {
                              reasons['no_project'].sub_reason['free_project_non_relevant']
                                .sub_reason['trade_type']
                            }
                          </label>
                        </div>

                        <div className="free-project-non-relevant-element">
                          <input
                            name="free_project_non_relevant"
                            type="radio"
                            data-cy="free_project_non_relevant_project_size"
                            id="free_project_non_relevant_project_size"
                            checked={subReason === 'free_project_non_relevant_project_size'}
                            onChange={() =>
                              handleSubReasonChange('free_project_non_relevant_project_size')
                            }
                          />
                          <label>
                            {
                              reasons['no_project'].sub_reason['free_project_non_relevant']
                                .sub_reason['project_size']
                            }
                          </label>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="free_project_not_succeed"
                      id="free_project_not_succeed"
                      checked={subReason === 'free_project_not_succeed'}
                      onChange={() => handleSubReasonChange('free_project_not_succeed')}
                    />
                    <label>{reasons['no_project'].sub_reason['free_project_not_succeed']}</label>
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="negative_answer"
                      id="negative_answer"
                      checked={subReason === 'free_project_negative_answer'}
                      onChange={() => handleSubReasonChange('free_project_negative_answer')}
                    />
                    <label>
                      {reasons['no_project'].sub_reason['free_project_negative_answer']}
                    </label>
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="other"
                      id="other"
                      checked={subReason === 'other'}
                      onChange={() => handleSubReasonChange('other')}
                    />
                    <label>{reasons['no_project'].sub_reason['other']}</label>
                  </div>

                  {subReason === 'other' && (
                    <div className="comment-element">
                      <textarea
                        maxLength={255}
                        data-cy="other_comment"
                        id="other_comment"
                        placeholder="Écrire un commentaire ..."
                        value={otherComment}
                        onChange={(event) => handleOtherCommentChange(event)}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="no_result"
                  id="reason"
                  value={reason}
                  onChange={() => handleReasonChange('no_result')}
                />
                <label>{reasons['no_result'].text}</label>
              </div>

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="no_need"
                  id="reason"
                  value={reason}
                  onChange={() => handleReasonChange('no_need')}
                />
                <label>{reasons['no_need'].text}</label>
              </div>

              {reason === 'no_need' && (
                <div className="sub-reason-box">
                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="enought_project"
                      id="enought_project"
                      checked={subReason === 'enought_project'}
                      onChange={() => handleSubReasonChange('enought_project')}
                    />
                    <label>{reasons['no_need'].sub_reason['enought_project']}</label>
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="close_company"
                      id="close_company"
                      checked={subReason === 'close_company'}
                      onChange={() => handleSubReasonChange('close_company')}
                    />
                    <label>{reasons['no_need'].sub_reason['close_company']}</label>
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="other"
                      id="other"
                      checked={subReason === 'other'}
                      onChange={() => handleSubReasonChange('other')}
                    />
                    <label>{reasons['no_need'].sub_reason['other']}</label>
                  </div>

                  {subReason === 'other' && (
                    <div className="comment-element">
                      <textarea
                        maxLength={255}
                        data-cy="other"
                        id="other"
                        placeholder="Écrire un commentaire ..."
                        value={otherComment}
                        onChange={(event) => setOtherComment(event.target.value)}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="not_adapted"
                  id="reason"
                  value={reason}
                  onChange={() => handleReasonChange('not_adapted')}
                />
                <label>{reasons['not_adapted'].text}</label>
              </div>

              {reason === 'not_adapted' && (
                <div className="sub-reason-box">
                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="how_to_access_project"
                      id="how_to_access_project"
                      checked={subReason === 'how_to_access_project'}
                      onChange={() => handleSubReasonChange('how_to_access_project')}
                    />
                    <label>{reasons['not_adapted'].sub_reason['how_to_access_project']}</label>

                    {subReason === 'how_to_access_project' && (
                      <>
                        <p className="mb-1">
                          <i>
                            Vous n’avez pas encore profité de votre projet gratuit ! Souhaitez vous
                            faire votre première demande de DCE ?
                          </i>
                        </p>

                        <Link className="free-project-ask" to="/consultation_open">
                          Je demande mon projet gratuit
                        </Link>
                      </>
                    )}
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="other_partner"
                      id="other_partner"
                      checked={subReason === 'other_partner'}
                      onChange={() => handleSubReasonChange('other_partner')}
                    />
                    <label>{reasons['not_adapted'].sub_reason['other_partner']}</label>
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="too_expensive"
                      id="too_expensive"
                      checked={subReason === 'too_expensive'}
                      onChange={() => handleSubReasonChange('too_expensive')}
                    />
                    <label>{reasons['not_adapted'].sub_reason['too_expensive']}</label>
                  </div>

                  <div className="sub-reason-element">
                    <input
                      name="sub_reason"
                      type="radio"
                      data-cy="other"
                      id="other"
                      checked={subReason === 'other'}
                      onChange={() => handleSubReasonChange('other')}
                    />
                    <label>{reasons['not_adapted'].sub_reason['other']}</label>
                  </div>

                  {subReason === 'other' && (
                    <div className="comment-element">
                      <textarea
                        maxLength={255}
                        data-cy="other"
                        id="other"
                        placeholder="Écrire un commentaire ..."
                        value={otherComment}
                        onChange={(event) => setOtherComment(event.target.value)}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="reason-element">
                <input
                  name="reason"
                  type="radio"
                  data-cy="other"
                  id="reason"
                  value={reason}
                  onChange={() => handleReasonChange('other')}
                />
                <label>{reasons['other'].text}</label>
              </div>

              {reason === 'other' && (
                <div className="sub-reason-box">
                  <div className="comment-element">
                    <textarea
                      maxLength={255}
                      data-cy="other"
                      id="other"
                      placeholder="Écrire un commentaire ..."
                      value={otherComment}
                      onChange={(event) => setOtherComment(event.target.value)}
                    />
                  </div>
                </div>
              )}

              <div className="deletion-card-button">
                <div className="mr-2">
                  <Link to={`/settings/subscription`}>
                    <button
                      type="button"
                      id="button-cancel"
                      data-cy="button-cancel"
                      className="btn btn-outline-secondary"
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="ml-2">
                  <button
                    disabled={isDisabled() || deletingUser}
                    onClick={() => handleSubmit()}
                    id="button-continue"
                    data-cy="button-continue"
                    className="btn btn-secondary"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="delete-confirmation">
            <h2 className="deletion-card-title">
              Votre demande de suppression de compte a bien été prise en compte.
            </h2>

            <p className="deletion-card-sub-title">
              Vous pouvez réactiver votre compte en vous reconnectant dans les 72h, au dela, votre
              compte sera totalement supprimé.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default DeleteAccount;
