import React, { useState } from 'react';

import axios from 'axios';
import LoginCarousel from './LoginCarousel';
import ResetPasswordModal from '../../common/Modal/ResetPasswordModal';
import Button from '../../common/navigation/Button';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { BACKEND_URL } from '../../config/environment';

const ResetPassword = () => {
  const [mail, setMail] = useState<string | null>(null);
  const [error, setError] = useState<string>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const sendResetLink = () => {
    setError(null);

    if (mail) {
      setLoading(true);
      const toMail = mail.trim().toLowerCase();
      axios
        .post(`${BACKEND_URL}/api/forgot-password`, { mail: toMail })
        .then(() => {
          setOpen(true);
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setError(error ? error : 'Une erreur est survenue.');
        })
        .finally(() => setLoading(false));
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      {mail && (
        <ResetPasswordModal
          hidden={open}
          closeModal={closeModal}
          mail={mail}
          sendResetLink={sendResetLink}
        />
      )}
      <div className="container height-100">
        <div className="d-flex align-items-center row height-100">
          <div className="col-lg-6">
            <div className="card card-body card-auth mt-3">
              <img
                src="/images/logo_saqara.png"
                className="logo"
                alt="Logo chantier privé par Saqara"
              />

              <p className="mt-4">
                Renseignez votre e-mail pour réinitialiser
                <br />
                votre mot de passe :
              </p>

              {error && <ErrorMessage text={error} />}

              <input
                type="text"
                className="form-control"
                placeholder="E-mail"
                data-cy="E-mail"
                onChange={(e) => setMail(e.target.value)}
              />

              <Button
                color="btn-secondary mt-3"
                text="Réinitialiser"
                action={() => sendResetLink()}
                loading={loading}
                disabled={!mail}
              />
            </div>
          </div>
          <div className="col-lg-6 pl-5">
            <LoginCarousel />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
