import React, { useContext } from 'react';
import { UserContext } from '../providers/UserProvider';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface GuestRouteProps extends RouteProps {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  component?: any;
  exact?: boolean;
  path: string;
}

// Redirect to /consultation_open if user is logged in
const GuestRoute: React.FC<GuestRouteProps> = (props) => {
  const { component: Component, exact = false, path } = props;
  const { user } = useContext(UserContext);
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (!user) {
          return <Component {...props} user={user} />;
        } else {
          return (
            <Redirect to={{ pathname: '/consultation_open', state: { from: props.location } }} />
          );
        }
      }}
    />
  );
};

export default GuestRoute;
