import React from 'react';

const LoginCarousel: React.FC = () => (
  <>
    <div className="carousel slide mt-3" id="carousel" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carousel" data-slide-to="0" className="active" />
        <li data-target="#carousel" data-slide-to="1" />
        <li data-target="#carousel" data-slide-to="2" />
        <li data-target="#carousel" data-slide-to="3" />
        <li data-target="#carousel" data-slide-to="4" />
      </ol>

      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="text align-text-bottom">
            <h2>
              Gagnez du temps,
              <br />
              ne cherchez plus vos projets,
            </h2>

            <h1>trouvez les ici !</h1>
            <p>
              <b>Chantier Privé</b> est le nouveau service de Saqara qui propose aux entreprises du
              bâtiment de trouver <b>facilement</b> et <b>rapidement</b> les chantiers privés
              référencés exclusivement sur la plateforme AOS by Saqara.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="text align-text-bottom">
            <h2>Marchés privés</h2>

            <h1>UNIQUEMENT</h1>
            <p>Vous ne trouverez ici aucun chantier public, seulement du privé !</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="text">
            <h2>Des DCE</h2>

            <h1>EXCLUSIFS</h1>
            <p>Accédez à des projets disponibles uniquement sur notre site.</p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="text">
            <h2>Dans</h2>

            <h1>VOTRE RÉGION</h1>
            <p>
              Local, régional, national, où que vous soyez, accédez aux chantiers de votre rayon
              d&apos;action.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="text">
            <h2>Toutes tailles</h2>

            <h1>DE CHANTIERS</h1>
            <p>Petites opérations ou grands programmes, choisissez ce qui vous correspond.</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default LoginCarousel;
