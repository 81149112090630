import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserProvider';
import { useEffect } from 'react';
import { BACKEND_URL } from '../../config/environment';

const SubscriptionContext = createContext<{
  subscription: ISubscription | null;
  refreshSubscription: () => void;
}>({
  subscription: null,
  refreshSubscription: null,
});

const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, fetched } = useContext(UserContext);
  const [subscription, setSubscription] = useState<ISubscription>(null);

  /** Reset the subscription state if the user sign out. */
  useEffect(() => {
    if (fetched && !user) {
      setSubscription(null);
    }
  }, [user, fetched]);

  const refreshSubscription = () => {
    axios.get<{ subscription: ISubscription }>(`${BACKEND_URL}/api/subscriptions`).then((data) => {
      setSubscription(data.data.subscription);
    });
  };

  return (
    <SubscriptionContext.Provider value={{ subscription, refreshSubscription }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionProvider, SubscriptionContext };
