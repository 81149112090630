import React from 'react';
import Modal from './index';
import { Link } from 'react-router-dom';

/**
 * This react component is used to cancel subscription.
 * @param props
 */
const CancelSubscriptionModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
}> = ({ hidden, closeModal }) => {
  return (
    <Modal hidden={hidden} maxWidth={750} closeModal={closeModal}>
      <div className="modal-body">
        <div>
          <h1 className="mt-4">MODIFIER VOTRE ABONNEMENT</h1>
          <p className="mt-4 unsubscribe">
            Vous ne pouvez pas passer à un abonnement trimestriel si vous êtes sur un abonnement
            annuel. Par contre vous pouvez résilier votre abonnement et choisir un abonnement
            trimestriel.
          </p>
        </div>
        <div className="my-4">
          <Link
            to="/settings/subscription/cancel"
            id="unsubscribe"
            data-cy="unsubscribe"
            className="link-unsubscribe"
          >
            Résilier votre abonnement
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
