import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Modal from './index';

/**
 * This react component is used to see the password reset modal.
 * This modal iss used to inform the user that the password reset
 * has been done and that an email has been sent to the user.
 * @param props
 * @constructor
 */
const ResetPasswordModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
  sendResetLink: () => void;
  mail: string;
}> = (props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const history = useHistory();

  const handleSendResetLink = () => {
    setDisabled(true);
    props.sendResetLink();
  };

  return (
    <Modal hidden={props.hidden} maxWidth={600} closeModal={props.closeModal}>
      <div className="modal-body">
        <h1>MOT DE PASSE OUBLIÉ</h1>
        <p className="mt-2">
          Un email a été envoyé à {props.mail} avec la marche à suivre pour réinitialiser votre mot
          de passe.
          <br />
          <br />
          <em>Ce mail est peut-être dans vos SPAM.</em>
        </p>

        <button
          disabled={disabled}
          onClick={handleSendResetLink}
          type="button"
          className="btn btn-secondary mt-2"
        >
          RENVOYER LE MAIL
        </button>
        <button
          type="button"
          onClick={() => history.push('/login')}
          className="btn btn-outline-secondary mt-2"
        >
          SE CONNECTER
        </button>
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
