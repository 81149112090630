import React, { useState, useContext } from 'react';
import ProjectModal from '../../common/Modal/ProjectModal';
import SelectTradeModal from '../../common/Modal/SelectTradeModal';
import FavoriteButton from './FavoriteButton';
import CountTradesStatus from './CountTradesStatus';
import SubmitRequestModal from '../../common/Modal/SubmitRequestModal';
import { TopMessageContext } from '../../common/providers/TopMessageProvider';
import { useAnalytics } from '../../common/RudderStack';
import moment from 'moment';

const ProjectListElement: React.FC<{
  project: IProject;
  user: IUser;
  reload?: () => void;
  projectRequest: IProjectRequest;
  projectPublicRequest?: boolean;
  projectPublics?: number;
  onFavoriteChange?: (v: boolean) => void;
}> = ({
  project,
  user,
  projectRequest,
  projectPublicRequest,
  projectPublics,
  reload,
  onFavoriteChange,
}) => {
  const { displayTopMessage, closeTopMessage } = useContext(TopMessageContext);
  const hasProjectAccess = user.is_subscriber || projectRequest;
  const [open, setOpen] = useState<boolean>(false);
  const [openLot, setOpenLot] = useState<boolean>(false);
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [selectedTrade, setSelectedTrade] = useState<Array<string>>([]);
  const [closableModal, setClosableModal] = useState<boolean>(false);
  const analytics = useAnalytics();

  const loadBottomCrumb = () => {
    switch (projectRequest.status) {
      case 'validated':
        return (
          <>
            <div className="crumb flash-success" data-cy="flash-success">
              {projectRequest ? <CountTradesStatus projectRequest={projectRequest} /> : ''}
            </div>
          </>
        );
      case 'requested':
        return (
          <div className="crumb flash-info" data-cy="flash-info">
            {projectRequest ? <CountTradesStatus projectRequest={projectRequest} /> : ''}
          </div>
        );
      case 'refused':
        return (
          <div className="crumb flash-danger" data-cy="flash-danger">
            {projectRequest ? <CountTradesStatus projectRequest={projectRequest} /> : ''}
          </div>
        );
      default:
        return '';
    }
  };

  const loadTopCrumb = () => {
    if (project.archived_at) {
      return (
        <div className="card-ribbon archived">
          <span>Projet clôturé</span>
        </div>
      );
    } else if (project.is_new) {
      return (
        <div className="card-ribbon new">
          <span>Nouveauté</span>
        </div>
      );
    } else if (project.is_coming) {
      return (
        <div className="card-ribbon coming">
          <span>Bientôt disponible</span>
        </div>
      );
    }
    return '';
  };

  const openModal = () => {
    analytics?.track('Project Modal Opened', {
      name: project.name,
      purchaser_name: project.purchaser_name,
      zip_code: project.zip_code,
      construction_type: project.construction_type,
      begin_of_offering_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      end_of_offering_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      building_site_start_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      building_site_end_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      city: project.city,
      is_coming: project.is_coming,
      is_flash: project.is_flash,
      is_external: project.is_external,
    });

    setOpen(true);
  };

  const loadButton = () => {
    if (projectRequest) {
      return (
        <button
          id={projectRequest.status === 'validated' ? 'button-access-dce' : 'button-access-project'}
          data-cy={
            projectRequest.status === 'validated' ? 'button-access-dce' : 'button-access-project'
          }
          type="button"
          className="btn btn-secondary btn-card-consultation"
          onClick={openModal}
        >
          {projectRequest.status === 'validated' ? 'ACCÉDER AU DCE' : 'DÉTAILS PROJET'}
        </button>
      );
    }

    if (project.archived_at) {
      return (
        <button
          id="button-unavailable-project"
          data-cy="button-unavailable-project"
          type="button"
          className="btn btn-secondary btn-card-consultation"
          onClick={openModal}
          disabled={true}
        >
          PLUS DISPONIBLE
        </button>
      );
    }

    return (
      <button
        id={project.is_coming ? 'button-know-more-project' : 'button-access-project'}
        data-cy={project.is_coming ? 'button-know-more-project' : 'button-access-project'}
        type="button"
        className="btn btn-secondary btn-card-consultation"
        onClick={openModal}
      >
        {project.is_coming ? 'EN SAVOIR PLUS' : 'DÉTAILS PROJET'}
      </button>
    );
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <ProjectModal
        hidden={open}
        closeModal={closeModal}
        closableModal={() => setClosableModal(true)}
        toCall={() => setOpenLot(true)}
        projectRequest={projectRequest}
        projectPublicRequest={projectPublicRequest}
        project={project}
        projectPublics={projectPublics}
        user={user}
      />

      <SelectTradeModal
        project={project}
        hidden={openLot}
        closeModal={() => setOpenLot(false)}
        toCall={() => {
          user.is_subscriber ? reload() : setOpenRequest(true);
        }}
        closableModal={() => setClosableModal(true)}
        user={user}
        selectedTrades={selectedTrade}
        setSelectedTrades={setSelectedTrade}
        openConsultation={user.open_consultation}
      />

      <SubmitRequestModal
        project={project}
        hidden={openRequest}
        closeModal={() => setOpenRequest(false)}
        toCall={() => {
          reload();
          displayTopMessage(
            'Votre demande de projet a bien été envoyée ! Notre équipe vous recontacte dans les plus brefs délais.',
            'success'
          );
          setTimeout(function () {
            closeTopMessage();
          }, 5000);
        }}
        closableModal={() => setClosableModal(true)}
        user={user}
        selectedTrades={selectedTrade}
        openConsultation={user.open_consultation}
      />

      <div className="col-lg-4 col-sm-6 col-md-6 no-padding" style={{ minWidth: '280px' }}>
        <div className="card card-body card-consultation with-flash mt-4">
          <div style={{ position: 'relative' }}>
            {loadTopCrumb()}
            <img
              src={project.image_path}
              className="card-img-top"
              alt={`${project.name || ''}`}
              draggable={false}
            />
            {projectRequest && loadBottomCrumb()}
          </div>
          <div className="content" style={project ? { paddingTop: 10 } : {}}>
            <div className="content-top">
              <span
                className={hasProjectAccess ? 'title' : 'card-fake-title'}
                title={hasProjectAccess ? project.name : null}
                style={{ marginTop: 5 }}
              >
                {hasProjectAccess && project.name}
              </span>
              <FavoriteButton
                value={project.is_a_favorite}
                project={project}
                onValueChange={onFavoriteChange}
              />
            </div>

            <div className="description">
              <span className="description-key">Code postal</span>
              <span> : {project.zip_code ? project.zip_code : ' NC'}</span>
              <br />
              {!hasProjectAccess ? (
                <>
                  <span className="description-key">Type de construction</span>
                  <span> : {project.construction_type || ' NC'}</span>
                </>
              ) : (
                <>
                  {!project.archived_at && (
                    <>
                      {!project.is_ended && project.end_of_offering_date && (
                        <>
                          <span className="description-key">Fin de consultation</span>
                          <span> : le {project.end_of_offering_date}</span>
                        </>
                      )}
                      {!project.is_coming ? (
                        <span style={{ color: '#75C493' }}> Ouvert à la consultation</span>
                      ) : (
                        <span style={{ color: '#CEC7C7', fontWeight: 'bold' }}>
                          Bientôt ouvert à la consultation
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
              {loadButton()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectListElement;
