import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from './index';
import TradeRequestList from '../../components/projects/TradeRequestList';
/**
 * This react component is used to see the project modal.
 * This modal is used to see every information about a project.
 * @param props
 * @constructor
 */
const ProjectModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
  toCall: () => void;
  closableModal?: () => void;
  forceAccess?: boolean;
  projectRequest: IProjectRequest;
  projectPublicRequest?: boolean;
  projectPublics?: number;
  project: IProject;
  user: IUser;
}> = ({
  hidden,
  closeModal,
  toCall,
  closableModal,
  projectRequest,
  project,
  user,
  forceAccess,
}) => {
  const trades = project.trades;

  /* Trigger clicking outside event to close the modal */
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!modalRef.current.contains(event.target) && closableModal) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const hasProjectAccess = user.is_subscriber || projectRequest || forceAccess;

  const handleClose = () => {
    closeModal();
  };

  const chooseLot = () => {
    toCall();
    handleClose();
  };

  const loadTopCrumb = () => {
    if (project.is_new) {
      return (
        <div className="card-ribbon new">
          <span>Nouveauté</span>
        </div>
      );
    } else if (project.is_coming) {
      return (
        <div className="card-ribbon coming">
          <span>Bientôt disponible</span>
        </div>
      );
    }
    return '';
  };

  return (
    <Modal hidden={hidden} closeModal={closeModal} maxWidth={!projectRequest ? 600 : 750}>
      <div className="modal-body consultation-modal" style={{ border: 'none' }} ref={modalRef}>
        <div style={{ position: 'relative', marginBottom: loadTopCrumb() != '' ? 45 : 0 }}>
          {loadTopCrumb()}
          {!projectRequest && <img src={project.image_path} alt={`${project.name || ''} image`} />}
        </div>
        {projectRequest && <TradeRequestList projectRequest={projectRequest} />}
        <div className="container text-center">
          {hasProjectAccess && <h1>{project.name}</h1>}
          {user.is_subscriber && <h2>{project.purchaser_name}</h2>}

          <p className="mt-2 text-left">{project.description || 'NC'}</p>

          <div className="row">
            <div className="col-7">
              {!project.archived_at && (
                <>
                  {!project.is_ended && project.end_of_offering_date && (
                    <>
                      <span className="co-sub-detail spacing">
                        Fin d&apos;appel d&apos;offres :
                      </span>{' '}
                      le {project.end_of_offering_date}
                    </>
                  )}
                  {!project.is_coming ? (
                    <span className="co-sub-detail spacing" style={{ color: '#75C493' }}>
                      Ouvert à la consultation
                    </span>
                  ) : (
                    <span style={{ color: '#CEC7C7', fontWeight: 'bold' }}>
                      Bientôt ouvert à la consultation
                    </span>
                  )}
                  <br />
                </>
              )}
              {user.is_subscriber && (
                <>
                  <span className="co-sub-detail spacing">Début appel d&apos;offres :</span>
                  {project.begin_of_offering_date ? ` le ${project.begin_of_offering_date}` : ' NC'}
                </>
              )}
              {!user.is_subscriber && (
                <>
                  <span className="co-sub-detail spacing">Type de construction :</span>{' '}
                  {project.construction_type ?? ' NC'}
                </>
              )}
              <br />
              {user.is_subscriber && (
                <>
                  <span className="co-sub-detail">Début des travaux :</span>
                  {project.is_building_started ||
                  (!project.is_building_started && !project.building_site_start_date)
                    ? ' NC'
                    : ` le ${project.building_site_start_date}`}
                </>
              )}
              {!user.is_subscriber && (
                <>
                  <span className="co-sub-detail">Code postal :</span> {project.zip_code ?? 'NC'}
                </>
              )}
            </div>

            {user.is_subscriber && (
              <div className="col-5">
                <span className="co-sub-detail spacing">Type de construction :</span>{' '}
                {project.construction_type ?? ' NC'}
                <br />
                <span className="co-sub-detail spacing">Ville :</span>
                {project.city ?? ' NC'}
                <br />
                <span className="co-sub-detail">Code postal :</span> {project.zip_code ?? 'NC'}
                <br />
              </div>
            )}
            <div className="d-flex  flex-column">
              <span className="co-sub-detail spacing">Lots du projet : </span> <br />
              <br />
              <div className="mt-2">
                {trades &&
                  trades
                    .map((trade: ITrade) => trade.name)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .map((trade: string, index: number) => (
                      <label className="PillList-item" key={index}>
                        <span className="PillList-label">{trade}</span>
                      </label>
                    ))}
              </div>
            </div>
          </div>

          {projectRequest ? (
            <>
              {projectRequest.status === 'refused' && (
                <div className="alert alert-danger p-2 mt-5 text-center">
                  Désolé, l&apos;accès à ce projet a été refusé : le DO a déjà sélectionné son
                  partenaire.
                </div>
              )}
              {projectRequest.status === 'requested' && (
                <div className="alert alert-info p-2 mt-5 text-center">
                  L&apos;accès à ce projet est en cours de validation par le donneur d&apos;ordre.
                </div>
              )}
              {projectRequest.status === 'validated' && (
                <div className="alert alert-success p-2 mt-5 text-center">
                  {project.is_flash && (
                    <>
                      Vous allez prochainement recevoir un mail de Saqara vous permettant
                      d&apos;accéder au DCE.
                    </>
                  )}

                  {!project.is_flash && (
                    <>
                      L&apos;accès à ce projet a été accepté ! Vous allez prochainement recevoir un
                      mail de Saqara afin d’accéder au DCE.
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {!hasProjectAccess && (
                <div className="alert alert-warning p-2 mt-5 text-center">
                  Les accès complets au projet et au DCE sont réservés aux abonnés.
                  <Link className="btn btn-secondary mt-4" to="/settings/subscription">
                    Découvrir nos offres
                  </Link>
                </div>
              )}

              {hasProjectAccess && (
                <button
                  id="button-select-trades"
                  data-cy="button-select-trades"
                  className="btn btn-secondary mt-4"
                  onClick={chooseLot}
                  disabled={project.is_coming || !user.is_mail_confirmed || !project.trades.length}
                >
                  SÉLECTIONNER DES LOTS
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProjectModal;
