import React, { useContext, useEffect } from 'react';
import { UserContext } from '../providers/UserProvider';
import { Route, Redirect, RouteProps, Link } from 'react-router-dom';
import SideBar from '../navigation/SideBar';
import Footer from '../navigation/Footer';
import CircularLoader from '../navigation/CircularLoader';
import $ from 'jquery';
import { TopMessageContext } from '../providers/TopMessageProvider';
import BottomMenu from '../navigation/BottomMenu';
import { V2_URL } from '../../config/environment';
//import { SubscriptionContext } from '../providers/SubscriptionProvider';

interface PrivateRouteProps extends RouteProps {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  component?: any;
  exact?: boolean;
  path: string;
  enableWrapper?: boolean;
}

// Redirect to /login if user is not logged in
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  path,
  exact = false,
  enableWrapper = true,
}) => {
  const Component = component;
  const { user, fetched } = useContext(UserContext);
  const { displayTopMessage } = useContext(TopMessageContext);
  //const { subscription, refreshSubscription } = useContext(SubscriptionContext);

  const openBurger = () => {
    if ($('#top').css('display') !== 'none') {
      if ($('.sidebar').css('display') === 'none') {
        $('.content-app').hide();
        $('.sidebar').slideDown(500);
      } else {
        $('.sidebar').slideUp(500, () => {
          $('.content-app').show();
        });
      }
    }
  };

  const closeBurger = () => {
    $('.sidebar').slideUp(500, () => {
      $('.content-app').show();
    });
  };

  /** Display a top message if the subscription is past_due (unpaid). */
  useEffect(() => {
    window.location.href = V2_URL;
    if (enableWrapper && user?.subscription_status === 'past_due') {
      displayTopMessage(
        'Votre dernier paiement a été refusé.',
        'danger',
        false,
        '/settings/subscription',
        'Merci de vérifier vos informations de paiement.'
      );
    }

    if (enableWrapper && user?.subscription_status === 'disputed') {
      displayTopMessage('Vous avez contesté le paiement', 'danger', false);
    }
  }, [user, enableWrapper]);

  /** Display a top message about sponsorship if the user is subscriber. */
  // useEffect(() => {
  //   if (subscription && user?.subscription_status !== 'past_due') {
  //     displayTopMessage(
  //       'Nouveau : parrainez un confrère et gagnez ' +
  //         (subscription.stripe_price.name === 'annually' ? '95' : '10') +
  //         ' € sur votre abonnement ' +
  //         (subscription.stripe_price.name === 'annually' ? 'annuel' : 'mensuel') +
  //         ' !',
  //       subscription.stripe_price.name === 'annually'
  //         ? 'secondary_information'
  //         : 'primary_information',
  //       false,
  //       '/settings/sponsorship',
  //       'EN SAVOIR PLUS.'
  //     );
  //   }
  // }, [subscription]);

  /** Get the subsription if the user is subscriber. */
  // useEffect(() => {
  //   if (fetched && enableWrapper && user?.is_subscriber) {
  //     refreshSubscription();
  //   }
  // }, [fetched, enableWrapper, user]);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (!fetched) {
          return (
            <>
              <div className="container-fluid">
                <div className="container-center">
                  <div className="content">
                    <div className="content-spinner">
                      <CircularLoader />
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
            </>
          );
        }
        if (user) {
          return enableWrapper ? (
            <>
              <div id="top" className="p-4">
                <Link to="/consultation_open">
                  <img src="/images/horizontalLogo.svg" alt="Logo chantier privé par Saqara" />
                </Link>
                <div className="d-flex align-middle">
                  <a href="tel:0187666076 " className="d-flex align-middle">
                    <img
                      width={30}
                      height={30}
                      src="/Icons/phone_Circle.svg"
                      alt="Phone"
                      className="mr-3"
                    />
                  </a>
                  <img
                    width={30}
                    height={30}
                    src="/Icons/noun_User.svg"
                    alt="User"
                    onClick={() => openBurger()}
                    className={`${window.innerWidth < 995 && 'my-last-step'}`}
                  />
                </div>
              </div>

              <div className="container-fluid container-app">
                <SideBar toCall={() => openBurger()} user={user} />
                <div className="content-app">
                  <div
                    className="container-center"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Component {...props} user={user} />
                    </div>
                    <Footer />
                  </div>
                </div>
              </div>
              <BottomMenu toCall={() => closeBurger()} />
            </>
          ) : (
            <Component {...props} user={user} />
          );
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
