import React from 'react';
import Modal from './index';

/**
 * This react component is used to display the payment method
 * attached confirmation modal.
 * @param props
 */
const PaymentMethodAttachedConfirmationModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
  refresh: () => void;
}> = ({ hidden, closeModal, refresh }) => {
  return (
    <Modal hidden={hidden} maxWidth={600} closeModal={() => closeModal()} hideCloseButton={true}>
      <div className="modal-body">
        <h1>MÉTHODE DE PAIEMENT AJOUTÉE !</h1>
        <p className="mt-2">
          Votre méthode de paiement a été ajoutée et attribuée par défaut pour vos prochains
          paiements.
          <br />
          <b>
            Les changements peuvent prendre quelques minutes avant d&apos;apparaître sur votre
            espace.
          </b>
        </p>

        <button onClick={() => refresh()} className="btn btn-secondary mt-2">
          Terminer
        </button>
      </div>
    </Modal>
  );
};

export default PaymentMethodAttachedConfirmationModal;
