import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from './index';
import { Link } from 'react-router-dom';
import CircularLoader from '../../common/navigation/CircularLoader';
import { useStripe } from '@stripe/react-stripe-js';
import { useAnalytics } from '../RudderStack';
import { BACKEND_URL } from '../../config/environment';

/**
 * This react component is used to see the payment method.
 * @param props
 */
const PaymentMethodModal: React.FC<{
  subscription: ISubscription;
  hidden: boolean;
  closeModal: () => void;
  refresh?: () => void;
}> = ({ subscription, hidden, closeModal, refresh }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const [paymentMethods, setPaymentMethods] = useState<Array<IPaymentMethod>>(null);
  const analytics = useAnalytics();

  useEffect(() => {
    if (!paymentMethods) {
      setLoading(true);
      axios
        .get<IPaginatePaymentMethod>(`${BACKEND_URL}/api/stripe/payment-methods`)
        .then(({ data }) => {
          setLoading(false);
          setPaymentMethods(data.payment_methods);
        });
    }
  }, [paymentMethods]);

  /**
   * Get payment method name
   */
  const getPaymentMethodName = (paymentMethod: IPaymentMethod) => {
    return paymentMethod.type == 'card'
      ? paymentMethod.data.brand.charAt(0).toUpperCase() + paymentMethod.data.brand.slice(1)
      : 'SEPA';
  };

  /**
   * Add PaymentMethod
   */
  const addPaymentMethod = () => {
    setLoading(true);
    axios.post(`${BACKEND_URL}/api/stripe/add-payment-method`).then(({ data }) => {
      console.log(data)
      stripe.redirectToCheckout({ sessionId: data.checkout_session_id }).then((result) => {
        analytics?.track('Payment Method Added');
        if (result.error !== undefined && result.error.message !== undefined) {
          setLoading(true);
        }
      });
    });
  };

  /**
   * Delete PaymentMethod
   */
  const deletePaymentMethod = (paymentMethod: string) => {
    if (confirm('Attention: Cette action est irréversible. Êtes vous sûr(e)?')) {
      setLoading(true);
      axios
        .delete(`${BACKEND_URL}/api/stripe/payment-method`, {
          params: { payment_method_id: paymentMethod },
        })
        .then(() => {
          analytics?.track('Payment Method Deleted');
          setPaymentMethods(null);
          setLoading(false);
        });
    }
  };

  /**
   * Set defaultPaymentMethod
   */
  const defaultPaymentMethod = (paymentMethod: string) => {
    if (
      confirm('Attention: Votre méthode de paiement par défaut va être changée. Êtes vous sûr(e)?')
    ) {
      setLoading(true);
      axios
        .post(`${BACKEND_URL}/api/stripe/default-payment-method`, {
          payment_method_id: paymentMethod,
        })
        .then(() => {
          setPaymentMethods(null);
          setLoading(false);
          if (refresh) {
            refresh();
          }
        });
    }
  };

  return (
    <>
      <Modal hidden={hidden} maxWidth={737} closeModal={closeModal}>
        <div className="modal-body">
          <h1 className="card-payment-title">GÉRER VOS MOYENS DE PAIEMENT</h1>
          {loading && <CircularLoader />}
          {!loading && (
            <div className="row my-4">
              <div className="col-12 card-payment">
                <div className="card">
                  <h2 className="card-header">Vos modes de paiement</h2>
                  {paymentMethods &&
                    paymentMethods.map((paymentMethod) => (
                      <div className="card-payment-border" key={paymentMethod.payment_method_id}>
                        <div className="row">
                          <div className="col-lg-3 type-payment">
                            {paymentMethod.type == 'card' ? (
                              paymentMethod.data.brand == 'visa' ? (
                                <img src="/images/visalogo.png" alt="visa" />
                              ) : paymentMethod.data.brand == 'mastercard' ? (
                                <img src="/images/mastercardlogo.png" alt="MasterCard" />
                              ) : (
                                <img src="/images/credit-card.png" alt="CreditCard" />
                              )
                            ) : (
                              <img src="/images/sepa.png" alt="sepa" />
                            )}
                          </div>
                          <div className="col-lg-7">
                            <p className="text-left">
                              <b>
                                {getPaymentMethodName(paymentMethod)} se terminant par ****
                                {paymentMethod.data.last4}
                              </b>
                            </p>
                            <p className="text-left">
                              {paymentMethod.type == 'card'
                                ? 'Arrive à expiration ' +
                                  paymentMethod.data.exp_month +
                                  ' /' +
                                  paymentMethod.data.exp_year +
                                  ' | ' +
                                  paymentMethod.data.name
                                : paymentMethod.data.name}
                            </p>
                            {!paymentMethod.is_default_payment_method && (
                              <p className="text-center">
                                <Link
                                  to="#"
                                  onClick={() =>
                                    deletePaymentMethod(paymentMethod.payment_method_id)
                                  }
                                  className="link-edit-payment"
                                >
                                  Supprimer
                                </Link>
                              </p>
                            )}
                          </div>
                          <div className="col-lg-2 payment-select">
                            <input
                              type="radio"
                              id={paymentMethod.payment_method_id}
                              data-cy={paymentMethod.payment_method_id}
                              value={paymentMethod.payment_method_id}
                              onChange={(e) => defaultPaymentMethod(e.target.value)}
                              checked={paymentMethod.is_default_payment_method ? true : false}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  <Link to="#" onClick={() => addPaymentMethod()} className="add-payment">
                    <span className="icon-plus"></span>
                    Ajouter un autre moyen de paiement
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="row my-4">
            <div className="col-12 card-payment">
              <div className="card">
                <h2 className="card-header">Informations abonnement</h2>
                <div className="row">
                  <div className="card-body subscription-body col-sm-8" style={{ paddingLeft: 30 }}>
                    <p>
                      <b>Abonnement {subscription.stripe_price.display_name} Chantier Privé</b>
                    </p>
                    <p>Abonné depuis le {subscription.current_period_start}</p>

                    {subscription.ends_at ? (
                      <p>Jusqu&apos;au {subscription.current_period_end} (Non renouvelé)</p>
                    ) : (
                      <p>
                        <b>{subscription.next_recurrence_amount}€HT</b> seront prélevés le{' '}
                        <b>{subscription.current_period_end}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentMethodModal;
