import React from 'react';

const Question: React.FC<{ question: IFaqQuestion }> = ({ question }) => {
  const parseAnswer = (question: IFaqQuestion) => {
    return { __html: question.answer_markdown };
  };

  return (
    <div className="col-md-8">
      <hr className="border-top hr-col-md-8" />
      <h3 className="question">
        <b>{question.question}</b>
      </h3>
      <br />
      <div dangerouslySetInnerHTML={parseAnswer(question)} />
    </div>
  );
};

export default Question;
