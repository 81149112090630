import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactModal from '../Modal/ContactModal';

/**
 * This react component is used to display a box to contact the admin
 * of Chantier Privé.
 * This box open a the ContactModal.
 * @constructor
 */
const BoxContactSupport: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div className="alert alert-red d-flex justify-content-between">
        <div>
          <div className="icon-danger"></div>
          <div className="text">
            Si vous rencontrez des difficultés, n&apos;hésitez pas à contacter l&apos;équipe de
            Chantier Privé.
          </div>
        </div>
        <Link to="#" onClick={() => setOpen(true)}>
          CONTACTER LE SUPPORT
        </Link>
      </div>
      <ContactModal hidden={open} closeModal={() => setOpen(false)} />
    </>
  );
};

export default BoxContactSupport;
