import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import QuestionsList from './QuestionList';
import Question from './Question';
import TitleIconed from '../../../common/navigation/TitleIconed';
import Phone from '../../../common/navigation/Phone';
import UserMenu from '../../../common/navigation/UserMenu';
import { BACKEND_URL } from '../../../config/environment';
import Banner from '../../banner/Banner';

const CategoryList: React.FC<{ user: IUser }> = ({ user }) => {
  const history = useHistory();
  const { categorySlug, subCategorySlug, questionSlug } = useParams<{
    categorySlug: string;
    subCategorySlug: string;
    questionSlug: string;
  }>();

  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [question, setQuestion] = useState(null);

  /**
   *  Get the categories when categorySlug from url change
   */
  useEffect(() => {
    axios.get(`${BACKEND_URL}/api/public/faq-categories`).then(({ data }) => {
      setCategories(data.categories);
    });
  }, [categorySlug]);

  //Handle the category slug in url
  useEffect(() => {
    if (categories) {
      const category = getCategory();

      if (category) {
        setCategory(getCategory());
        setSubCategory(null);
      } else {
        history.push(`/settings/faq`);
      }
    }
  }, [categories, categorySlug]);

  //Handle the subCategory slug in url
  useEffect(() => {
    if (category && category.sub_categories) {
      const subCategory = getSubCategory() ?? category.sub_categories[0];
      setSubCategory(subCategory);
      setQuestion(null);
    }
  }, [category, questionSlug, subCategorySlug]);

  //Handle the question slug in url
  useEffect(() => {
    if (subCategory && subCategory.faq_questions) {
      const question = getQuestion();
      if (question) {
        setQuestion(question);
      } else if (questionSlug) {
        history.push(`/settings/faq/${category.slug}/${subCategory.slug}`);
      }
    }
  }, [subCategory, questionSlug]);

  const getCategory = (): IFaqCategory => {
    return categories.find((category: IFaqCategory) => category.slug === categorySlug);
  };

  const getSubCategory = (): IFaqCategory => {
    return category.sub_categories.find(
      (subCategory: IFaqCategory) => subCategory.slug === subCategorySlug
    );
  };

  const getQuestion = (): IFaqCategory => {
    return subCategory.faq_questions.find(
      (question: IFaqQuestion) => question.slug === questionSlug
    );
  };

  const trim = (string: string, length: number) => {
    return string.length > length ? `${string.substring(0, length)}...` : string;
  };

  return (
    <>
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="noun_filter_activated" text="Centre d'Aide" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description">
        Posez ici toutes vos questions et obtenez rapidement des réponses de notre part ou de la
        communauté.
      </p>
      <Banner />
      <div className="category-cards-wrapper mt-5">
        <div className="card">
          {category && subCategory && (
            <div>
              <h2 className="card-header">
                <Link to="/settings/faq">{category && category.title}</Link>
              </h2>

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{ backgroundColor: 'inherit' }}>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to="/settings/faq" className="text-dark">
                      <b className="no-uppercase underline">{"Centre d'aide"}</b>
                    </Link>
                  </li>

                  <li className="breadcrumb-item" aria-current="page">
                    <Link
                      to={`/settings/faq/${category.slug}/${subCategory.slug}`}
                      className="text-dark"
                    >
                      <span className="no-uppercase">
                        {!question ? subCategory.title : <b>{subCategory.title}</b>}
                      </span>
                    </Link>
                  </li>

                  <li className="breadcrumb-item" aria-current="page" hidden={!question}>
                    {question && trim(question.question, 40)}
                  </li>
                </ol>
              </nav>

              <hr className="border-top" />

              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    {category &&
                      category.sub_categories.map(
                        (subCat: IFaqCategory, index: number) =>
                          subCat.is_shown && (
                            <p key={index}>
                              <Link
                                to={`/settings/faq/${category.slug}/${subCat.slug}`}
                                className={subCat.id === subCategory?.id ? 'category-active' : ''}
                              >
                                <span className="faq-marker" />
                                {subCat.title}
                              </Link>
                            </p>
                          )
                      )}
                  </div>

                  {question ? (
                    <Question question={question} />
                  ) : (
                    subCategory && <QuestionsList category={category} subCategory={subCategory} />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryList;
