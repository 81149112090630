import React, { useState } from 'react';

/**
 * This react component is used to display the support phone.
 */
const Phone = () => {
  const [showPhone, setShowPhone] = useState(false);

  return (
    <>
      <button
        className={'w-auto btn phone' + (showPhone ? '' : '-no')}
        onMouseLeave={() => setShowPhone(false)}
        onMouseOver={() => setShowPhone(true)}
      >
        <a href="tel:0187666076 ">
          <img
            width="32px"
            height="32px"
            className={'' + (showPhone ? 'mr-3' : '')}
            src={'/Icons/' + (showPhone ? 'noun_Phone_dark.svg' : 'phone_Circle.svg')}
            alt="Tel"
          />
        </a>
        {showPhone && (
          <a href="tel:0187666076 " className="phone">
            01 87 66 60 76
          </a>
        )}
      </button>
    </>
  );
};

export default Phone;
