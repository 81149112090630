import React, { useEffect, useState } from 'react';
import { extendStates } from '../../components/settings/preferences/getStates';
import StatesDropdown from './StatesDropdown';
import ConstructionTypesDropdown from './ConstructionTypesDropdown';
import axios from 'axios';
import { originalConstructionTypeList } from '../../components/settings/preferences/ConstructionTypeEdit';
import TradesCategoriesDropdown from './TradesCategoriesDropdown';
import { BACKEND_URL } from '../../config/environment';

const BoxResearch: React.FC<{
  preferences: IPreference;
  refreshProjects: (
    stateFilters: IState[],
    constructionTypeFilters: string[],
    tradesCategoriesFilters: ITrades[]
  ) => void;
}> = ({ refreshProjects, preferences }) => {
  const [states, setStates] = useState<IState[]>(preferences.states);
  const [nearbyStates, setNearbyStates] = useState<boolean>(preferences.extend_to_nearby_states);
  const [constructionTypes, setConstructionTypes] = useState<string[]>(
    preferences.construction_types
  );
  const [tradeCategories, setTradeCategories] = useState<ITrades[]>(
    preferences.trade_category_references
  );
  const [disbaledTypes, setDisabledTypes] = useState<Array<string>>([]);
  const [disabledLoaded, setDisabledLoaded] = useState<boolean>(false);

  const onStatesChange = (states: Array<IState>, extend: boolean) => {
    setStates(states);
    setNearbyStates(extend);
  };
  const onTradesCategoriesChange = (tradesCategories: Array<ITrades>) => {
    setTradeCategories(tradesCategories);
  };

  const search = () => {
    const filteredStates = nearbyStates ? extendStates(states) : states;

    refreshProjects(filteredStates, constructionTypes, tradeCategories);
  };

  useEffect(() => {
    // Retrieve all available construction types
    axios.get(`${BACKEND_URL}/api/projects/construction-types`).then(({ data }): void => {
      // Get the disabled types
      const allDisabledTypes = originalConstructionTypeList.filter(
        (t) => !data.available.includes(t)
      );
      setDisabledTypes(allDisabledTypes);
      setDisabledLoaded(true);

      // Intersect the pre-selected types (from criterias) with the available types
      const allPreSelectedTypes = constructionTypes.filter((t) => data.available.includes(t));
      setConstructionTypes(allPreSelectedTypes);
    });
  }, []);

  useEffect(() => {
    if (disabledLoaded) {
      search();
    }
  }, [states, nearbyStates, constructionTypes, tradeCategories]);

  return (
    <>
      <div className="search-card">
        <div className="col-lg-4 col-md-3 mt-2 mb-2 col-separator">
          <StatesDropdown states={states} nearbyStates={nearbyStates} onChange={onStatesChange} />
        </div>

        <div className="col-lg-4 col-md-4 mt-2 mb-2 col-separator my-second-step">
          <TradesCategoriesDropdown
            tradeCategories={tradeCategories}
            onChange={onTradesCategoriesChange}
          />
        </div>

        <div className="col-lg-4 col-md-4 mt-2 mb-2 col-separator">
          <ConstructionTypesDropdown
            types={constructionTypes}
            disabled={disbaledTypes}
            onChange={(t) => setConstructionTypes(t)}
          />
        </div>
      </div>
    </>
  );
};

export default BoxResearch;
