import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useAnalytics } from '../../common/RudderStack';

const ProjectRequested: React.FC<{ user: IUser }> = () => {
  const history = useHistory();
  const analytics = useAnalytics();

  const settingSubscription = () => {
    analytics?.track('Free Subscription');
    history.push('/settings/subscription');
  };
  return (
    <div className="project-request-box">
      <div className="project-request ml-0">
        <h2>Abonnez-vous à Chantier Privé !</h2>

        <p>
          Pour accèder à l’ensemble de nos projets, il vous suffit de vous abonner à Chantier Privé.
          Cliquez sur le bouton ci-dessous. Si vous souhaitez en savoir plus contactez-nous au{' '}
          <span className="orange"> 0187666076 </span> ou sur{' '}
          <span className="orange">contact@chantierprive.fr</span>.
        </p>
        <div className="col-md-4 mt-4">
          <Link to="#" onClick={() => settingSubscription()} className="btn btn-secondary">
            S&apos;abonner
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectRequested;
