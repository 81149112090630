import React, { useEffect, useState } from 'react';
import Checkbox from '../../../common/navigation/Checkbox';
import getStates from './getStates';

const LocalisationEdit: React.FC<{
  states: Array<IState>;
  updateStates: (state: IState) => void;
  updateAllStates: (stateList: Array<IState>) => void;
  background?: string;
}> = ({ states, updateStates, updateAllStates, background = 'white' }) => {
  const originalStateList = getStates();
  const [stateList, setStateList] = useState<Array<IState>>(originalStateList);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (search !== '') {
      setStateList(
        stateList.filter(
          (value) =>
            value.name.toLowerCase().includes(search.toLowerCase()) || value.number.includes(search)
        )
      );
    } else setStateList(originalStateList);
  }, [states, search]);

  const handleUpdateAllStates = () => {
    if (states.length === stateList.length) {
      updateAllStates([]);
    } else {
      updateAllStates(stateList);
    }
  };

  return (
    <>
      <input
        type="text"
        placeholder="Rechercher un département..."
        className="form-control"
        onChange={(event) => setSearch(event.target.value)}
      />
      <div
        className="multi-checkboxes profile mt-2"
        style={{ height: '225px', overflowY: 'scroll', backgroundColor: background }}
      >
        {search === '' && (
          <Checkbox
            id="all-states-checkbox"
            data-cy="all-states-checkbox"
            onChange={() => handleUpdateAllStates()}
            checked={states.length === stateList.length}
            label={'Tous les départements'}
          />
        )}
        {stateList.length > 0 ? (
          stateList.map((state) => (
            <Checkbox
              key={state.number}
              id={state.number}
              data-cy={state.number}
              onChange={() => updateStates(state)}
              checked={states.some((item) => item.number === state.number)}
              label={`${state.name} (${state.number})`}
            />
          ))
        ) : (
          <i className="sub-description">Aucun département ne correspond.</i>
        )}
      </div>
    </>
  );
};

export default LocalisationEdit;
