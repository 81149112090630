import React, { useEffect } from 'react';
import { Redirect } from 'react-router';

const JoinPlatform: React.FC = () => {
  useEffect(() => {
    window.localStorage.setItem('invitation_to_register', 'true');
  }, []);

  return <Redirect to="/register" />;
};

export default JoinPlatform;
