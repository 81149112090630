import React, { useState } from 'react';

const TradeRequestList: React.FC<{ projectRequest: IProjectRequest }> = ({ projectRequest }) => {
  const [selected, setSelected] = useState<number>(null);
  const countValidatedRequest = () => {
    const countValidated = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'validated'
    );
    return countValidated.length;
  };

  const countRequested = () => {
    const countRequested = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'requested'
    );
    return countRequested.length;
  };

  const countRefused = () => {
    const countRefused = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'refused'
    );
    return countRefused.length;
  };

  return (
    <>
      <div
        className={`${
          projectRequest.status === 'requested'
            ? 'requested'
            : projectRequest.status === 'refused'
            ? 'all-refused'
            : ''
        } card-header-trade `}
      >
        {countRequested() != 0 && !countValidatedRequest() ? (
          <span>
            {countRequested()} {countRequested() > 1 ? 'lots demandés' : 'lot demandé'} en attente
          </span>
        ) : countRefused() == projectRequest.request_trades.length ? (
          <span>
            {projectRequest.request_trades.length}{' '}
            {countRefused() > 1 ? 'lots refusés' : 'lot refusé'}
          </span>
        ) : (
          <span>
            {countValidatedRequest()} {countValidatedRequest() > 1 ? 'lots' : 'lot'} sur{' '}
            {projectRequest.request_trades.length}{' '}
            {projectRequest.request_trades.length > 1 ? 'acceptés' : 'accepté'}
          </span>
        )}
      </div>

      <div className="trades row accordion-trades">
        <div
          className="accordion-element trade-requested col-lg-4 pl-4 pt-4"
          id="scrollbar"
          style={{ maxHeight: '250px', overflow: 'auto' }}
        >
          <p
            className={`trade-status accordion-header ${selected === 1 ? 'show' : ''}`}
            onClick={() => (selected === 1 ? setSelected(null) : setSelected(1))}
          >
            Lots en attente ({countRequested()})
          </p>
          <ul className="accordion-body request-trade-list">
            {countRequested() > 0 &&
              projectRequest.request_trades.map((requestTrade, index) => {
                const slice = requestTrade.trade.slice ? `(${requestTrade.trade.slice})` : '';
                return (
                  requestTrade.status === 'requested' && (
                    <li className="request-trade" key={`requested-trade-${index}`}>
                      <div className="activated">
                        <img
                          src="/Icons/requested-waiting.svg"
                          className="pb-1"
                          alt="check circle"
                          style={{ width: '14px' }}
                        />
                        <span className="request-trade-name pl-2">{`${requestTrade.trade.name} ${slice}`}</span>
                      </div>
                    </li>
                  )
                );
              })}
          </ul>
        </div>

        <div
          className="accordion-element trade-accpeted col-lg-4 pl-4 pt-4"
          id="scrollbar"
          style={{ maxHeight: '250px', overflow: 'auto' }}
        >
          <p
            className={`trade-status accordion-header ${selected === 2 ? 'show' : ''}`}
            onClick={() => (selected === 2 ? setSelected(null) : setSelected(2))}
          >
            Lots acceptés ({countValidatedRequest()})
          </p>
          <ul className="accordion-body request-trade-list">
            {projectRequest.request_trades.map((requestTrade, index) => {
              const slice = requestTrade.trade.slice ? `(${requestTrade.trade.slice})` : '';
              return (
                requestTrade.status === 'validated' && (
                  <li className="accepted-trade" key={`validated-trade-${index}`}>
                    <div className="activated">
                      <img
                        src="/Icons/check-circle.svg"
                        alt="check circle"
                        style={{ width: '14px' }}
                      />
                      <span className="request-trade-name pl-2">{`${requestTrade.trade.name} ${slice}`}</span>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
        </div>
        <div
          className="accordion-element col-lg-4 pl-4 pt-4"
          id="scrollbar"
          style={{ maxHeight: '250px', overflow: 'auto' }}
        >
          <p
            className={`trade-status accordion-header ${selected === 3 ? 'show' : ''}`}
            onClick={() => (selected === 3 ? setSelected(null) : setSelected(3))}
          >
            Lots refusés ({countRefused()})
          </p>
          <ul className="accordion-body request-trade-list">
            {projectRequest.request_trades.map((requestTrade, index) => {
                const slice = requestTrade.trade.slice ? `(${requestTrade.trade.slice})` : '';
              return (
                requestTrade.status === 'refused' && (
                  <li className="refused-trade" key={`refused-trade-${index}`}>
                    <div className="refused">
                      <img
                        src="/Icons/cross-circle.svg"
                        alt="check circle"
                        style={{ width: '14px' }}
                      />
                      <span className="request-trade-name pl-2">{`${requestTrade.trade.name} ${slice}`}</span>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TradeRequestList;
