import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TitleIconed from '../../common/navigation/TitleIconed';
import BoxContactSupport from '../../common/navigation/BoxContactSupport';
import CircularLoader from '../../common/navigation/CircularLoader';
import ConsultationListElement from './ProjectListElement';
import Pagination from '../../common/navigation/Pagination';
import Toast from '../../common/navigation/Toast';
import Phone from '../../common/navigation/Phone';
import UserMenu from '../../common/navigation/UserMenu';
import { BACKEND_URL } from '../../config/environment';
import Banner from '../banner/Banner';

const ProjectRequestList: React.FC<{
  user: IUser;
}> = ({ user }) => {
  const [projectRequests, setProjectRequests] = useState<Array<IProjectRequest>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [hiddenToast, setHiddenToast] = useState<boolean>(true);
  const [toastMsg, setToastMsg] = useState<string>(null);

  const fetchPage = (page: number) => {
    setIsLoading(true);
    setCurrentPage(page);

    axios
      .get<IPaginateProjectRequest>(`${BACKEND_URL}/api/project-requests?page=${page}`)
      .then(({ data }) => {
        setProjectRequests(data.projectRequests.data);
        setLastPage(data.projectRequests.last_page);
      })
      .finally(() => setIsLoading(false));
  };

  const updateProject = (project: IProject) => {
    setProjectRequests((list) =>
      list.map((request) =>
        request.project_id === project.id
          ? {
              ...request,
              project,
            }
          : request
      )
    );
  };

  /**
   * ProjectListElement callback after a request was sent
   * of whether adding or removing project from favorites
   *
   * @param value Project is a favorite
   * @param projectId Project Id
   */
  const onFavoriteChange = (value: boolean, projectId: string): void => {
    const request = projectRequests.find((request) => request.project_id === projectId);
    const { project } = request;

    updateProject({
      ...project,
      is_a_favorite: value,
    });

    setToastMsg(
      value
        ? 'Le projet a bien été ajouté aux favoris.'
        : 'Le projet a bien été retiré des favoris.'
    );
    setHiddenToast(false);
    setTimeout(() => {
      setHiddenToast(true);
    }, 3 * 1000);
  };

  useEffect(() => {
    fetchPage(1);
  }, []);

  return (
    <>
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="noun_update_activated" text="Accès demandés" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description">
        Retrouvez ici la liste de toutes vos demandes d&apos;accès aux projets.
      </p>

      <Banner />

      {!isLoading && projectRequests && !projectRequests.length && <BoxContactSupport />}

      {isLoading && <CircularLoader />}

      {projectRequests.length > 0 && (
        <>
          <div className="row">
            {!isLoading &&
              projectRequests.map((projectRequest: IProjectRequest) => (
                <ConsultationListElement
                  key={projectRequest.id}
                  project={projectRequest.project}
                  projectRequest={projectRequest}
                  user={user}
                  reload={() => fetchPage(currentPage)}
                  onFavoriteChange={(value: boolean) =>
                    onFavoriteChange(value, projectRequest.project.id)
                  }
                />
              ))}
          </div>
          <Pagination
            lastPage={lastPage}
            currentPage={currentPage}
            toCall={(page) => fetchPage(page)}
          />
        </>
      )}

      {!isLoading && projectRequests.length === 0 && (
        <div className="alert alert-info" style={{ borderRadius: '20px' }}>
          Désolé, aucun projet n&apos;a été trouvé avec vos critères de recherche.
        </div>
      )}
      <Toast
        title="Chantier Privé"
        message={toastMsg}
        hidden={hiddenToast}
        onClick={() => setHiddenToast(true)}
        top={true}
        fixedPosition={true}
      />
    </>
  );
};

export default ProjectRequestList;
