import React, { useEffect } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CircularLoader from '../../common/navigation/CircularLoader';
import { BACKEND_URL } from '../../config/environment';
import { useAnalytics } from '../../common/RudderStack';
import * as Sentry from '@sentry/react';

const SubscriptionPaymentSuccess: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (!query || !query.price_name) history.push('/settings/subscription?success=false');
    else {
      axios
        .post(`${BACKEND_URL}/api/stripe/subscription`, {
          price_name: query.price_name,
          promotion_code: query.promotion_code,
          referral_code: query.referral_code,
        })
        .then(({ data }) => {
          const stripePrice: IStripePrice = data.stripe_price;

          analytics?.track('Order Completed', {
            revenue: stripePrice.price_excl_tax,
            currency: 'EUR',
            product_id: stripePrice.id,
            name: stripePrice.display_name,
            price: stripePrice.price_excl_tax,
            quantity: 1,
          });

          history.push('/settings/subscription?success=true');
        })
        .catch((err: any) => {
          Sentry.captureMessage(JSON.stringify(err));

          history.push('/settings/subscription?success=false');
        });
    }
  }, []);

  return (
    <>
      <CircularLoader />
    </>
  );
};

export default SubscriptionPaymentSuccess;
