import React from 'react';
import Modal from './index';

/**
 * This react component is used to display the failure method
 * @param props
 */
const SubscriptionFailureModal: React.FC<{ hidden: boolean; closeModal: () => void }> = (props) => {
  return (
    <Modal hidden={props.hidden} maxWidth={600} closeModal={props.closeModal}>
      <div className="modal-body">
        <h1>VOTRE PAIEMENT N&apos;A PAS ABOUTI !</h1>
        <p className="mt-2">
          Votre abonnement n&apos;a pas été effectué car votre paiement n&apos;a pas abouti.
          <br />
          Si le problème persiste, contactez le support.
        </p>

        <button onClick={() => props.closeModal()} className="btn btn-secondary mt-2">
          RÉESSAYEZ
        </button>
      </div>
    </Modal>
  );
};

export default SubscriptionFailureModal;
