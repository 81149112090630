import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import LoginCarousel from './LoginCarousel';
import Button from '../../common/navigation/Button';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { BACKEND_URL } from '../../config/environment';

export default function RecoveryPassword() {
  const { token, email }: { token: string; email: string } = useParams();

  const history = useHistory();
  const [error, setError] = useState<string>(null);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const loadRequest = () => {
    axios.get(`${BACKEND_URL}/api/recovery-password/${token}/${email}`).catch(() => {
      history.push('/reset-password');
    });
  };

  const changePassword = () => {
    setError(null);

    if (password && passwordConfirmation) {
      if (password !== passwordConfirmation) {
        setError("'Les mots de passe doivent être identiques.'");
      }
      setLoading(true);
      axios
        .post(`${BACKEND_URL}/api/recovery-password/${token}/${email}`, {
          password,
          password_confirmation: passwordConfirmation,
        })
        .then(() => {
          history.push('/login?success=true');
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setError(error ? error : 'Une erreur est survenue.');
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    loadRequest();
  }, []);

  return (
    <>
      <div className="container height-100">
        <div className="d-flex align-items-center row height-100">
          <div className="col-lg-6">
            <div className="card card-body card-auth mt-3">
              <img
                src="/images/logo_saqara.png"
                className="logo"
                alt="Logo chantier privé par Saqara"
              />

              <p className="mt-4">Veuillez ici réinitialiser votre mot de passe :</p>

              {error && <ErrorMessage text={error} />}

              <input
                type="password"
                className="form-control"
                placeholder="Nouveau mot de passe"
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="password"
                className="form-control"
                placeholder="Nouveau mot de passe à nouveau"
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />

              <Button
                text="Confirmer mon mot de passe"
                action={() => changePassword()}
                color="btn-secondary mt-3"
                loading={loading}
                disabled={!password || !passwordConfirmation}
              />
            </div>
          </div>
          <div className="col-lg-6 pl-5">
            <LoginCarousel />
          </div>
        </div>
      </div>
    </>
  );
}
