import React, { useState } from 'react';
import axios from 'axios';
import TitleIconed from '../../common/navigation/TitleIconed';
import SwapSubscriptionModal from '../../common/Modal/SwapSubscriptionModal';
import SubscriptionResumeConfirmationModal from '../../common/Modal/SubscriptionResumeConfirmationModal';
import CancelSubscriptionModal from '../../common/Modal/CancelSubscriptionModal';

import CircularLoader from '../../common/navigation/CircularLoader';
import PaymentMethodModal from '../../common/Modal/PaymentMethodModal';
import Phone from '../../common/navigation/Phone';
import UserMenu from '../../common/navigation/UserMenu';
import { BACKEND_URL } from '../../config/environment';
import Banner from '../banner/Banner';

const SubscriptionInformation: React.FC<{
  subscription: ISubscription;
  user: IUser;
  refreshSubscription: () => Promise<void>;
}> = ({ subscription, refreshSubscription, user }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openSubscriptionResume, setOpenSubscriptionResume] = useState<boolean>(false);
  const [openCancelSubscription, setOpenCancelSubscription] = useState<boolean>(false);

  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const [loadingResume, setLoadingResume] = useState<boolean>(false);

  const resumeSubscription = () => {
    setLoadingResume(true);
    axios
      .post(`${BACKEND_URL}/api/stripe/resume-subscription`)
      .then(() => {
        setOpenSubscriptionResume(true);
      })
      .finally(() => {
        setLoadingResume(false);
      });
  };

  /**
   * Get payment method name
   */
  const getPaymentMethodName = () => {
    return subscription.payment_method.type == 'card'
      ? subscription.payment_method.data.brand.charAt(0).toUpperCase() +
          subscription.payment_method.data.brand.slice(1)
      : 'SEPA';
  };

  /**
   * Get reccurency.
   *
   * @returns {string}
   */
  const getReccurency = () => {
    switch (subscription.stripe_price.name) {
      case 'monthly':
        return 'mois';
      case 'quarterly':
        return 'trimestre';
      case 'annually':
        return 'an';
    }
  };

  return (
    <div>
      <SubscriptionResumeConfirmationModal
        hidden={openSubscriptionResume}
        closeModal={() => setOpenSubscriptionResume(false)}
        refresh={() => refreshSubscription()}
      />
      <SwapSubscriptionModal
        subscription={subscription}
        hidden={open}
        priceName={subscription.stripe_price.name}
        closeModal={() => setOpen(false)}
        refresh={() => refreshSubscription()}
      />
      <PaymentMethodModal
        subscription={subscription}
        hidden={openPayment}
        closeModal={() => setOpenPayment(false)}
        refresh={() => refreshSubscription()}
      />
      <CancelSubscriptionModal
        hidden={openCancelSubscription}
        closeModal={() => setOpenCancelSubscription(false)}
      />
      <div className="d-flex">
        <div className="p-2 w-75">
          <TitleIconed icon="noun_filter_activated" text="GÉRER VOTRE ABONNEMENT" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description">
        Gérez ici votre abonnement, vos moyens de paiements et vos historiques de facturation.
      </p>
      <Banner />
      <div className="row my-4">
        <div className="col-12">
          {loadingResume && <CircularLoader />}
          {!loadingResume && (
            <div className="card card-body">
              <h2 className="title-card-option title-subscription">Informations abonnement</h2>
              <div className="row">
                <div className="card-body subscription-body col-sm-7 col-lg-7 col-xl-8">
                  <p>
                    <b>Abonnement {subscription.stripe_price.display_name} Chantier Privé</b>
                  </p>
                  <p>Abonné depuis le {subscription.current_period_start}</p>

                  {subscription.ends_at ? (
                    <p>Jusqu&apos;au {subscription.current_period_end} (Non renouvelé)</p>
                  ) : (
                    <p>Renouvellement automatique le {subscription.current_period_end}</p>
                  )}
                </div>

                {subscription.ends_at && (
                  <div className="card-body button-body col-sm-12 col-lg-5 col-xl-4 text-center">
                    <button
                      className="btn subscription btn-primary"
                      id="Switch-to-annual-subscription"
                      data-cy="switch-to-annual-subscription"
                      onClick={() => resumeSubscription()}
                    >
                      Reprendre l&apos;abonnement
                    </button>
                  </div>
                )}

                {!subscription.ends_at &&
                  (subscription.stripe_price.name === 'monthly' ||
                    subscription.stripe_price.name === 'quarterly') && (
                    <div className="card-body button-body col-sm-12 col-lg-5 col-xl-4 text-center">
                      <button
                        className="btn subscription btn-primary"
                        id="Switch-to-annual-subscription"
                        data-cy="switch-to-annual-subscription"
                        onClick={() => setOpen(true)}
                      >
                        Modifier votre abonnement
                      </button>
                    </div>
                  )}
                {!subscription.ends_at && subscription.stripe_price.name === 'annually' && (
                  <div className="card-body button-body col-sm-12 col-lg-5 col-xl-4 text-center">
                    <button
                      className="btn subscription btn-primary"
                      id="Switch-to-annual-subscription"
                      data-cy="switch-to-annual-subscription"
                      onClick={() => setOpenCancelSubscription(true)}
                    >
                      Modifier votre abonnement
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12">
          <div className="card card-body">
            <h2 className="title-card-option title-subscription">Informations de paiement</h2>
            <div className="row">
              <div className="card-body subscription-body col-sm-6 col-lg-7 col-xl-8">
                {subscription.ends_at ? (
                  <b>Abonnement annulé</b>
                ) : (
                  <div>
                    <p>
                      {subscription.stripe_price.price_excl_tax}€HT/
                      {getReccurency()}
                    </p>
                    <p>
                      <b>{subscription.next_recurrence_amount}€HT</b> seront prélevés le{' '}
                      <b>{subscription.current_period_end}</b>
                    </p>
                    {subscription.payment_method.type ? (
                      <p>
                        {getPaymentMethodName()} se terminant par ****
                        {subscription.payment_method.data.last4}
                      </p>
                    ) : (
                      <p className="text-danger">
                        Vous n&apos;avez aucune méthode de paiement, merci d&apos;en ajouter une.
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="card-body button-body col-sm-4 col-lg-5 col-xl-4 text-center">
                <button
                  className="btn payment btn-primary"
                  id="manage-payment"
                  data-cy="manage-payment"
                  onClick={() => setOpenPayment(true)}
                >
                  GÉRER VOS MOYENS DE PAIEMENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInformation;
