import React from 'react';
import Modal from './index';
import Button from '../../common/navigation/Button';
import { useHistory } from 'react-router-dom';
/**
 * This react component is used to swap subscription to annual.
 * @param props
 */
const SuccessPriceRequestModal: React.FC<{
  closeModal: () => void;
  hidden: boolean;
}> = ({ closeModal, hidden }) => {
  const history = useHistory();

  const confirmModal = () => {
    history.push('/consultation_open');
  };

  return (
    <Modal hidden={hidden} maxWidth={600} closeModal={() => closeModal()}>
      <div className="card">
        <div className="card-body p-lg-3">
          <h2 className="title-card-option mb-4">Merci !</h2>

          <p className="text-center">
            Nos équipes vous appellent dans les meilleurs délais. <br />
            Mais en attendant, découvrez Chantier Privé.
          </p>
        </div>
        <div className="col-lg-8 offset-lg-2 pt-5">
          <Button
            id="button-validate-user-modal"
            data-cy="button-validate-user-modal"
            color="btn-primary"
            text={'Explorer les projets'}
            action={() => confirmModal()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuccessPriceRequestModal;
