import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import Modal from './index';
import ErrorMessage from '../../common/errors/ErrorMessage';
import { UserContext } from '../../common/providers/UserProvider';
import { BACKEND_URL } from '../../config/environment';

const SubmitRequestModal: React.FC<{
  project: IProject;
  hidden: boolean;
  closeModal: () => void;
  toCall?: () => void;
  closableModal?: () => void;
  user: IUser;
  selectedTrades: Array<string>;
  openConsultation: boolean;
}> = ({
  project,
  hidden,
  closeModal,
  toCall,
  closableModal,
  user,
  selectedTrades,
  openConsultation,
}) => {
  const { refreshUser } = useContext(UserContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [firstName, setFirstName] = useState(user.first_name ?? '');
  const [lastName, setLastName] = useState(user.last_name ?? '');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCreateProjectPublicRequest = (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage(null);
    setDisabled(true);
    axios
      .post(`${BACKEND_URL}/api/project-public-requests`, {
        first_name: firstName,
        last_name: lastName,
        project_id: project.id,
        trades: selectedTrades,
        open_consultation: openConsultation,
      })
      .then(() => {
        closeModal();
        refreshUser();
        toCall();
      })
      .catch((err) => {
        const error = err?.response?.data?.errorMessage;
        setErrorMessage(error ? error : 'Une erreur est survenue.');
        setDisabled(false);
      });
  };

  /* Trigger clicking outside event to close the modal */
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!modalRef.current.contains(event.target) && closableModal) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  return (
    <Modal hidden={hidden} maxWidth={600} closeModal={closeModal}>
      <div className="modal-body" ref={modalRef}>
        {errorMessage && <ErrorMessage text={errorMessage} />}

        <h1>DEMANDER MON PROJET GRATUIT</h1>
        <p className="mt-4">
          Afin d’accéder à votre projet gratuit il vous suffit de nous indiquer votre nom et prénom
          ci-dessous. Un conseiller Chantier Privé vous recontactera pour vous donner accès à votre
          projet qui vous correspond.
        </p>
        <form onSubmit={handleCreateProjectPublicRequest}>
          <div className="d-flex flex-row">
            <input
              type="text"
              name="first_name"
              placeholder="Prénom"
              disabled={disabled}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              className="form-control m-2"
            />
            <input
              type="text"
              name="last_name"
              placeholder="Nom"
              disabled={disabled}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              className="form-control m-2"
            />
          </div>

          <button
            type="submit"
            className="btn btn-secondary"
            disabled={disabled || !firstName || !lastName || !firstName.trim() || !lastName.trim()}
          >
            Je demande mon projet
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default SubmitRequestModal;
