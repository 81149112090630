import React from 'react';
import Modal from './index';

/**
 * This react component is used to display the subscription resume confirmation
 * @param props
 */
const SubscriptionResumeConfirmationModal: React.FC<{
  hidden: boolean;
  closeModal: () => void;
  refresh: () => void;
}> = ({ hidden, closeModal, refresh }) => {
  return (
    <Modal hidden={hidden} maxWidth={600} closeModal={() => closeModal()} hideCloseButton={true}>
      <div className="modal-body">
        <h1>VOTRE ABONNEMENT EST RÉACTIVÉ !</h1>
        <p className="mt-2">
          Votre abonnement est réactivé et sera renouvelé automatiquement.
          <br />
          <br />
          <b>
            Les changements peuvent prendre quelques minutes avant d&apos;apparaître sur votre
            espace.
          </b>
        </p>

        <button
          onClick={() => refresh()}
          id="button-terminer"
          data-cy="button-complete"
          className="btn btn-secondary mt-2"
        >
          Terminer
        </button>
      </div>
    </Modal>
  );
};

export default SubscriptionResumeConfirmationModal;
