import React, { useState } from 'react';
import axios from 'axios';
import Modal from './index';
import { useAnalytics } from '../RudderStack';
import { BACKEND_URL } from '../../config/environment';

/**
 * This react component is used to see the contact modal.
 * This modal is used to send a message to the contact mail of ChantierPrivé
 * when the use has questions or problems with the application.
 * @param props
 */
const ContactModal: React.FC<{ hidden: boolean; closeModal: () => void }> = (props) => {
  const [message, setMessage] = useState<string>('');
  const analytics = useAnalytics();
  const handleMessage = () => {
    if (message) {
      axios.post(`${BACKEND_URL}/api/user/contact`, { message }).then(() => {
        analytics?.track('Support Contacted');
      });
      setMessage('');
      props.closeModal();
    }
  };

  return (
    <Modal hidden={props.hidden} maxWidth={600} closeModal={props.closeModal}>
      <div className="modal-body">
        <h1>COMMENT POUVONS-NOUS VOUS AIDER ?</h1>
        <p className="mt-3">
          Expliquez-nous votre problème le plus précisément possible.
          <br />
          Nous vous recontacterons dans les plus bref délais.
        </p>
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Dites nous en plus..."
          className="form-control"
          style={{ margin: 'auto', minHeight: 100 }}
          value={message}
        />
        <br />
        <button
          type="button"
          disabled={!message}
          className="btn btn-secondary mt-2"
          onClick={() => handleMessage()}
        >
          ENVOYER
        </button>
      </div>
    </Modal>
  );
};

export default ContactModal;
