import React, { CSSProperties } from 'react';

const Toast: React.FC<{
  message: string;
  title: string;
  hidden: boolean;
  onClick: () => void;
  top?: boolean;
  fixedPosition?: boolean;
}> = ({ message, title, hidden, onClick, top = false, fixedPosition = false }) => {
  const style: CSSProperties = {
    opacity: 1,
    right: '10px',
    zIndex: 1001,
    backgroundColor: '#fff',
  };

  if (top) {
    style['top'] = '10px';
  } else {
    style['bottom'] = '10px';
  }

  style['position'] = fixedPosition ? 'fixed' : 'absolute';

  return (
    <>
      {!hidden && (
        <div className="toast" role="alert" aria-live="assertive" aria-atomic="true" style={style}>
          <div className="toast-header">
            <img
              src="/Icons/logo-symbol.png"
              className="rounded mr-2"
              alt="Logo Chantier Privé"
              style={{ height: '30px' }}
            />
            <strong className="mr-auto">{title}</strong>
            <button
              type="button"
              className="ml-2 mb-1 close"
              data-dismiss="toast"
              aria-label="Close"
              onClick={() => onClick()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="toast-body" data-cy="toast-body">
            {message}
          </div>
        </div>
      )}
    </>
  );
};

export default Toast;
