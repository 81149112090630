import React, { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import CircularLoader from '../../common/navigation/CircularLoader';
import { useAnalytics } from '../../common/RudderStack';
import { BACKEND_URL } from '../../config/environment';

const TrialPaymentSuccess: React.FC = () => {
  const history = useHistory();
  const { stripePriceName }: { stripePriceName: string } = useParams();
  const analytics = useAnalytics();

  useEffect(() => {
    axios
      .post(`${BACKEND_URL}/api/stripe/trial-subscription`, {
        price_name: stripePriceName,
      })
      .then(({ data }) => {
        const stripePrice: IStripePrice = data.stripe_price;

        analytics?.track('Order Completed', {
          revenue: stripePrice.price_excl_tax,
          currency: 'EUR',
          product_id: stripePrice.id,
          name: stripePrice.display_name,
          price: stripePrice.price_excl_tax,
          quantity: 1,
        });

        history.push('/settings/subscription?success=true');
      })
      .catch(() => history.push(`/offers/${stripePriceName}/failed`));
  }, []);

  return <CircularLoader />;
};

export default TrialPaymentSuccess;
