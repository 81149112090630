import React, { useState, useEffect } from 'react';

const ProjectRequest: React.FC<{
  selectedProject: IProject;
  projectRequest: IProjectRequest;
  updateRequestTradesStatus: (status: 'validated' | 'refused', projectRequestId: string) => void;
  updateRequestTradeStatus: (
    status: 'validated' | 'refused',
    projectRequestId: string,
    requestTradeId: string
  ) => void;
}> = ({ selectedProject, projectRequest, updateRequestTradesStatus, updateRequestTradeStatus }) => {
  const [isOpen, setIsOpen] = useState(false);

  /** Close accordion on selected project change. */
  useEffect(() => {
    setIsOpen(false);
  }, [selectedProject]);

  /**
   * Get the formatted localisation for project request company.
   * @returns string
   */
  const getCompanyLocalisation = () => {
    const city = projectRequest.account.company.city ?? '-';
    const zipCode = projectRequest.account.company.zip_code
      ? '(' + projectRequest.account.company.zip_code.substr(0, 2) + ')'
      : '';

    return (city + ' ' + zipCode).trim();
  };

  const nbValidatedRequestTrades = projectRequest.request_trades.filter(
    (requestTrade) => requestTrade.status === 'validated'
  ).length;

  const nbRefusedRequestTrades = projectRequest.request_trades.filter(
    (requestTrade) => requestTrade.status === 'refused'
  ).length;

  const nbRequestedRequestTrades = projectRequest.request_trades.filter(
    (requestTrade) => requestTrade.status === 'requested'
  ).length;

  return (
    <>
      {!isOpen ? (
        <div className="project-request">
          <div className="informations">
            <div className="bullet"></div>
            <span className="bullet-label">Nouveau</span>
            <div className="company">
              <span className="company-name">{projectRequest.account.company.name ?? '-'}</span>
              <span className="company-separator">|</span>
              <span className="company-city">
                {projectRequest.account.company.city ?? '-'}{' '}
                {projectRequest.account.company.zip_code
                  ? '(' + projectRequest.account.company.zip_code.substr(0, 2) + ')'
                  : ''}
              </span>
            </div>
          </div>
          <div className="buttons">
            <button
              onClick={() => updateRequestTradesStatus('validated', projectRequest.id)}
              className={
                nbRequestedRequestTrades === 0 && nbValidatedRequestTrades > 0
                  ? 'validation-button button validated'
                  : 'validation-button button'
              }
            >
              <>
                {nbRequestedRequestTrades > 0 ? (
                  <span>Tout accepter</span>
                ) : (
                  <span>({nbValidatedRequestTrades}) Acceptés</span>
                )}
              </>
            </button>
            <button
              onClick={() => updateRequestTradesStatus('refused', projectRequest.id)}
              className={
                nbRequestedRequestTrades === 0 && nbRefusedRequestTrades > 0
                  ? 'validation-button button refused'
                  : 'validation-button button'
              }
            >
              {nbRequestedRequestTrades > 0 ? (
                <span>Tout refuser</span>
              ) : (
                <span>({nbRefusedRequestTrades}) Refusés</span>
              )}
            </button>
            <img
              onClick={() => setIsOpen(!isOpen)}
              width="25"
              height="25"
              className="chevron"
              src={isOpen ? '/Icons/chevron-up.svg' : '/Icons/chevron-down.svg'}
            />
          </div>
        </div>
      ) : (
        <div className="project-request-open">
          <div className="project-request-wrapper">
            <div className="informations-wrapper">
              <div className="informations">
                <div className="bullet"></div>
                <div className="company">
                  <span title={projectRequest.account.company.name ?? '-'} className="company-name">
                    {projectRequest.account.company.name ?? '-'}
                  </span>
                  <span className="company-separator">|</span>
                  <span title={getCompanyLocalisation()} className="company-city">
                    {getCompanyLocalisation()}
                  </span>
                </div>
              </div>
              <div className="additional-informations">
                <span>
                  <span className="additional-information-key">SIRET</span> :{' '}
                  {projectRequest.account.company.siret ?? '-'}
                </span>
                <span>
                  <span className="additional-information-key">Téléphone</span> :{' '}
                  {projectRequest.account.main_user.phone ?? '-'}
                </span>
                <span>
                  <span className="additional-information-key">E-mail</span> :{' '}
                  <a className="email" href={`mailto:${projectRequest.account.main_user.mail}`}>
                    {projectRequest.account.main_user.mail ?? '-'}
                  </a>
                </span>
              </div>
            </div>
            <div className="request-trades">
              <div className="head">
                <div className="trade-name">
                  <span className="name">Lots demandés par l’entreprise</span>
                </div>
                <button
                  onClick={() => updateRequestTradesStatus('validated', projectRequest.id)}
                  className="button"
                >
                  Tout accepter
                </button>
                <button
                  onClick={() => updateRequestTradesStatus('refused', projectRequest.id)}
                  className="button"
                >
                  Tout refuser
                </button>
              </div>
              <ul className="trades">
                {projectRequest.request_trades.map((requestTrade, index) => {
                  return (
                    <li key={`request-trade-${index}`}>
                      <div className="trade-name">
                        <span title={requestTrade.trade.name}>{requestTrade.trade.name}</span>
                      </div>
                      <button
                        onClick={() =>
                          updateRequestTradeStatus('validated', projectRequest.id, requestTrade.id)
                        }
                        className={`${
                          requestTrade.status === 'validated' ? 'button validated' : 'button'
                        }`}
                      >
                        Accepter
                      </button>
                      <button
                        onClick={() =>
                          updateRequestTradeStatus('refused', projectRequest.id, requestTrade.id)
                        }
                        className={`${
                          requestTrade.status === 'refused' ? 'refused button' : 'button'
                        }`}
                      >
                        Refuser
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="buttons">
            <img
              onClick={() => setIsOpen(!isOpen)}
              width="25"
              height="25"
              className="chevron"
              src={isOpen ? '/Icons/chevron-up.svg' : '/Icons/chevron-down.svg'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectRequest;
