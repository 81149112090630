import React from 'react';
import Button from '../../../common/navigation/Button';

const UpdateCardAnnually: React.FC<{
  displayAdvantages: boolean;
  displayButton: boolean;
  handleChange?: () => void;
  price: number;
  title: string;
}> = ({ displayAdvantages, displayButton, handleChange, price, title }) => {
  return (
    <div
      className="card card-subscription-annually card-plan card-plan-annually plan"
      style={displayAdvantages ? {} : { height: '250px' }}
    >
      <div className="card-header-title">
        <span>{title}</span>
      </div>
      {displayAdvantages && (
        <div className="annually-option-edit">
          <span>Meilleure option</span>
        </div>
      )}
      <div className="card-title">
        <h3>ANNUEL</h3>
      </div>
      <h1>
        {price}€<sup className="large">HT</sup>
      </h1>
      <h4 className="under-price">
        Au lieu de <b className="line-orange">1188€</b>
        <sup className="small-orange">HT</sup>
      </h4>
      <br />
      <h4 className="under-price">Facturé chaque année</h4> <br />
      {displayAdvantages && (
        <ul className="check-list">
          <li className="icon-annually">
            <b>
              Économie de 238€<sup className="medium">HT</sup>
            </b>
          </li>
          <li className="icon-annually">
            <b>Exclusivité</b> des chantiers
          </li>
          <li className="icon-annually">
            <b>Adaptés</b> à votre activité
          </li>
          <li className="icon-annually">
            Accès <b>illimité</b> aux consultations
          </li>
          <li className="icon-annually">
            Abonnement <b>sans engagement</b>
          </li>
        </ul>
      )}
      {displayButton && (
        <div className="button">
          <Button color="btn-primary" text={"Je m'abonne"} action={() => handleChange()} />
        </div>
      )}
    </div>
  );
};

export default UpdateCardAnnually;
