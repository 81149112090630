import React from 'react';
import { Link } from 'react-router-dom';
import CardQuarterly from '../subscriptions/prices/CardQuarterly';
import CardAnnually from '../subscriptions/prices/CardAnnually';

const LandingFormulas: React.FC = () => {
  return (
    <div style={{ padding: '20px' }}>
      <div className="container">
        <div className="d-flex align-items-center">
          <div className="card card-body container-plans" style={{ padding: 10 }}>
            <img
              src="/images/logo_saqara.png"
              style={{ width: '300px', height: '150px', margin: 'auto' }}
              alt="Logo chantier privé par Saqara"
            />

            <div className="alert alert-success mt-4">
              Merci, nous allons rapidement vous recontacter pour avoir accès au DCE.
            </div>

            <p className="mt-4">
              <b>Intéressé par plus de projets ?</b><br />
              <Link to="/settings/subscription" className="btn btn-secondary no-block m-0 ml-2">Découvrez nos offres !</Link>
            </p>

            <p>
              <Link to="/consultation_ask" className="btn btn-primary no-block m-0 ml-2">Accéder à mon espace</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFormulas;
