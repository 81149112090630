import React, { createContext, useState, useEffect, useContext } from 'react';
import TopMessage from '../navigation/TopMessage';
import { UserContext } from './UserProvider';

const TopMessageContext = createContext<{
  displayTopMessage: (
    message: string,
    type: string,
    closable?: boolean,
    link?: string,
    linkText?: string
  ) => void;
  closeTopMessage: () => void;
}>({
  displayTopMessage: null,
  closeTopMessage: null,
});

type Type =
  | 'warning'
  | 'danger'
  | 'success'
  | 'primary_information'
  | 'secondary_information'
  | 'third_information';

const TopMessageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, fetched } = useContext(UserContext);

  const [display, setDisplay] = useState<boolean>(false);
  const [closable, setClosable] = useState<boolean>(true);
  const [message, setMessage] = useState<string>(null);
  const [link, setLink] = useState<string>(null);
  const [linkText, setLinkText] = useState<string>(null);
  const [type, setType] = useState<Type>(null);

  useEffect(() => {
    updateLayout();
  }, [display, document.querySelector('.content-app')]);

  /** Close the top message if the user logout. */
  useEffect(() => {
    if (fetched && !user) {
      closeTopMessage();
    }
  }, [user, fetched]);

  /**
   * Update the marged className of the content-app wrapper.
   */
  const updateLayout = () => {
    const contentApp: HTMLElement = document.querySelector('.content-app');
    const top: HTMLElement = document.querySelector('#top');

    if (contentApp) {
      if (display) {
        contentApp.className += ' marged';
        top.className += ' marged';
      } else {
        contentApp.classList.remove('marged');
        top.classList.remove('marged');
      }
    }
  };

  const displayTopMessage = (
    message: string,
    type: Type,
    closable = true,
    link?: string,
    linkText?: string
  ) => {
    setMessage(message);
    setType(type);
    setClosable(closable);
    setLink(link);
    setLinkText(linkText);
    setDisplay(true);
  };

  const closeTopMessage = () => {
    setMessage(null);
    setType(null);
    setClosable(true);
    setLink(null);
    setLinkText(null);
    setDisplay(false);
  };

  return (
    <TopMessageContext.Provider value={{ displayTopMessage, closeTopMessage }}>
      {display && message && (
        <TopMessage
          message={message}
          link={link}
          linkText={linkText}
          type={type}
          close={closable ? closeTopMessage : null}
        />
      )}
      {children}
    </TopMessageContext.Provider>
  );
};

export { TopMessageProvider, TopMessageContext };
