import React, { useState } from 'react';

type PasswordInputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * This is an  encapsulation of the password input
 * with display/hide password
 *
 * @param onChange onChange event
 */
const PasswordInput: React.FC<PasswordInputProps> = ({ onChange }) => {
  const [value, setValue] = useState<string>(null);
  const [isShown, setIsShown] = useState<boolean>(false);

  const handleOnChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e);
  };

  const handleShowClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsShown(!isShown);
  };

  return (
    <div className="password-container">
      <input
        id="password"
        data-cy="password"
        type={isShown ? 'text' : 'password'}
        className="form-control"
        placeholder="Mot de passe"
        onChange={handleOnChangePassword}
      />

      <a
        href="#"
        role="button"
        title="Afficher le mot de passe"
        onClick={handleShowClick}
        hidden={!value}
      >
        <div
          className={`icon ${isShown ? 'icon-eye' : 'icon-eye-off'}`}
          id={`icon ${isShown ? 'icon-eye' : 'icon-eye-off'}`}
          data-cy={`icon ${isShown ? 'icon-eye' : 'icon-eye-off'}`}
        ></div>
      </a>
    </div>
  );
};

export default PasswordInput;
