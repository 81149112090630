import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import SubscriptionList from './SubscriptionList';
import SubscriptionInformation from './SubscriptionInformation';
import CircularLoader from '../../common/navigation/CircularLoader';
import UpdatePaymentMethod from './UpdatePaymentMethod';
import SubscriptionConfirmationModal from '../../common/Modal/SubscriptionConfirmationModal';
import SubscriptionFailureModal from '../../common/Modal/SubscriptionFailureModal';
import PaymentMethodAttachedConfirmationModal from '../../common/Modal/PaymentMethodAttachedConfirmationModal';

import { UserContext } from '../../common/providers/UserProvider';
import queryString from 'query-string';
import { BACKEND_URL } from '../../config/environment';

const Subscription: React.FC<{ user: IUser }> = ({ user }) => {
  const { refreshUser } = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [failureModal, setFailureModal] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<ISubscription>(null);
  const [paymentMethodAttachedModal, setPaymentMethodAttachedModal] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<Array<IInvoice>>([]);

  /**
   * List of subscription statuses that don't
   * need to display the SubscriptionList component.
   */
  const subscriptionStatuses = ['past_due', 'disputed'];

  const query = queryString.parse(location.search);

  const getSubscription = () => {
    setLoading(true);
    return axios
      .get<{ subscription: ISubscription }>(`${BACKEND_URL}/api/subscriptions`)
      .then(({ data }) => {
        setSubscription(data.subscription);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSubscription();
  }, []);

  useEffect(() => {
    axios.get(`${BACKEND_URL}/api/invoices`).then(({ data }) => {
      setInvoices(data.invoices);
    });
  }, []);

  useEffect(() => {
    if (query.success === 'true') {
      setSuccessModal(true);
    } else if (query.success === 'false') {
      setFailureModal(true);
    } else if (query['payment-method-success'] === 'true') {
      setPaymentMethodAttachedModal(true);
    }
  }, []);

  const refreshSubscription = () => {
    return getSubscription().finally(() => {
      refreshUser().then(() => {
        setSuccessModal(false);
        setFailureModal(false);
        setPaymentMethodAttachedModal(false);
        history.push('/settings/subscription');
      });
    });
  };

  /**
   * Call the server which generates the invoice and returns a pre-signed url.
   * This URL is then opened to a new tab of the browser.
   *
   * @param invoiceId
   */
  const downloadInvoice = (invoiceId: string) => {
    axios.get(`${BACKEND_URL}/api/invoices/${invoiceId}`).then(({ data }) => {
      const newWindow = window.open(data, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    });
  }

  return (
    <>
      <PaymentMethodAttachedConfirmationModal
        hidden={paymentMethodAttachedModal}
        closeModal={() => setPaymentMethodAttachedModal(false)}
        refresh={() => refreshSubscription()}
      />
      <SubscriptionConfirmationModal
        hidden={successModal}
        closeModal={() => setSuccessModal(false)}
        refresh={() => refreshSubscription()}
      />
      <SubscriptionFailureModal hidden={failureModal} closeModal={() => setFailureModal(false)} />
      {loading ? (
        <CircularLoader />
      ) : (
        [
          !user.is_stripe_subscriber &&
            !subscriptionStatuses.includes(user.subscription_status) && (
              <SubscriptionList user={user} key="subscription-list" />
            ),
          !user.is_stripe_subscriber && user.subscription_status === 'disputed' && (
            <div className="row my-4">
              <div className="col-12">
                <div className="card card-body">
                  <h2 className="title-card-option title-subscription">Contestation de paiement</h2>

                  <div className="card-body">
                    Votre paiement a été contesté. Veuillez contacter votre banque pour résoudre ce
                    problème.
                  </div>
                </div>
              </div>
            </div>
          ),
          !user.is_stripe_subscriber && user.subscription_status === 'past_due' && (
            <UpdatePaymentMethod key="update-payment-method" subscription={subscription} />
          ),
          user.is_stripe_subscriber && (
            <SubscriptionInformation
              key="subscription-information"
              subscription={subscription}
              refreshSubscription={refreshSubscription}
              user={user}
            />
          ),
          invoices && invoices.length > 0 && (
            <div className="row my-4" key="invoices">
              <div className="col-12">
                <div className="card card-body">
                  <h2 className="title-card-option title-subscription">
                    Historique de facturation
                  </h2>
                  <div
                    className="table-responsive table-subscription"
                    id="scrollbar"
                    data-cy="scrollbar"
                    style={{ maxHeight: '250px', overflow: 'auto' }}
                  >
                    <table className="table table-hover mt-4">
                      <thead>
                        <tr>
                          <th scope="col">DATE</th>
                          <th scope="col">TYPE</th>
                          <th scope="col">MONTANT (TTC)</th>
                          <th scope="col">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices &&
                          invoices.length > 0 &&
                          invoices.map((invoice) => (
                            <tr key={invoice.id}>
                              <td>
                                <p>{invoice.format_date}</p>
                              </td>
                              <td>{invoice.type === 1 ? 'Facture' : 'Avoir'}</td>
                              <td>{invoice.amount_incl_tax} €</td>
                              <td>
                                {/*<a href={`${BACKEND_URL}/api/invoices/view/${invoice.id}`} className="ml-3">*/}
                                {/*  <img src="/Icons/eye.png" className="mr-2" />*/}
                                {/*</a>*/}
                                <img src="/Icons/download.png" className="mr-2 ml-3" style={{cursor: 'pointer'}} onClick={() => downloadInvoice(invoice.id)}  />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ),
        ]
      )}
    </>
  );
};

export default Subscription;
