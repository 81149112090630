import moment from 'moment';
import React, { useState } from 'react';
import ProjectModal from '../../common/Modal/ProjectModal';
import SelectTradeModal from '../../common/Modal/SelectTradeModal';
import { useAnalytics } from '../../common/RudderStack';

const ProjectPublicListElement: React.FC<{
  project: IProject;
  user: IUser;
  reload?: () => void;
  projectRequest?: IProjectRequest;
  projectPublicRequest?: boolean;
  marginTop: number;
}> = ({ project, user, projectRequest, projectPublicRequest, marginTop, reload }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openLot, setOpenLot] = useState<boolean>(false);
  const [selectedTrade, setSelectedTrade] = useState<Array<string>>([]);
  const analytics = useAnalytics();

  const loadBottomCrumb = () => {
    switch (projectRequest.status) {
      case 'validated':
        return <div className="crumb flash-success">Demande acceptée</div>;
      case 'requested':
        return <div className="crumb flash-info">Demande en attente</div>;
      case 'refused':
        return <div className="crumb flash-danger">Accès refusé</div>;
      default:
        return '';
    }
  };

  const loadTopCrumb = () => {
    if (project.archived_at) {
      return (
        <div className="card-ribbon archived">
          <span>Projet clôturé</span>
        </div>
      );
    } else if (project.is_new) {
      return (
        <div className="card-ribbon new">
          <span>Nouveauté</span>
        </div>
      );
    } else if (project.is_coming) {
      return (
        <div className="card-ribbon coming">
          <span>Bientôt disponible</span>
        </div>
      );
    }
    return '';
  };

  const openModal = () => {
    analytics?.track('Public Project Modal Opened', {
      name: project.name,
      purchaser_name: project.purchaser_name,
      zip_code: project.zip_code,
      construction_type: project.construction_type,
      begin_of_offering_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      end_of_offering_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      building_site_start_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      building_site_end_date: moment(project.begin_of_offering_date, 'd/m/Y').toISOString(),
      city: project.city,
      is_coming: project.is_coming,
      is_flash: project.is_flash,
      is_external: project.is_external,
    });

    setOpen(true);
  };

  const loadButton = () => {
    if (projectRequest) {
      return (
        <button
          id={projectRequest.status === 'validated' ? 'button-access-dce' : 'button-access-project'}
          data-cy={
            projectRequest.status === 'validated' ? 'button-access-dce' : 'button-access-project'
          }
          type="button"
          className="btn btn-secondary btn-card-consultation"
          onClick={openModal}
        >
          {projectRequest.status === 'validated' ? 'ACCÉDER AU DCE' : 'DÉTAILS PROJET'}
        </button>
      );
    }

    if (project.archived_at) {
      return (
        <button
          id="button-unavailable-project"
          data-cy="button-unavailable-project"
          type="button"
          className="btn btn-secondary btn-card-consultation"
          onClick={openModal}
          disabled={true}
        >
          PLUS DISPONIBLE
        </button>
      );
    }

    return (
      <button
        id={project.is_coming ? 'button-know-more-project' : 'button-access-project'}
        data-cy={project.is_coming ? 'button-know-more-project' : 'button-access-project'}
        type="button"
        className="btn btn-secondary btn-card-consultation"
        onClick={openModal}
      >
        {project.is_coming ? 'EN SAVOIR PLUS' : 'DÉTAILS PROJET'}
      </button>
    );
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <ProjectModal
        hidden={open}
        closeModal={closeModal}
        toCall={() => setOpenLot(true)}
        forceAccess={true}
        projectPublicRequest={projectPublicRequest}
        projectRequest={projectRequest}
        project={project}
        user={user}
      />

      <SelectTradeModal
        project={project}
        hidden={openLot}
        forceAccess={true}
        closeModal={() => setOpenLot(false)}
        toCall={() => reload()}
        user={user}
        selectedTrades={selectedTrade}
        setSelectedTrades={setSelectedTrade}
        openConsultation={user.open_consultation}
      />

      <div className="col-lg-4 col-sm-6 col-md-6">
        <div className={`card card-body card-consultation with-flash mt-${marginTop}`}>
          <div style={{ position: 'relative' }}>
            {loadTopCrumb()}
            <img
              src={project.image_path}
              className="card-img-top"
              alt={project.name}
              draggable={false}
            />
            {projectRequest && loadBottomCrumb()}
          </div>
          <div className="content" style={project ? { paddingTop: 10 } : {}}>
            <span className="title" style={{ marginTop: 5 }} title={project.name}>
              {project.name}
            </span>

            <div className="description">
              <span className="description-key">Code postal</span>
              <span> : {project.zip_code ? project.zip_code : ' NC'}</span>
              <br />
              {!project.archived_at && (
                <>
                  {!project.is_ended && project.end_of_offering_date && (
                    <>
                      <span className="description-key">Fin de consultation</span>
                      <span> : le {project.end_of_offering_date}</span>
                    </>
                  )}
                  {!project.is_coming ? (
                    <span style={{ color: '#75C493' }}> Ouvert à la consultation</span>
                  ) : (
                    <span style={{ color: '#CEC7C7', fontWeight: 'bold' }}>
                      Bientôt ouvert à la consultation
                    </span>
                  )}
                </>
              )}
              {loadButton()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectPublicListElement;
