import React, { useState, useRef, useEffect } from 'react';

const Tab: React.FC<{
  projects: Array<IProject>;
  setSelectedProject: (project: IProject) => void;
  selectedProject: IProject;
}> = ({ projects, setSelectedProject, selectedProject }) => {
  const dropdown = useRef<HTMLDivElement>();
  const dropdownButton = useRef<HTMLLIElement>();

  const [openDropdown, setOpenDropdown] = useState(false);

  const nbVisibleProjects = 3;

  const visibleProjects =
    projects.length > nbVisibleProjects ? projects.slice(0, nbVisibleProjects) : projects;
  const hiddenProjects =
    projects.length > nbVisibleProjects ? projects.slice(nbVisibleProjects, projects.length) : [];

  /** Bind event listener to the dropdown ref. */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  /** Handle the click outside of the dropdown. */
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (
      dropdown &&
      !dropdown?.current?.contains(target) &&
      !dropdownButton?.current?.contains(target)
    ) {
      setOpenDropdown(false);
    }
  };

  /**
   * Handle the tab change.
   * @param {IProject} project - The project.
   */
  const handleChangeTab = (project: IProject) => {
    setSelectedProject(project);
    setOpenDropdown(false);
  };

  /**
   * Get the selected project index.
   * @returns integer
   */
  const getSelectedProjectIndex = () => {
    return projects.findIndex((project) => project.id === selectedProject.id);
  };

  /** Extend the global wrapper size when dropdown is open. */
  useEffect(() => {
    if (openDropdown) {
      const app = document.getElementById('app');
      if (app.scrollHeight < 500) {
        app.style.height = '500px';
      }
    }
  }, [openDropdown]);

  return (
    <ul className="purchaser-navigation">
      {visibleProjects.map((project, index) => {
        return (
          <li
            title={project.name}
            onClick={() => handleChangeTab(project)}
            className={getSelectedProjectIndex() === index ? 'active' : ''}
            key={`project-${index}`}
          >
            <span>{project.name}</span>
          </li>
        );
      })}
      {hiddenProjects.length > 0 && (
        <div className="project-dropdown-wrapper">
          <li
            ref={dropdownButton}
            onClick={() => setOpenDropdown(!openDropdown)}
            className="more"
            key="project-more"
          >
            <span>...</span>
          </li>
          {openDropdown && (
            <div ref={dropdown} className="project-dropdown">
              <ul>
                {hiddenProjects.map((project, index) => {
                  return (
                    <li
                      title={project.name}
                      className={
                        getSelectedProjectIndex() === index + nbVisibleProjects ? 'active' : ''
                      }
                      onClick={() => handleChangeTab(project)}
                      key={`project-${index + nbVisibleProjects}`}
                    >
                      <span>{project.name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </ul>
  );
};

export default Tab;
