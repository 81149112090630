import axios from 'axios';
import React, { useContext } from 'react';
import { UserContext } from '../../common/providers/UserProvider';
import { BACKEND_URL, V2_URL } from '../../config/environment';

const Banner = () => {
  const { logout } = useContext(UserContext);

  const migrateToV2 = () => {
    axios.put(`${BACKEND_URL}/api/v2/users/me/preferences/version`).then(() => {
      axios.post(`${BACKEND_URL}/api/logout`).then(() => {
        logout();
        window.location.href = V2_URL;
      });
    });
  };

  return (
    <>
      <div className="p-3 p-md-0">
        <div
          className="search-card mb-3 flex-column flex-md-row d-flex"
          style={{ background: 'white', padding: '24px', gap: '24px' }}
        >
          <div>
            <img
              src={`/images/migration/V2_migration.png`}
              alt="V2_migration.png"
              style={{ width: '100%' }}
            />
          </div>
          <div
            className="d-flex flex-column"
            style={{
              gap: '16px',
              order: 1,
              alignItems: 'flex-start',
              maxWidth: '530px',
              flexWrap: 'wrap',
            }}
          >
            <div className="d-flex flex-row" style={{ gap: '8px', order: 0, alignItems: 'center' }}>
              <img
                src={`/images/migration/Star.png`}
                alt="Star.png"
                style={{ width: '17px', height: '17px' }}
              />
              <div
                style={{
                  font: 'Varela Round',
                  fontSize: '24px',
                  lineHeight: '29px',
                  fontWeight: 'normal',
                }}
              >
                Chantier Privé fait son printemps !
              </div>
            </div>
            <div>
              <span
                className="d-flex"
                style={{
                  font: 'Varela Round',
                  fontSize: '15px',
                  lineHeight: '25px',
                  fontWeight: 'normal',
                  order: 1,
                }}
              >
                Votre plateforme évolue : nouveau design, nouvelles fonctionnalités,
              </span>
              <span
                className="d-flex"
                style={{
                  font: 'Varela Round',
                  fontSize: '15px',
                  lineHeight: '25px',
                  fontWeight: 'bold',
                  order: 1,
                }}
              >
                testez-les dès aujourd’hui !
              </span>
              <span
                className="d-flex"
                style={{
                  font: 'Varela Round',
                  fontSize: '15px',
                  lineHeight: '25px',
                  fontWeight: 'normal',
                  order: 1,
                }}
              >
                Cliquez sur le bouton ci-dessous, votre compte et vos dossiers restent les-mêmes.
                Vous pourrez revenir à l’ancienne version à tout moment.
              </span>
            </div>
            <button
              type="button"
              className="btn btn-primary mt-2 d-flex d-md-block"
              onClick={() => migrateToV2()}
              style={{ width: '100%', alignItems: 'center' }}
            >
              <span className="text" style={{ whiteSpace: 'normal' }}>
                Découvrir votre nouvelle plateforme
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
