import React, { createContext, useState, useRef, useEffect, useContext } from 'react';
import { UserContext } from './UserProvider';
import { Steps } from 'intro.js-react';
import { IStep } from '../../interfaces/IStep';
import { steps, skipLabel, tourPaths, version } from '../product-tour/tour-config';
import { useLocation, useHistory } from 'react-router-dom';
import 'arrive';
import { useAnalytics } from '../RudderStack';

const ProductTourContext = createContext<{
  currentStep: IStep;
  triggerNextTourStep: () => void;
  forceTourTrigger: () => void;
  isTourEnabled: boolean;
}>({ isTourEnabled: null, triggerNextTourStep: null, currentStep: null, forceTourTrigger: null });

const ProductTourProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const history = useHistory();
  const [isTourEnabled, setTourEnabled] = useState<boolean>(false);
  const [closeTour, setCloseTour] = useState<boolean>(false);
  const introJsRef = useRef(null);
  const [currentStep, setCurrentStep] = useState<IStep>(steps[0]);
  const [computedSteps, setComputedSteps] = useState(null);
  const analytics = useAnalytics();

  const { user } = useContext(UserContext);
  const location = useLocation();
  const enableWidth = 1275;

  //Disable product tour if window become less than enableWidth
  window.addEventListener('resize', () => {
    if (window.innerWidth < enableWidth) {
      setTourEnabled(false);
    }
  });

  useEffect(() => {
    if (closeTour) {
      setTourEnabled(false);
    } else if (
      user &&
      currentStep &&
      tourPaths.includes(location.pathname + (location.hash ? location.hash : ''))
    ) {
      //Enable only for screen width superior or equal to enableWidth
      if (window.innerWidth >= enableWidth) {
        setTourEnabled(true);

        //Get the steps following the conditions on each of them
        setComputedSteps(getComputedSteps());
      }
    }
  }, [user, currentStep, location.pathname, location.hash, closeTour]);

  //On step change: add listener on DOM, and track with segment
  useEffect(() => {
    if (isTourEnabled) {
      window.unbindArrive('#tour__step');
      window.arrive('#tour__step', attachStepObserver);

      analytics?.track('Change Product Tour Step', {
        step_id: currentStep.id,
        step_number: getStepNumber(currentStep.id),
        is_optional_step: !!currentStep.conditions,
        onboarding_version: version,
      });
    }
  }, [isTourEnabled, currentStep]);

  //Get the steps where the conditions match
  const getComputedSteps = () => {
    const computedStepsTmp: Array<IStep> = [];

    steps.forEach((step) => {
      if (matchConditions(step)) {
        computedStepsTmp.push(step);
      }
    });

    return computedStepsTmp;
  };

  const forceTourTrigger = () => {
    setCloseTour(false);
    document.location.href = '/consultation_open#tour';
  };

  //Check if a step condition match
  const matchConditions = (step: IStep) => {
    let matchConditions = true;

    if (step.conditions) {
      step.conditions.forEach((condition) => {
        if (condition === 'free' && user.is_stripe_subscriber) {
          matchConditions = false;
        }
      });
    }

    return matchConditions;
  };

  //Jump step function
  const jumpStep = () => {
    if (currentStep && currentStep.jumpStepId) {
      introJsRef.current.introJs.goToStepNumber(getStepNumber(currentStep.jumpStepId) + 1);
    }
  };

  //Trigger the next step
  const triggerNextTourStep = () => {
    if (isTourEnabled) {
      introJsRef.current.introJs.nextStep();
    }
  };

  //Get the step number from the id
  const getStepNumber = (id: string) => {
    let getCurrentStepNumber = 0;
    computedSteps.forEach((step: IStep, index: number) => {
      if (step.id === id) {
        getCurrentStepNumber = index;
      }
    });
    return getCurrentStepNumber;
  };

  //Refresh element when the Step is ready
  const attachStepObserver = () => {
    const stepNumber = getStepNumber(currentStep.id);

    //Disable or enable the buttons
    const tooltipButtons: HTMLElement = document.querySelector('.introjs-tooltipbuttons');
    tooltipButtons.style.display = currentStep.showButtons ? 'flex' : 'none';

    //Set the custom text inside the next button
    const nextButtons: HTMLElement = document.querySelector('.introjs-nextbutton');
    //If the current step is the last one, modify next button wording
    if (stepNumber === computedSteps.length - 3) {
      nextButtons.innerHTML = 'Dernière étape';
    } else {
      nextButtons.innerHTML = currentStep.nextLabel;
    }

    //Assign the jump function to the "Plus tard" button
    const button: HTMLElement = document.querySelector('#tour__jump-button');
    if (button) {
      button.onclick = jumpStep;
    }

    //Add the progress stepper excepted for the first and last step
    if (stepNumber > 0 && stepNumber < computedSteps.length - 1) {
      const content: HTMLElement = document.querySelector('.introjs-tooltiptext');
      const node = document.createElement('p');
      node.classList.add('introjs-stepper');
      node.appendChild(
        document.createTextNode(
          'Étape ' + getStepNumber(currentStep.id) + ' sur ' + (computedSteps.length - 2)
        )
      );

      content.insertBefore(node, content.firstChild);
    }

    //Enable interaction with the focused block
    if (!currentStep.disableInteraction) {
      const element: HTMLElement = document.querySelector(currentStep.element);
      if (element) {
        element.classList.add('introjs-showElement');
        element.classList.add('introjs-relativePosition');
      }
    }

    //Refresh IntroJS
    const currentStepNumber = getStepNumber(currentStep.id);
    introJsRef.current.updateStepElement(currentStepNumber);
    introJsRef.current.introJs.refresh();
  };

  const onTourExit = () => {
    window.unbindArrive('#tour__step');
    setCloseTour(true);

    analytics?.track('Exit Product Tour', {
      step_id: currentStep.id,
      step_number: getStepNumber(currentStep.id),
      is_optional_step: !!currentStep.conditions,
      onboarding_version: version,
    });
  };

  const onTourChange = (step: number) => {
    const stepObj = computedSteps[step];
    setCurrentStep(stepObj);

    if (stepObj.redirection) {
      history.push(stepObj.redirection);
    }
  };

  return (
    <ProductTourContext.Provider
      value={{ isTourEnabled, triggerNextTourStep, currentStep, forceTourTrigger }}
    >
      {user && currentStep && computedSteps && (
        <Steps
          enabled={isTourEnabled && !closeTour}
          steps={computedSteps}
          onChange={onTourChange}
          initialStep={0}
          onExit={onTourExit}
          ref={introJsRef}
          options={{
            tooltipClass: currentStep.tooltipClass,
            showBullets: false,
            showButtons: currentStep.showButtons,
            nextLabel: currentStep.nextLabel,
            skipLabel: skipLabel,
            keyboardNavigation: false,
            showProgress: false,
            exitOnOverlayClick: false,
          }}
        />
      )}

      {children}
    </ProductTourContext.Provider>
  );
};

export { ProductTourProvider, ProductTourContext };
