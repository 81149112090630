import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    //Redirect to login page when 401 (For all routes excepted login and get current user)
    if (error?.response?.status === 401) {
      localStorage.setItem('redirect', document.location.pathname);
      if (
        error?.response?.config?.url !== '/api/user' &&
        error?.response?.config?.url !== '/api/login'
      ) {
        // document.location.href = '/login?disconnected=true';
        return error;
      }
    } else if (error?.response?.status === 422) {
      //Get the first validation error to the key errorMessage
      const errors: Array<Array<string>> = Object.values(error?.response?.data?.errors);

      if (errors.length > 0 && errors[0].length > 0) {
        error.response.data.errorMessage = errors[0][0];
      }
    }

    throw error;
  }
);
