import React from 'react';
import { useHistory } from 'react-router';
import Modal from './index';
import Button from '../navigation/Button';

/**
 * This react component is used to see the login modal.
 * This modal is used to inform the user if the mail or the password is wrong.
 * @param props
 * @constructor
 */
const LoginModal: React.FC<{ hidden: boolean; closeModal: () => void }> = (props) => {
  const history = useHistory();
  return (
    <Modal hidden={props.hidden} closeModal={props.closeModal} maxWidth={600}>
      <div className="modal-body">
        <h1 id="message-invalid-credentials" data-cy="message-invalid-credentials">
          EMAIL OU MOT DE PASSE INCORRECT
        </h1>
        <p className="mt-2">
          Si vous rencontrez des difficultés pour vous connecter, vous pouvez réinitialiser votre
          mot de passe.
        </p>

        <Button
          color="btn-secondary mt-2"
          text="RÉINITIALISER MON MOT DE PASSE"
          action={() => history.push('/reset-password')}
        />
        <Button color="btn-outline-secondary mt-2" text="RÉESSAYER" action={props.closeModal} />
      </div>
    </Modal>
  );
};

export default LoginModal;
