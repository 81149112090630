import React, { useState, useEffect } from 'react';
import CircularLoader from '../../../common/navigation/CircularLoader';
import TitleIconed from '../../../common/navigation/TitleIconed';
import PurchaserWrapper from '../PurchaserWrapper';
import ProjectRequest from './ProjectRequest';
import { useParams } from 'react-router';
import Tab from '../Tab';
import axios from 'axios';
import { BACKEND_URL } from '../../../config/environment';

const ProjectRequestsFollowup: React.FC = () => {
  const [projects, setProjects] = useState<Array<IProject>>(null);
  const [selectedProject, setSelectedProject] = useState<IProject>(null);
  const [projectRequests, setProjectRequests] = useState<Array<IProjectRequest>>(null);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const [loadingProjectRequests, setLoadingProjectRequests] = useState<boolean>(true);
  const [projectError, setProjectError] = useState<boolean>(false);
  const { token } = useParams<{ token: string }>();

  /** Get the projects. */
  useEffect(() => {
    getProjects();
  }, []);

  /** Get the project requests of the selected project. */
  useEffect(() => {
    if (selectedProject) {
      getProjectRequests();
    }
  }, [selectedProject]);

  /**
   * Get projects.
   */
  const getProjects = () => {
    setLoadingProjects(true);
    setLoadingProjectRequests(true);
    axios
      .get(`${BACKEND_URL}/api/public/purchasers/${token}/projects`, {
        params: { project_requests_status: ['validated', 'refused'] },
      })
      .then(({ data }) => {
        setProjects(data.projects);
        setSelectedProject(data.projects.length ? data.projects[0] : null);
      })
      .catch(() => {
        setProjectError(true);
      })
      .finally(() => {
        setLoadingProjects(false);
      });
  };

  /**
   * Get the project requests for the selected project.
   */
  const getProjectRequests = () => {
    setLoadingProjectRequests(true);
    axios
      .get(
        `${BACKEND_URL}/api/public/purchasers/${token}/projects/${selectedProject.id}/project-requests`,
        {
          params: { status: ['validated', 'refused'] },
        }
      )
      .then(({ data }) => {
        setProjectRequests(data.project_requests);
      })
      .finally(() => {
        setLoadingProjectRequests(false);
      });
  };

  /**
   * Get the archived projects.
   * @returns Array<IProject>
   */
  const getArchivedProjects = () => {
    return projects.filter((project) => !!project.archived_at);
  };

  /**
   * Get the available projects.
   * @returns Array<IProject>
   */
  const getAvailableProjects = () => {
    return projects.filter((project) => project.archived_at === null);
  };

  return (
    <PurchaserWrapper>
      <div className="container-fluid container-app">
        <div className="content-app">
          <div className="container-center">
            {loadingProjects ? (
              <div className="content">
                <div className="content-spinner">
                  <CircularLoader />
                </div>
              </div>
            ) : (
              <>
                <TitleIconed icon="noun_update_activated" text="SUIVI DES DEMANDES" />
                <p className="sub-description">
                  Retrouvez le suivi des demandes d’entreprises que vous avez accepté et refusé.
                </p>
                {projectError ? (
                  <div
                    className="alert alert-danger d-flex justify-content-between"
                    style={{ borderRadius: '20px' }}
                  >
                    <div>Lien expiré ou invalide.</div>
                  </div>
                ) : (
                  <>
                    {projects && projects.length > 0 ? (
                      <>
                        <div className="project-count">
                          <span>
                            <b>{getArchivedProjects().length}</b> projets archivés
                          </span>
                          <span className="count-separator"> | </span>
                          <span>
                            <b>{getAvailableProjects().length}</b> projets en cours
                          </span>
                        </div>
                        <Tab
                          projects={projects}
                          setSelectedProject={setSelectedProject}
                          selectedProject={selectedProject}
                        />

                        {loadingProjectRequests ? (
                          <div className="content">
                            <div className="content-spinner">
                              <CircularLoader />
                            </div>
                          </div>
                        ) : (
                          <div className="project-requests-wrapper">
                            <table
                              className="project-requests-table"
                              cellPadding={10}
                              cellSpacing={10}
                            >
                              <thead>
                                <tr>
                                  <th>Entreprise</th>
                                  <th>Téléphone</th>
                                  <th>E-mail</th>
                                  <th>Suivi</th>
                                </tr>
                              </thead>
                              <tbody>
                                {projectRequests.map((projectRequest, index) => (
                                  <ProjectRequest
                                    key={`project-request-${index}`}
                                    projectRequest={projectRequest}
                                  />
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </>
                    ) : (
                      <div
                        className="alert alert-success d-flex justify-content-between"
                        style={{ borderRadius: '20px' }}
                      >
                        <div>
                          Vous n&apos;avez pas encore accepté ou refusé d&apos;entreprises sur vos
                          projets.
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PurchaserWrapper>
  );
};

export default ProjectRequestsFollowup;
