import React, { InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, className, ...props }) => {
  return (
    <div className={`checkbox-wrapper ${className ?? ''}`}>
      <label>
        <input type="checkbox" {...props} />
        <span className="custom-checkbox">
          <span className="check-icon" />
        </span>
        <span className="title">{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
