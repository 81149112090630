import React, { useState, useContext } from 'react';

import axios from 'axios';
import { useHistory, useLocation, Redirect } from 'react-router';
import { UserContext } from '../../common/providers/UserProvider';
import queryString from 'query-string';
import LoginCarousel from './LoginCarousel';
import Button from '../../common/navigation/Button';
import ErrorMessage from '../../common/errors/ErrorMessage';
import PhoneInput from '../../common/navigation/PhoneInput';
import { useAnalytics } from '../../common/RudderStack';
import { BACKEND_URL } from '../../config/environment';

const Register = () => {
  const location = useLocation();
  const { offer, coupon, referral_code } = queryString.parse(location.search);
  const history = useHistory();
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [siret, setSiret] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [phoneCode, setPhoneCode] = useState<string>('FR');
  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [conditionsAccepted, setConditionsAccepted] = useState<boolean>(true);
  const [communicationAuthorized, setCommunicationAuthorized] = useState<boolean>(true);
  const { user, login } = useContext(UserContext);
  const analytics = useAnalytics();

  const signUp = () => {
    if (email && password && siret && phone) {
      setLoading(true);
      const toMail = email.trim().toLowerCase();
      const toSiret = siret.trim().replace(/\s/g, '');
      const toPhone = phone.trim();
      axios
        .post(`${BACKEND_URL}/api/register`, {
          mail: toMail,
          password,
          siret: toSiret,
          phone: toPhone,
          phone_code: phoneCode,
          conditions_accepted: conditionsAccepted,
          communication_authorized: communicationAuthorized,
        })
        .then(({ data }) => {
          analytics?.identify(
            data.user.id,
            {
              name: data.user.name,
              email: data.user.mail,
            },
            () => {
              analytics?.track('User Registered', {
                email: data.user.mail,
              });
            }
          );

          axios
            .post(`${BACKEND_URL}/api/login`, { mail: toMail, password })
            .then(({ data }) => {
              login(data);
            })
            .catch(() => {
              setError('E-mail ou mot de passe incorrect.');
            });
        })
        .catch((err) => {
          const error = err?.response?.data?.errorMessage;
          setError(error ? error : 'E-mail ou mot de passe incorrect.');
        })
        .finally(() => setLoading(false));
    } else {
      setError('Merci de remplir tous les champs.');
    }
  };

  const getRedirectPath = () => {
    let pathname = '/register/formulas';

    if (offer) {
      pathname = `/offers/${offer}`;
    }

    return pathname;
  };

  const getRedirectSearch = () => {
    let search = '';

    if (coupon) {
      search = `?coupon=${coupon}`;
    } else if (referral_code) {
      search = `?referral_code=${referral_code}`;
    }

    return search;
  };

  const getUrlWithParams = (baseUrl?: string) => {
    const query = queryString.stringify({ coupon: coupon, offer: offer });

    if (query && query.length > 0) {
      baseUrl += '/?' + queryString.stringify({ coupon: coupon, offer: offer });
    }
    return baseUrl;
  };

  const signIn = () => {
    analytics?.track('User already have an account');
    history.push(getUrlWithParams('/login'));
  };

  return (
    <div className="container height-100">
      {user && user.mail && (
        <Redirect to={{ pathname: getRedirectPath(), search: getRedirectSearch() }} />
      )}

      <div className="d-flex align-items-center row height-100">
        <div className="col-lg-6">
          <div className="card card-body card-login-and-register mt-3">
            <img
              src="/images/logo_saqara.png"
              className="logo"
              alt="Logo chantier privé par Saqara"
            />

            <div className="advice-inscription mt-3">
              <b>Vous disposez déjà d&apos;un compte sur AOS by Saqara ?</b>
              <br />
              Nous vous invitons à utiliser la même adresse e-mail pour créer votre compte Chantier
              Privé. Cela vous permettra de centraliser les alertes de vos projets.
            </div>

            <div className="container">
              <p className="mt-4">
                L&apos;inscription est <b>gratuite</b> et <b>sans engagement</b>.
              </p>

              {error && <ErrorMessage text={error} />}

              <input
                id="email"
                data-cy="email"
                type="text"
                className="form-control"
                placeholder="E-mail"
                autoComplete="off"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                id="siret"
                data-cy="siret"
                type="text"
                className="form-control"
                placeholder="SIRET"
                autoComplete="off"
                name="siret"
                onChange={(e) => setSiret(e.target.value)}
              />

              <PhoneInput
                onCodeClick={(e) => setPhoneCode(e.currentTarget.value)}
                onPhoneChange={(e) => setPhone(e.target.value)}
                value={{ phone, code: phoneCode }}
              />

              <input
                id="password"
                data-cy="password"
                type="password"
                className="form-control"
                placeholder="Mot de passe"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="text-left mt-2">
                <input
                  id="cgu_authorized"
                  data-cy="cgu_authorized"
                  type="checkbox"
                  name="cgu_authorized"
                  checked={conditionsAccepted}
                  onChange={(e) => setConditionsAccepted(e.target.checked)}
                />{' '}
                J&apos;ai lu et j&apos;accepte les{' '}
                <a
                  href={`${process.env.BUCKET_URL}/documents/cgu.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  conditions générales d&apos;utilisation
                </a>
              </div>

              <div className="text-left mt-2">
                <input
                  id="sms_authorized"
                  data-cy="sms_authorized"
                  type="checkbox"
                  name="sms_authorized"
                  checked={communicationAuthorized}
                  onChange={(e) => setCommunicationAuthorized(e.target.checked)}
                />{' '}
                J&apos;accepte de recevoir des communications promotionnelles de la part de Chantier
                Privé
              </div>

              <Button
                id="button-create-account"
                data-cy="button-create-account"
                color="btn-secondary mt-3"
                text={'Créer mon compte'}
                action={() => signUp()}
                loading={loading}
                disabled={!email || !password || !siret || !phone}
              />

              <Button
                id="button-login-redirect"
                data-cy="button-login-redirect"
                color="btn-outline-secondary mt-5"
                text="J'ai déjà un compte"
                action={() => signIn()}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 pl-5">
          <LoginCarousel />
        </div>
      </div>
    </div>
  );
};

export default Register;
