import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';

import TitleIconed from '../../common/navigation/TitleIconed';
import ErrorMessage from '../../common/errors/ErrorMessage';
import CircularLoader from '../../common/navigation/CircularLoader';
import { useAnalytics } from '../../common/RudderStack';
import { BACKEND_URL } from '../../config/environment';
import OfferItem from './OfferItem';

const Offers: React.FC<{ user: IUser }> = ({ user }) => {
  const [stripePrice, setStripePrice] = useState<IStripePrice>(null);
  const [loadingPrice, setLoadingPrice] = useState(true);

  const [error, setError] = useState(null);
  const { stripePriceName }: { stripePriceName: string } = useParams();

  useEffect(() => {
    if (stripePriceName) {
      axios
        .get(`${BACKEND_URL}/api/public/stripe-prices/trials/${stripePriceName}`)
        .then(({ data }) => {
          setStripePrice(data.stripe_price);
        })
        .catch(() => {
          setError("Cette offre est invalide ou n'est plus disponible.");
        })
        .finally(() => {
          setLoadingPrice(false);
        });
    } else {
      setLoadingPrice(false);
      setError("Cette offre est invalide ou n'est plus disponible.");
    }
  }, [stripePriceName]);

  // const handleCgu = () => {
  //   setCguAccepted(!cguAccepted);
  // };

  // const getCheckoutUrl = () => {
  //   if (stripe && elements) {
  //     setLoadingCheckout(true);

  //     axios
  //       .post(`${BACKEND_URL}/api/stripe/trial-checkout-session`, { price_name: stripePriceName })
  //       .then(({ data }) => {
  //         analytics?.track('Checkout Started', {
  //           revenue: stripePrice.price_excl_tax,
  //           currency: 'EUR',
  //           products: [
  //             {
  //               product_id: stripePrice.id,
  //               name: stripePrice.display_name,
  //               price: stripePrice.price_excl_tax,
  //               quantity: 1,
  //             },
  //           ],
  //         });

  //         stripe.redirectToCheckout({ sessionId: data.checkout_session_id }).finally(() => {
  //           setLoadingCheckout(false);
  //         });
  //       });
  //   }
  // };

  // /**
  //  * Get reccurency.
  //  *
  //  * @returns {string}
  //  */
  // const getReccurency = () => {
  //   switch (stripePrice.price_after_expiration.name) {
  //     case 'monthly':
  //       return 'mois';
  //     case 'quarterly':
  //       return 'trimestre';
  //     case 'annually':
  //       return 'an';
  //   }
  // };

  return (
    <div>
      <TitleIconed icon="noun_filter_activated" text="Offre spéciale" />

      {loadingPrice ? (
        <CircularLoader />
      ) : (
        <div className="mt-2">
          {user.is_stripe_subscriber && !error && (
            <ErrorMessage text="Vous n'êtes pas éligible à cette offre." />
          )}

          {error && <ErrorMessage text={error} />}

          {stripePrice && !user.is_stripe_subscriber && (
            <div>
              <p className="sub-description">
                Vous êtes inscrit gratuitement à Chantier Privé, abonnez-vous pour profiter d’un
                accès illimité à des projets sélectionnés sur mesure.
              </p>

              <OfferItem stripePrice={stripePrice} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Offers;
