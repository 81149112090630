import React from 'react';

const CountTradesStatus: React.FC<{ projectRequest: IProjectRequest }> = ({ projectRequest }) => {
  const countValidatedRequest = () => {
    const countValidated = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'validated'
    );
    return countValidated.length;
  };

  const countRequested = () => {
    const countRequested = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'requested'
    );
    return countRequested.length;
  };

  const countRefused = () => {
    const countRefused = projectRequest.request_trades.filter(
      (requestTrade) => requestTrade.status === 'refused'
    );
    return countRefused.length;
  };

  return (
    <>
      <span className="count-trades">
        <img src="/Icons/noun_waiting.svg" alt="waiting circle" />{' '}
        <span style={{ paddingLeft: '3px' }}>{countRequested()}</span>
      </span>
      <span className="count-trades">
        <img src="/Icons/noun_Check.svg" alt="validate circle" />{' '}
        <span style={{ paddingLeft: '3px' }}>{countValidatedRequest()}</span>
      </span>
      <span className="count-trades">
        <img src="/Icons/noun_cancel.svg" alt="refused circle" />{' '}
        <span style={{ paddingLeft: '3px' }}>{countRefused()}</span>
      </span>
      <span className="trades-information">
        <img src="/Icons/information.svg" alt="information" />
      </span>
      <span className="notice-information">
        <p className="triangle-isosceles top-bubble">
          États de vos lots, pour en savoir plus cliquez sur{' '}
          <strong>
            {projectRequest.status === 'validated' ? 'Accéder au dce' : 'Détails projet'}
          </strong>
        </p>
      </span>
    </>
  );
};

export default CountTradesStatus;
