import React from 'react';

/**
 * This react component is used to display a circle loader.
 * @constructor
 */
const CircularLoader = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default CircularLoader;
