import React, { createContext, useEffect, useState } from 'react';
import Toast from '../navigation/Toast';

const ToastContext = createContext<{
  displayToast: (message: string, title?: string) => void;
}>({
  displayToast: null,
});

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [display, setDisplay] = useState<boolean>(true);
  const [title, setTitle] = useState<string>(null);
  const [message, setMessage] = useState<string>(null);

  const displayToast = (message: string, title = 'Chantier Privé') => {
    setTitle(title);
    setMessage(message);
    setDisplay(true);
    setTimeout(() => {
      setDisplay(false);
      setTitle(null);
      setMessage(null);
    }, 3 * 1000);
  };

  const closeToast = () => {
    setTitle(null);
    setMessage(null);
    setDisplay(false);
  };

  return (
    <ToastContext.Provider value={{ displayToast }}>
      {display && title && message && (
        <Toast
          message={message}
          title={title}
          hidden={false}
          onClick={closeToast}
          top={true}
          fixedPosition={true}
        />
      )}
      {children}
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
