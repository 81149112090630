import axios from 'axios';
import React, { useState, useContext } from 'react';
import Button from '../../../common/navigation/Button';
import { useAnalytics } from '../../../common/RudderStack';
import SuccessPriceRequestModal from '../../../common/Modal/SuccessPriceRequestModal';
import { UserContext } from '../../../common/providers/UserProvider';
import { BACKEND_URL } from '../../../config/environment';

const PriceRequestCardAnnually: React.FC<{ foundAnnually?: boolean }> = ({ foundAnnually }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const analytics = useAnalytics();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [hideButton, sethideButton] = useState<boolean>(false);
  const { refreshUser } = useContext(UserContext);

  const sendPriceRequest = () => {
    setLoading(true);
    axios
      .post(`${BACKEND_URL}/api/price-requests`, {
        price_name: 'annually',
      })
      .then(() => {
        setIsModalVisible(true);
        sethideButton(true);
        setLoading(false);
        refreshUser();

        analytics?.track('Price Request', {
          price: 'annually',
        });
      });
  };

  return (
    <div className="card price-request-card-subscription-annually price-request-card-plan card-plan-annually price-request-plan">
      <div className="price-request-header-title">
        <span></span>
      </div>
      <div className="card-title">
        <h3 className="mb-3">
          <strong>Formule intégrale</strong>
        </h3>
      </div>
      <p className="subtitle-request-price">Découvrez tous les projets :</p>
      <ul className="check-list-price-request">
        <li className="icon-annually">Par départements</li>
        <li className="icon-annually">Par lots</li>
        <li className="icon-annually">Par secteur d&apos;activité</li>
        <li className="icon-annually">Avec accès aux coordonnées du maître d&apos;ouvrage</li>
        <li className="icon-annually">Avec accès aux DCEs</li>
        <li className="icon-annually">Avec possibilité de réponse</li>
        <li className="icon-annually">Avec une formation personnalisée</li>
      </ul>
      <p className="subtitle-request-price">
      Votre facturation sans engagement, résiliez à tout moment.
      </p>
      <p>
        <b>
          {foundAnnually
            ? 'Notre équipe vous appelle dans les meilleurs délais'
            : 'Pour finaliser votre abonnement'}
        </b>
      </p>
      {!foundAnnually && (
        <div className="button">
          <Button
            disabled={hideButton}
            color="btn-primary"
            text={'Demander à être appelé'}
            loading={loading}
            action={() => sendPriceRequest()}
          />
        </div>
      )}

      <SuccessPriceRequestModal
        hidden={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default PriceRequestCardAnnually;
