import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Redirect, RouteProps } from 'react-router-dom';
import { UserContext } from '../common/providers/UserProvider';
import SideBar from '../common/navigation/SideBar';
import Footer from '../common/navigation/Footer';
import CircularLoader from '../common/navigation/CircularLoader';
import $ from 'jquery';

type NotFoundProps = RouteProps;

const NotFound: React.FC<NotFoundProps> = (props) => {
  const { user, fetched } = useContext(UserContext);
  const history = useHistory();

  const openBurger = () => {
    if ($('#top').css('display') !== 'none') {
      if ($('.sidebar').css('display') === 'none') {
        $('.content-app').hide();
        $('.sidebar').slideDown(500);
      } else {
        $('.sidebar').slideUp(500, () => {
          $('.content-app').show();
        });
      }
    }
  };

  if (!fetched) {
    return (
      <>
        <div className="container-fluid">
          <div className="container-center">
            <div className="content">
              <div className="content-spinner">
                <CircularLoader />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {user ? (
        <>
          <div id="top">
            <img src="/images/logo_saqara.png" alt="Logo chantier privé par Saqara" />
            <span className="menu-icon">
              <img src="/images/burger.svg" alt="Menu" onClick={() => openBurger()} />
            </span>
          </div>
          <div className="container-fluid container-app">
            <SideBar toCall={() => openBurger()} user={user} />
            <div className="content-app">
              <div className="container-center d-flex flex-column">
                <div className="d-flex align-items-center text-center flex-grow-1">
                  <div className="card-404" style={{ margin: 'auto' }}>
                    <img src="/images/404.svg" alt="404" />

                    <h1 className="mt-2">404</h1>

                    <p className="mt-2">Nous sommes navrés mais cette page n’existe pas.</p>

                    <button
                      type="button"
                      className="btn btn-secondary mt-2"
                      onClick={() => history.push('/consultation_open')}
                    >
                      Revenir à l’accueil
                    </button>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        </>
      )}
    </>
  );
};

export default NotFound;
