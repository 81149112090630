import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL } from '../../config/environment';

const ConfirmEmail = () => {
  const { token }: { token: string } = useParams();

  useEffect(() => {
    axios
      .post(`${BACKEND_URL}/api/email-confirmation/${token}`)
      .then(() => {
        document.location.href = '/login?created=success';
      })
      .catch(() => {
        document.location.href = '/login?created=failure';
      });
  }, []);

  return <div />;
};

export default ConfirmEmail;
