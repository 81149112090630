import React, { useState } from 'react';

import TitleIconed from '../../common/navigation/TitleIconed';
import PaymentMethodModal from '../../common/Modal/PaymentMethodModal';

const UpdatePaymentMethod: React.FC<{ subscription: ISubscription }> = ({ subscription }) => {
  const [openPayment, setOpenPayment] = useState<boolean>(false);

  /**
   * Get payment method name
   */
  const getPaymentMethodName = () => {
    return subscription.payment_method.type == 'card'
      ? subscription.payment_method.data.brand.charAt(0).toUpperCase() +
          subscription.payment_method.data.brand.slice(1)
      : 'SEPA';
  };

  /**
   * Get reccurency.
   *
   * @returns {string}
   */
  const getReccurency = () => {
    switch (subscription.stripe_price.name) {
      case 'monthly':
        return 'mois';
      case 'quarterly':
        return 'trimestre';
      case 'annually':
        return 'an';
    }
  };

  return (
    <div>
      <PaymentMethodModal
        subscription={subscription}
        hidden={openPayment}
        closeModal={() => setOpenPayment(false)}
      />
      <TitleIconed icon="noun_filter_activated" text="DERNIER PAIEMENT REFUSÉ" />
      <p className="sub-description mb-5">
        Votre dernier paiement a échoué, merci de mettre à jour votre moyen de paiement.
      </p>
      <div className="row my-4">
        <div className="col-12">
          <div className="card card-body">
            <h2 className="title-card-option title-subscription">Informations de paiement</h2>
            <div className="row">
              <div className="card-body subscription-body col-sm-7 col-lg-8">
                <p>
                  <b>
                    {subscription.stripe_price.price_excl_tax}€/
                    {getReccurency()}
                  </b>
                </p>
                <p>
                  Votre dernier paiement a échoué, vous devez mettre à jour votre méthode de
                  paiement pour continuer à profiter de Chantier Privé.
                </p>
                <p>
                  {getPaymentMethodName()} se terminant par ****
                  {subscription.payment_method.data.last4}
                </p>
              </div>
              <div className="card-body button-body col-sm-4 col-lg-4">
                <button className="btn payment btn-primary" onClick={() => setOpenPayment(true)}>
                  GÉRER VOS MOYENS DE PAIEMENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePaymentMethod;
