import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

/**
 * This react component is used to display the sidebar of the purchaser app.
 * @constructor
 */
const SideBar: React.FC<{ toCall: () => void }> = ({ toCall }) => {
  const location = useLocation();
  const { token }: { token: string } = useParams();

  const projectRequestsValidation = new RegExp(/projects-requests-validation*/ + token);
  const projectRequestsFollowups = new RegExp(/projects-requests-followups*/ + token);

  return (
    <>
      <div id="navbar" className="sidebar">
        <div className="sidebar-container d-flex flex-column">
          <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
            <Link to={'/projects-requests-validation/' + token} id="link-home">
              <img
                src="/images/logo_saqara.png"
                alt="Logo chantier privé par Saqara"
                className="logo"
              />
            </Link>

            <div className="item">
              <Link
                onClick={() => {
                  toCall();
                }}
                to={'/projects-requests-validation/' + token}
                id="link-projects-requests-validation"
                className={projectRequestsValidation.test(location.pathname) ? 'active' : ''}
              >
                <div
                  className={`${
                    projectRequestsValidation.test(location.pathname) ? '' : 'no-'
                  }marker light`}
                />
                <img src="/Icons/check-plain.svg" alt="Icone coche" />
                <div className="link">Gestion des demandes</div>
              </Link>
            </div>

            <div className="item">
              <Link
                onClick={() => {
                  toCall();
                }}
                to={'/projects-requests-followups/' + token}
                className={projectRequestsFollowups.test(location.pathname) ? 'active' : ''}
                id="link-projects-requests-followups"
              >
                <div
                  className={`${
                    projectRequestsFollowups.test(location.pathname) ? '' : 'no-'
                  }marker light`}
                />
                <img src="/Icons/noun_update_activated.svg" alt="Icone activation" />
                <div className="link">Suivi gestion</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
