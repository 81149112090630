import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LogoutModal from '../../components/authentication/LogoutModal';
import { ADMIN_URL } from '../../config/environment';

const UserMenu: React.FC<{ user: IUser }> = (props) => {
  const parameter = props;
  const user = parameter.user;
  const [showDropdown, setShowDropdown] = useState(false);
  const statusChange = () => setShowDropdown((showDropdown) => !showDropdown);
  const [isSigningOff, setIsSigningOff] = useState<boolean>(false);
  const userName = user.name && user.name.trim() ? user.name.trim() : user.mail;
  return (
    <>
      <LogoutModal hidden={isSigningOff} closeModal={() => setIsSigningOff(false)} />
      <Dropdown
        onClick={statusChange}
        className="mx-3 dropdownmenu dropdownmenu-navigation d-none d-lg-block d-xl-block"
      >
        <Dropdown.Toggle
          className={`btn dropdownmenu m-0 dropdownmenu-title shadow ${
            window.innerWidth >= 995 && 'my-last-step'
          }`}
        >
          <img
            width="20px"
            height="20px"
            className="mr-2"
            src="/Icons/noun_User.svg"
            alt="user"
            data-cy="settings-menu"
          />
          <span className="menu-username">{userName}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu shadow" style={{ margin: 15 }} show={showDropdown}>
          <Link to="/settings/profile" style={{ textDecoration: 'none' }}>
            <Dropdown.Item
              className="dropdownmenu-title dropdownmenu-link"
              data-cy="link-profile"
              as="div"
            >
              Compte
            </Dropdown.Item>
          </Link>
          <Link to="/settings/subscription" style={{ textDecoration: 'none' }}>
            <Dropdown.Item
              className="dropdownmenu-title dropdownmenu-link"
              data-cy="link-subscription"
              as="div"
            >
              Abonnement
            </Dropdown.Item>
          </Link>
          {user && user.profile === 1 && (
            <a href={`${ADMIN_URL}/admin`} style={{ textDecoration: 'none' }}>
              <Dropdown.Item
                className="dropdownmenu-title dropdownmenu-link"
                data-cy="link-admin"
                as="div"
              >
                Administration
              </Dropdown.Item>
            </a>
          )}
          <Dropdown.Divider />
          <Link to="/settings/faq" style={{ textDecoration: 'none' }}>
            <Dropdown.Item
              as="div"
              className="dropdownmenu-title dropdownmenu-link"
              data-cy="link-faq"
            >
              Centre d’aide
            </Dropdown.Item>
          </Link>

          <Dropdown.Item
            className="dropdownmenu-subtitle"
            href="#"
            onClick={() => {
              setIsSigningOff(true);
            }}
            id="link-sign-out"
            data-cy="link-sign-out"
          >
            Se déconnecter
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as="a"
            className="dropdownmenu-par"
            target="_blank"
            rel="noreferrer"
            href={`${process.env.BUCKET_URL}/documents/cgu.pdf`}
          >
            Conditions générales Chantier Privé
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default UserMenu;
