import React, { useEffect, useState } from 'react';
import axios from 'axios';

import TitleIconed from '../../common/navigation/TitleIconed';
import ErrorMessage from '../../common/errors/ErrorMessage';
import CircularLoader from '../../common/navigation/CircularLoader';
import { useAnalytics } from '../../common/RudderStack';
import OfferItem from './OfferItem'; 
import { BACKEND_URL } from '../../config/environment';

const OffersList: React.FC<{ user: IUser }> = ({ user }) => {
  const [prices, setPrices] = useState<Array<IStripePrice>>(null);
  const [loadingPrice, setLoadingPrice] = useState(true);
  const analytics = useAnalytics();

  const [error, setError] = useState(null);

  useEffect(() => {
    if (!prices) {
      axios
        .get(`${BACKEND_URL}/api/prices/proposed`)
        .then(({ data }) => {
          setPrices(data.prices);
        })
        .catch(() => {
          setError("Cette offre est invalide ou n'est plus disponible.");
        })
        .finally(() => {
          setLoadingPrice(false);
        });
    } else {
      setLoadingPrice(false);
      setError("Cette offre est invalide ou n'est plus disponible.");
    }
  }, []);

  return (
    <div>
      <TitleIconed icon="noun_filter_activated" text="Offre spéciale" />

      {loadingPrice ? (
        <CircularLoader />
      ) : (
        <div className="mt-2">
          {user.is_stripe_subscriber && !error && (
            <ErrorMessage text="Vous n'êtes pas éligible à cette offre." />
          )}

          {error && <ErrorMessage text={error} />}

          {prices && !user.is_stripe_subscriber && (
            <div>
              <p className="sub-description">
                Vous êtes inscrit gratuitement à Chantier Privé, abonnez-vous pour profiter d’un
                accès illimité à des projets sélectionnés sur mesure.
              </p>

              {prices.map((stripePrice) => (
                <OfferItem key={`offer-${stripePrice.id}`} stripePrice={stripePrice} />
              ))}

            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OffersList;
