import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Phone from '../../common/navigation/Phone';
import TitleIconed from '../../common/navigation/TitleIconed';
import UserMenu from '../../common/navigation/UserMenu';
import Pagination from '../../common/navigation/Pagination';
import { BACKEND_URL } from '../../config/environment';

const UserManagement: React.FC<{ user: IUser }> = ({ user }) => {
  const [users, setUsers] = useState<Array<IUser>>();
  const [lastPage, setLastPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  /**
   * Load companies first time.
   */
  useEffect(() => {
    if (!users) {
      changePage(1);
    }
  }, []);

  /**
   * Load users in a specific page with a query string filter.
   * @param page Current page.
   */
  const changePage = (page: number) => {
    setLoading(true);
    setCurrentPage(page);

    const url = `${BACKEND_URL}/api/members?page=${page}`;

    axios.get<IPaginateUser>(url).then(({ data }) => {
      setUsers(data.users.data);
      setLastPage(data.users.last_page);
      setLoading(false);
    });
  };

  const handleLoggingAsClick = (id: string) => {
    axios.post(`${BACKEND_URL}/api/members/${id}/log-as`).then(() => {
      document.location.href = '/consultation_open';
    });
  };

  const displayInvitationStatus = (member: IUser) => {
    if (member.management_status === null) {
      return <span className="text-warning">En attente</span>;
    }

    return member.management_status ? (
      <span className="text-success">Validée</span>
    ) : (
      <span className="text-danger">Refusée</span>
    );
  };

  return (
    <>
      <div className="d-flex">
        <div className="p-2 w-75 my-first-step">
          <TitleIconed icon="users" text="Gestion des utilisateurs" />
        </div>
        <div className="py-2">
          <Phone />
        </div>
        <div className="p-2">
          <UserMenu user={user} />
        </div>
      </div>
      <p className="sub-description">Retrouvez ici la liste des utilisateurs lié à votre compte</p>

      <div className="alert alert-info">
        Si vous souhaitez ajouter un collaborateur à votre équipe, veuillez nous contacter via le
        numéro 01 87 66 60 76 ou via e-mail à l&apos;adresse contact@chantierprive.fr
      </div>

      <div className="card card-body">
        <h2 className="title-card-option">Liste des utilisateurs</h2>
        <div
          className="table-responsive table-subscription"
          id="scrollbar"
          data-cy="scrollbar"
          style={{ overflow: 'auto' }}
        >
          <table className="table table-hover mt-4">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Prénom</th>
                <th scope="col">Adresse mail</th>
                <th scope="col">SIRET</th>
                <th scope="col">Status</th>
                <th scope="col">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.length > 0 &&
                users.map((user: IUser) => (
                  <tr key={user.id}>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.mail}</td>
                    <td>{user.account?.company?.siret}</td>
                    <td>{displayInvitationStatus(user)}</td>
                    <td>
                      {user.management_status && (
                        <a
                          href={`#`}
                          className="ml-3"
                          onClick={() => handleLoggingAsClick(user.id)}
                        >
                          <img src="/Icons/eye.png" className="mr-2" />
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {lastPage > 0 && (
        <Pagination lastPage={lastPage} currentPage={currentPage} toCall={changePage} />
      )}
    </>
  );
};

export default UserManagement;
