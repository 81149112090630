import React, { useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';

import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import stripe, { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import $ from 'jquery';

import Home from './authentication/Home';
import Login from './authentication/Login';
import ResetPassword from './authentication/ResetPassword';
import RecoveryPassword from './authentication/RecoveryPassword';
import Register from './authentication/Register';
import CompleteRegistration from './authentication/CompleteRegistration';
import ProjectList from './projects/ProjectList';
import ProjectRequestList from './projects/ProjectRequestList';
import PersonnalInformationList from './settings/informations/PersonnalInformationList';
import PreferencesList from './settings/preferences/PreferencesList';
import ConfirmEmail from './redirections/ConfirmEmail';
import TrialPaymentSuccess from './offers/TrialPaymentSuccess';
import TrialPaymentFailed from './offers/TrialPaymentFailed';

import RegisterFormulas from './authentication/RegisterFormulas';
import NotFound from './NotFound';
import SubscriptionPaymentSuccess from './subscriptions/SubscriptionPaymentSuccess';
import Landing from './landing';
import LandingFormulas from './landing/LandingFormulas';
import Offers from './offers';
import OffersList from './offers/OffersList';
import JoinPlatform from './authentication/JoinPlatform';
import MainCategoryList from './settings/help/MainCategoryList';
import CategoryList from './settings/help/CategoryList';
import ProjectRequestsValidation from './purchasers/project-requests-validation';
import ProjectRequestsFollowup from './purchasers/project-requests-followup';

import FavoriteProjectsList from './favorites/FavoriteProjectsList';
import axios from 'axios';
import { UserProvider, UserContext } from '../common/providers/UserProvider';
import { ToastProvider } from '../common/providers/ToastProvider';
import { SubscriptionProvider } from '../common/providers/SubscriptionProvider';

import PrivateRoute from '../common/routes/PrivateRoute';
import GuestRoute from '../common/routes/GuestRoute';
import DeleteAccount from './settings/account/DeleteAccount';

import CancelSubscription from './subscriptions/CancelSubscription';
import Subscription from './subscriptions/index';
import SubscriptionResumeOffer from './subscriptions/SubscriptionResumeOffer';
//import Sponsorship from './sponsorships/Sponsorship';

import { useLocation } from 'react-router-dom';
import { TopMessageProvider } from '../common/providers/TopMessageProvider';
import { useAnalytics } from '../common/RudderStack';
import UserManagement from './users/UserManagement';

import { BACKEND_URL } from '../config/environment';
import LogASBanner from './authentication/LogASBanner';
// import AdminRoutePath from '../admin/components/Router';
// import Admin from '../admin/components/Admin';

const stripePromise: Promise<stripe.Stripe | null> = loadStripe(
  process.env.STRIPE_PUBLISHABLE_KEY as string
);

Sentry.init({
  dsn: process.env.SENTRY_DSN_FRONT,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
});

const RoutePath: React.FC = () => {
  const { user, login, loginFailed } = useContext(UserContext);
  const analytics = useAnalytics();

  useEffect(() => {
    if (!user) {
      axios
        .get(`${BACKEND_URL}/api/user`)
        .then(({ data }) => {
          if (data.user && data.user.mail) {
            login(data.user);
          } else {
            loginFailed();
          }
        })
        .catch(() => {
          loginFailed();
        });
    }
  }, []);

  $(window).on('resize', () => {
    if ($('#top').css('display') === 'none') {
      $('.sidebar').show();
    } else {
      $('.sidebar').hide();
      $('.content-app').show();
    }
  });

  return (
    <BrowserRouter>
      <TopMessageProvider>
        <RouteListener>
          <Switch>
            {/* This route is used to confirm email */}
            <Route exact path="/email-confirmation/:token" component={ConfirmEmail} />
            <Route exact path="/invitation/:token" component={ConfirmEmail} />

            <Route exact path="/landing" component={Landing} />
            <Route exact path="/landing/formulas" component={LandingFormulas} />

            <Route
              exact
              path="/projects-requests-validation/:token"
              component={ProjectRequestsValidation}
            />
            <Route
              exact
              path="/projects-requests-followups/:token"
              component={ProjectRequestsFollowup}
            />

            {/* Login and Register are guest only routes with their own redirection logic inside the component */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />

            {/* Complete registration of invited users */}
            <Route exact path="/registration/complete" component={CompleteRegistration} />

            {/* Guest routes */}
            <GuestRoute exact path="/" component={Home} />
            <GuestRoute exact path="/reset-password" component={ResetPassword} />
            <GuestRoute
              exact
              path="/recovery-password/:token/:email"
              component={RecoveryPassword}
            />
            <GuestRoute exact path="/rejoignez-nous" component={JoinPlatform} />

            {/* Private routes */}
            <PrivateRoute
              exact
              path="/register/formulas"
              component={RegisterFormulas}
              enableWrapper={false}
            />

            <PrivateRoute
              exact
              path="/offers/:stripePriceName/success"
              component={TrialPaymentSuccess}
            />
            <PrivateRoute
              exact
              path="/offers/:stripePriceName/failed"
              component={TrialPaymentFailed}
            />
            <PrivateRoute exact path="/offers" component={OffersList} />
            <PrivateRoute exact path="/offers/:stripePriceName" component={Offers} />

            <PrivateRoute exact path="/consultation_open" component={ProjectList} />
            <PrivateRoute exact path="/consultation_ask" component={ProjectRequestList} />
            <PrivateRoute exact path="/favorites" component={FavoriteProjectsList} />
            <PrivateRoute exact path="/settings/profile" component={PersonnalInformationList} />
            <PrivateRoute exact path="/settings/subscription/" component={Subscription} />
            <PrivateRoute
              exact
              path="/settings/subscription/resume-offer"
              component={SubscriptionResumeOffer}
            />
            <PrivateRoute
              exact
              path="/settings/subscription/cancel"
              component={CancelSubscription}
            />

            <PrivateRoute
              exact
              path="/settings/subscription/success"
              component={SubscriptionPaymentSuccess}
            />
            <PrivateRoute exact path="/settings/preferences/" component={PreferencesList} />
            <PrivateRoute exact path="/settings/faq" component={MainCategoryList} />
            <PrivateRoute
              exact
              path="/settings/faq/:categorySlug/:subCategorySlug?/:questionSlug?"
              component={CategoryList}
            />
            <PrivateRoute exact path="/settings/account/delete" component={DeleteAccount} />

            {/* <PrivateRoute exact path="/settings/sponsorship" component={Sponsorship} /> */}
            <PrivateRoute exact path="/user_management" component={UserManagement} />

            {/* Not Found */}
            <Route component={NotFound} />
          </Switch>
        </RouteListener>
      </TopMessageProvider>
    </BrowserRouter>
  );
};

const RouteListener: React.FC = ({ children }) => {
  const location = useLocation();

  let prevPath: string = null;
  const analytics = useAnalytics();

  useEffect(() => {
    // Prevent GA page bounce.
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
      analytics?.page();
    }
  }, [location.pathname]);

  return <>{children}</>;
};

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <UserProvider>
      <SubscriptionProvider>
        <ToastProvider>
          <RoutePath />
          <LogASBanner />
        </ToastProvider>
      </SubscriptionProvider>
    </UserProvider>
  </Elements>,
  document.getElementById('app')
);

export default RoutePath;
