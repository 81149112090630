import React, { useEffect, useState } from 'react';
import TradesCategoriesEdit from '../../components/settings/preferences/TradesCategoriesEdit';
import { useAnalytics } from '../RudderStack';

interface TradesCategoriesDropdownProps {
  tradeCategories: Array<ITrades>;
  onChange: (trades_categories: Array<ITrades>) => void;
}

const TradesCategoriesDropdown: React.FC<TradesCategoriesDropdownProps> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [tradeCategories, setTradeCategories] = useState<Array<ITrades>>(props.tradeCategories);
  const analytics = useAnalytics();

  const onApply = () => {
    props.onChange(tradeCategories);
    setIsVisible(false);
  };

  const onCancel = () => {
    setTradeCategories(props.tradeCategories);
    setIsVisible(false);
  };

  const updateTradesCategories = (tradeCategory: ITrades) => {
    const eventData = {
      filter_type: 'Trade Category',
      filter_key: tradeCategory.name,
      filter_value: '',
    };

    if (tradeCategories.some((item) => item.name === tradeCategory.name)) {
      setTradeCategories(tradeCategories.filter((value) => value.name !== tradeCategory.name));
      eventData.filter_value = 'unchecked';
    } else {
      eventData.filter_value = 'checked';
      setTradeCategories([...tradeCategories, tradeCategory]);
    }

    analytics?.track('Filter Changed', eventData);
  };

  const updateAllTradesCategories = (tradesCategoriesList: Array<ITrades>) => {
    analytics?.track('Filter Changed', {
      filter_type: 'Trade',
      filter_key: 'All',
      filter_value: tradesCategoriesList.length ? 'checked' : 'unchecked',
    });

    setTradeCategories(tradesCategoriesList);
  };

  const displaySelected = (a: Array<ITrades>): string => {
    const str = a.map((s) => `${s.name}`).join('; ');

    return str.length > 63 ? str.substr(0, 63) : str;
  };

  /**
   * Closes dropdowns when clicking outside of the dropdown
   */
  useEffect(() => {
    const onClickEvent = (e: MouseEvent) => {
      const menu = document.getElementById('trades-dropdown');
      const target = e.target as Node;

      if (!menu.contains(target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('click', onClickEvent);

    return () => document.removeEventListener('click', onClickEvent);
  }, []);

  useEffect(() => {
    const app = document.getElementById('app');
    if (app.scrollHeight < 710) {
      app.style.height = '710px';
    }
  }, [isVisible]);

  return (
    <div
      id="trades-dropdown"
      data-cy="trades-dropdown"
      className={'search-dropdown' + (isVisible ? ' show' : '')}
    >
      <div className="toggle mt-0" role="button" onClick={() => setIsVisible((p) => !p)}>
        <div className="toggle-header">
          <span className="title">Lots</span>
          {props.tradeCategories.length ? (
            <b className="selected" data-cy="lots-selected">
              {displaySelected(props.tradeCategories)}
            </b>
          ) : (
            <span className="text">Trouvez des chantiers dans votre zone...</span>
          )}
        </div>
        <span className="caret"></span>
      </div>
      <div className={'menu' + (isVisible ? ' show' : '')}>
        <div className="menu-element">
          <b>Rechercher par Lot(s)</b>

          <div className="mobile-header" onClick={() => setIsVisible((p) => !p)}>
            <span className="title">Lots</span>
            <span className="caret"></span>
          </div>

          <TradesCategoriesEdit
            updateAllTrades={updateAllTradesCategories}
            updateTrades={updateTradesCategories}
            trades={tradeCategories}
            background="#F2F4F8"
          />
        </div>

        <hr className="mt-0" />

        <div className="menu-element">
          <div className="dropdown-actions">
            <button
              type="button"
              data-cy="cancel-trades"
              className="btn btn-outline-primary mr-1"
              onClick={onCancel}
            >
              Annuler
            </button>

            <button
              type="button"
              data-cy="apply-trades"
              className="btn btn-primary"
              onClick={onApply}
              disabled={tradeCategories.length === 0}
            >
              Appliquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradesCategoriesDropdown;
